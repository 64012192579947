<ul class="icons-list flex-wrap d-flex justify-content-between">
  @for (icon of icons; track icon.src) {
    <li class="icons-list__item">
      <img
        [src]="icon.src | staticImageUrl: icon.folder"
        [alt]="icon.description"
        [title]="icon.description"
        loading="lazy"
        [height]="icon.height"
        [width]="icon.width"
      />
    </li>
  }
</ul>
