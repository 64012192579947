import { IPaymentMethodIcons } from './footer-bar-perrelet.model';

export const PAYMENT_METHOD_ICONS: IPaymentMethodIcons[] = [
  { src: 'visa.png', description: 'Visa', folder: 'assets/img/footer/payment-methods-white-bg', height: '36', width: '50' },
  { src: 'mastercard.png', description: 'Mastercard', folder: 'assets/img/footer/payment-methods-white-bg', height: '36', width: '50' },
  { src: 'apple-pay.png', description: 'Apple Pay', folder: 'assets/img/footer/payment-methods-white-bg', height: '36', width: '50' },
  { src: 'google-pay.png', description: 'Google Pay', folder: 'assets/img/footer/payment-methods-white-bg', height: '36', width: '50' },
  { src: 'karna.png', description: 'Klarna', folder: 'assets/img/footer/payment-methods-white-bg', height: '36', width: '50' },
  { src: 'giropay.png', description: 'Giro Pay', folder: 'assets/img/footer/payment-methods-white-bg', height: '36', width: '50' },
  { src: 'paypal.png', description: 'Paypal', folder: 'assets/img/footer/payment-methods-white-bg', height: '36', width: '50' }
];
