{
  "content": [
    {
      "id": 60,
      "__component": "utils.text-on-background",
      "text": "<div class=\"raw-html-embed\"><h1 class=\"text-center text-light\">Extension De Garantie</h1></div>",
      "positionX": "center",
      "positionY": "center",
      "mobilePositionY": "center",
      "buttonText": null,
      "url": null,
      "background": null,
      "buttonType": null,
      "unpublish": null,
      "height": 500,
      "params": null,
      "fragment": null,
      "key": null,
      "videoLoop": true,
      "mediaMobile": {
        "id": 2425,
        "name": "headgarantiamobile.png",
        "alternativeText": null,
        "caption": null,
        "width": 991,
        "height": 824,
        "formats": {
          "small": {
            "ext": ".png",
            "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1713449203487-small_headgarantiamobile.png.png",
            "hash": "small_headgarantiamobile_ed09457a4d",
            "mime": "image/png",
            "name": "small_headgarantiamobile.png",
            "path": null,
            "size": 145.31,
            "width": 500,
            "height": 416
          },
          "medium": {
            "ext": ".png",
            "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1713449203487-medium_headgarantiamobile.png.png",
            "hash": "medium_headgarantiamobile_ed09457a4d",
            "mime": "image/png",
            "name": "medium_headgarantiamobile.png",
            "path": null,
            "size": 310.02,
            "width": 750,
            "height": 624
          },
          "thumbnail": {
            "ext": ".png",
            "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1713449203406-thumbnail_headgarantiamobile.png.png",
            "hash": "thumbnail_headgarantiamobile_ed09457a4d",
            "mime": "image/png",
            "name": "thumbnail_headgarantiamobile.png",
            "path": null,
            "size": 27.21,
            "width": 188,
            "height": 156
          }
        },
        "hash": "headgarantiamobile_ed09457a4d",
        "ext": ".png",
        "mime": "image/png",
        "size": 133.61,
        "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1713449203371-headgarantiamobile.png.png",
        "previewUrl": null,
        "provider": "@strapi-community/strapi-provider-upload-google-cloud-storage",
        "provider_metadata": null,
        "createdAt": "2024-04-18T14:06:43.685Z",
        "updatedAt": "2024-04-18T14:06:43.685Z"
      },
      "media": {
        "id": 2424,
        "name": "headgarantia_desk.png",
        "alternativeText": null,
        "caption": null,
        "width": 2560,
        "height": 304,
        "formats": {
          "large": {
            "ext": ".png",
            "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1713449190642-large_headgarantia_desk.png.png",
            "hash": "large_headgarantia_desk_f197a0ae4b",
            "mime": "image/png",
            "name": "large_headgarantia_desk.png",
            "path": null,
            "size": 97.39,
            "width": 1000,
            "height": 119
          },
          "small": {
            "ext": ".png",
            "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1713449190642-small_headgarantia_desk.png.png",
            "hash": "small_headgarantia_desk_f197a0ae4b",
            "mime": "image/png",
            "name": "small_headgarantia_desk.png",
            "path": null,
            "size": 27.57,
            "width": 500,
            "height": 59
          },
          "medium": {
            "ext": ".png",
            "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1713449190642-medium_headgarantia_desk.png.png",
            "hash": "medium_headgarantia_desk_f197a0ae4b",
            "mime": "image/png",
            "name": "medium_headgarantia_desk.png",
            "path": null,
            "size": 56.76,
            "width": 750,
            "height": 89
          },
          "thumbnail": {
            "ext": ".png",
            "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1713449190553-thumbnail_headgarantia_desk.png.png",
            "hash": "thumbnail_headgarantia_desk_f197a0ae4b",
            "mime": "image/png",
            "name": "thumbnail_headgarantia_desk.png",
            "path": null,
            "size": 9.01,
            "width": 245,
            "height": 29
          }
        },
        "hash": "headgarantia_desk_f197a0ae4b",
        "ext": ".png",
        "mime": "image/png",
        "size": 142.49,
        "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1713449190431-headgarantia_desk.png.png",
        "previewUrl": null,
        "provider": "@strapi-community/strapi-provider-upload-google-cloud-storage",
        "provider_metadata": null,
        "createdAt": "2024-04-18T14:06:30.873Z",
        "updatedAt": "2024-04-18T14:06:30.873Z"
      }
    },
    {
      "id": 2544,
      "__component": "utils.rich-text",
      "text": "<p style=\"text-align:center;\">&nbsp;</p><h2 style=\"margin-left:0px;text-align:center;\">Perrelet Care Program</h2><div class=\"subtitle-4\">&nbsp;</div><div class=\"gris-5-color subtitle-4\"><strong>PROLONGEZ VOTRE GARANTIE INTERNATIONALE JUSQU'À 5 ANS</strong></div><p>&nbsp;</p><div class=\"paragraph-1\">Nos maîtres horlogers assurent la précision et la fiabilité des montres PERRELET depuis plus de 240 ans. Grâce à ce programme d'extension de garantie, votre montre bénéficiera de nos soins d'experts durant cinq ans à compter de la date d'achat.</div><div class=\"paragraph-1\">Inscrivez-vous dès maintenant et devenez membre de My PERRELET pour bénéficier d'une extension de garantie supplémentaire de 2 ans  s'ajoutant à la garantie internationale standard de 3 ans. En tant que membre du club, vous recevrez également des conseils utiles sur l'entretien de votre montre, les dernières nouveautés et un accès prioritaire aux éditions limitées.</div><p>&nbsp;</p><div class=\"paragraph-1\" style=\"text-align:center;\"><u>CONDITIONS</u></div><div class=\"paragraph-1\">&nbsp;</div><li class=\"paragraph-1\">Cette extension de garantie est disponible exclusivement pour les montres équipées du mouvement P-331-MH, certifié COSC et Chronofiable.</li><li class=\"paragraph-1\">La montre doit avoir été achetée auprès de notre réseau de revendeurs agréés.</li><li class=\"paragraph-1\">La demande d'extension de garantie doit être faite dans un délai maximum de 90 jours à compter de la date d'achat.</li><p>&nbsp;</p><div class=\"paragraph-1\">N'hésitez pas à contacter votre revendeur PERRELET pour savoir si votre montre est éligible à l'extension de garantie.</div><div class=\"paragraph-1\">Veuillez consulter les <strong><u> <a class=\"font-weight-bold\" href=\"/fr-FR/termes-legaux-et-conditions\" target=\"_blank\">Conditions légales</a></u></strong> de ce service d'extension de garantie Perrelet.</div><p>&nbsp;</p>",
      "fullWidth": false,
      "unpublish": false,
      "key": null
    },
    {
      "fullWidth": false,
      "id": 405,
      "show": false,
      "unpublish": false,
      "__component": "utils.warranty-extension"
    },
    {
      "id": 2544,
      "__component": "utils.rich-text",
      "text": "<div class=\"raw-html-embed\"><div class=\"pt-5\"></div></div>",
      "fullWidth": false,
      "unpublish": false,
      "key": null
    },
    {
      "id": 390,
      "__component": "landing.card-image-grid",
      "textButton": null,
      "linkButton": null,
      "fullWidth": true,
      "spaced": true,
      "params": null,
      "buttonType": null,
      "unpublish": false,
      "key": null,
      "item": [
        {
          "id": 4264,
          "title": null,
          "textButton": null,
          "url": null,
          "reverse": false,
          "text": "<div class=\"px-2 px-md-5\"><h2 style=\"margin-left:0px;\">¿Quelles montres sont éligibles à l'extension de garantie?</h2><div class=\"paragraph-1\" style=\"margin-left:0px;\">&nbsp;</div><div class=\"paragraph-1\" style=\"margin-left:0px;\"><span style=\"background-color:rgb(255,255,255);color:rgb(55,52,48);font-family:&quot;Work Sans&quot;, Helvetica, Arial, sans-serif;\">Toutes les montres PERRELET équipées du calibre de manufacture P-331-MH, certifiées COSC et achetées via notre réseau autorisé, pourront bénéficier de cette extension de garantie de 2 ans pour atteindre un total de 5 ans de garantie internationale.</span><br><span style=\"background-color:rgb(255,255,255);color:rgb(55,52,48);font-family:&quot;Work Sans&quot;, Helvetica, Arial, sans-serif;\">&nbsp;</span><br><span style=\"background-color:rgb(255,255,255);color:rgb(55,52,48);font-family:&quot;Work Sans&quot;, Helvetica, Arial, sans-serif;\">La demande d'extension de garantie doit être effectuée dans un délai maximum de 90 jours à compter de la date d'achat de la montre.</span><br><span style=\"background-color:rgb(255,255,255);color:rgb(55,52,48);font-family:&quot;Work Sans&quot;, Helvetica, Arial, sans-serif;\">&nbsp;</span><br><span style=\"background-color:rgb(255,255,255);color:rgb(55,52,48);font-family:&quot;Work Sans&quot;, Helvetica, Arial, sans-serif;\">Pour plus de détails, veuillez consulter les Termes et Conditions de l'extension de la Garantie Internationale Limitée.</span></div></div>",
          "effect": false,
          "illuminate": false,
          "gridMedia": "col-lg-6",
          "gridMediaMobile": "col-12",
          "titleImage": null,
          "titleImageColor": null,
          "controls": false,
          "params": null,
          "fullWidth": false,
          "reverseColumn": false,
          "background": null,
          "buttonType": null,
          "unpublish": false,
          "translateY": null,
          "fragment": null,
          "key": null,
          "videoLoop": true,
          "media": {
            "id": 2370,
            "name": "Que_relojes.png",
            "alternativeText": null,
            "caption": null,
            "width": 1060,
            "height": 900,
            "formats": {
              "large": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1711626144314-large_Que_relojes.png.png",
                "hash": "large_Que_relojes_3256eb1a2d",
                "mime": "image/png",
                "name": "large_Que_relojes.png",
                "path": null,
                "size": 537.63,
                "width": 1000,
                "height": 849
              },
              "small": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1711626144314-small_Que_relojes.png.png",
                "hash": "small_Que_relojes_3256eb1a2d",
                "mime": "image/png",
                "name": "small_Que_relojes.png",
                "path": null,
                "size": 154.55,
                "width": 500,
                "height": 425
              },
              "medium": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1711626144314-medium_Que_relojes.png.png",
                "hash": "medium_Que_relojes_3256eb1a2d",
                "mime": "image/png",
                "name": "medium_Que_relojes.png",
                "path": null,
                "size": 323.36,
                "width": 750,
                "height": 637
              },
              "thumbnail": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1711626144218-thumbnail_Que_relojes.png.png",
                "hash": "thumbnail_Que_relojes_3256eb1a2d",
                "mime": "image/png",
                "name": "thumbnail_Que_relojes.png",
                "path": null,
                "size": 26.91,
                "width": 184,
                "height": 156
              }
            },
            "hash": "Que_relojes_3256eb1a2d",
            "ext": ".png",
            "mime": "image/png",
            "size": 131.95,
            "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1711626144173-Que_relojes.png.png",
            "previewUrl": null,
            "provider": "@strapi-community/strapi-provider-upload-google-cloud-storage",
            "provider_metadata": null,
            "createdAt": "2024-03-28T11:42:24.632Z",
            "updatedAt": "2024-03-28T11:42:24.632Z"
          },
          "mediaMobile": null
        },
        {
          "id": 4265,
          "title": null,
          "textButton": null,
          "url": null,
          "reverse": true,
          "text": "<div class=\"px-2 px-md-5\"><h2 style=\"margin-left:0px;\">Comment enregistrer votre montre?</h2><div class=\"paragraph-1\" style=\"margin-left:0px;\">&nbsp;</div><div class=\"paragraph-1\" style=\"margin-left:0px;\"><span style=\"background-color:rgb(255,255,255);color:rgb(55,52,48);font-family:&quot;Work Sans&quot;, Helvetica, Arial, sans-serif;\">En trois étapes simples, vous pouvez enregistrer votre montre pour demander une extension de garantie:</span></div><ol style=\"list-style-type:decimal;\"><li class=\"paragraph-1\">Ayez votre carte de garantie Perrelet à portée de main.</li><li class=\"paragraph-1\">Remplissez le formulaire avec les détails de votre montre Perrelet et vos informations personnelles.</li><li class=\"paragraph-1\">Finalisez l'enregistrement de votre montre.</li></ol></div>",
          "effect": false,
          "illuminate": false,
          "gridMedia": "col-lg-6",
          "gridMediaMobile": "col-12",
          "titleImage": null,
          "titleImageColor": null,
          "controls": false,
          "params": null,
          "fullWidth": false,
          "reverseColumn": false,
          "background": null,
          "buttonType": null,
          "unpublish": false,
          "translateY": null,
          "fragment": null,
          "key": null,
          "videoLoop": true,
          "media": {
            "id": 2371,
            "name": "como_registrar.png",
            "alternativeText": null,
            "caption": null,
            "width": 1060,
            "height": 900,
            "formats": {
              "large": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1711626144788-large_como_registrar.png.png",
                "hash": "large_como_registrar_e283c5ef28",
                "mime": "image/png",
                "name": "large_como_registrar.png",
                "path": null,
                "size": 960.25,
                "width": 1000,
                "height": 849
              },
              "small": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1711626144789-small_como_registrar.png.png",
                "hash": "small_como_registrar_e283c5ef28",
                "mime": "image/png",
                "name": "small_como_registrar.png",
                "path": null,
                "size": 265.44,
                "width": 500,
                "height": 425
              },
              "medium": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1711626144789-medium_como_registrar.png.png",
                "hash": "medium_como_registrar_e283c5ef28",
                "mime": "image/png",
                "name": "medium_como_registrar.png",
                "path": null,
                "size": 564.7,
                "width": 750,
                "height": 637
              },
              "thumbnail": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1711626144478-thumbnail_como_registrar.png.png",
                "hash": "thumbnail_como_registrar_e283c5ef28",
                "mime": "image/png",
                "name": "thumbnail_como_registrar.png",
                "path": null,
                "size": 47.59,
                "width": 184,
                "height": 156
              }
            },
            "hash": "como_registrar_e283c5ef28",
            "ext": ".png",
            "mime": "image/png",
            "size": 231.88,
            "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1711626144403-como_registrar.png.png",
            "previewUrl": null,
            "provider": "@strapi-community/strapi-provider-upload-google-cloud-storage",
            "provider_metadata": null,
            "createdAt": "2024-03-28T11:42:25.603Z",
            "updatedAt": "2024-03-28T11:42:25.603Z"
          },
          "mediaMobile": null
        },
        {
          "id": 4266,
          "title": null,
          "textButton": null,
          "url": null,
          "reverse": false,
          "text": "<div class=\"px-2 px-md-5\"><h2 style=\"margin-left:0px;\">À votre service</h2><div class=\"paragraph-1\" style=\"margin-left:0px;\">&nbsp;</div><div class=\"paragraph-1\" style=\"margin-left:0px;\"><span style=\"background-color:rgb(255,255,255);color:rgb(55,52,48);font-family:&quot;Work Sans&quot;, Helvetica, Arial, sans-serif;\">Notre service Concierge est disponible pour répondre à toutes les questions qui pourraient survenir pendant le processus d'inscription de votre montre.</span></div></div>",
          "effect": false,
          "illuminate": false,
          "gridMedia": "col-lg-6",
          "gridMediaMobile": "col-12",
          "titleImage": null,
          "titleImageColor": null,
          "controls": false,
          "params": null,
          "fullWidth": false,
          "reverseColumn": false,
          "background": null,
          "buttonType": null,
          "unpublish": false,
          "translateY": null,
          "fragment": null,
          "key": null,
          "videoLoop": true,
          "media": {
            "id": 2372,
            "name": "asu servicio.png",
            "alternativeText": null,
            "caption": null,
            "width": 1060,
            "height": 900,
            "formats": {
              "large": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1711626145453-large_asu-servicio.png.png",
                "hash": "large_asu_servicio_e70b631425",
                "mime": "image/png",
                "name": "large_asu servicio.png",
                "path": null,
                "size": 2093.25,
                "width": 1000,
                "height": 849
              },
              "small": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1711626145453-small_asu-servicio.png.png",
                "hash": "small_asu_servicio_e70b631425",
                "mime": "image/png",
                "name": "small_asu servicio.png",
                "path": null,
                "size": 526.01,
                "width": 500,
                "height": 425
              },
              "medium": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1711626145453-medium_asu-servicio.png.png",
                "hash": "medium_asu_servicio_e70b631425",
                "mime": "image/png",
                "name": "medium_asu servicio.png",
                "path": null,
                "size": 1193.88,
                "width": 750,
                "height": 637
              },
              "thumbnail": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1711626144851-thumbnail_asu-servicio.png.png",
                "hash": "thumbnail_asu_servicio_e70b631425",
                "mime": "image/png",
                "name": "thumbnail_asu servicio.png",
                "path": null,
                "size": 73.72,
                "width": 184,
                "height": 156
              }
            },
            "hash": "asu_servicio_e70b631425",
            "ext": ".png",
            "mime": "image/png",
            "size": 486.86,
            "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1711626144782-asu-servicio.png.png",
            "previewUrl": null,
            "provider": "@strapi-community/strapi-provider-upload-google-cloud-storage",
            "provider_metadata": null,
            "createdAt": "2024-03-28T11:42:26.039Z",
            "updatedAt": "2024-03-28T11:42:26.039Z"
          },
          "mediaMobile": null
        }
      ],
      "grid": {
        "id": 742,
        "gridMobile": "col-12",
        "grid": "col-lg-12"
      }
    },
    {
      "id": 2544,
      "__component": "utils.rich-text",
      "text": "<p style=\"text-align:center;\">&nbsp;</p><h1 style=\"text-align:center;\">Services</h1><div class=\"subtitle-4\" style=\"text-align:center;\">&nbsp;</div><div class=\"paragraph-1\">Chez Perrelet, l'engagement envers nos clients est un pilier de notre culture d'entreprise. Au-delà de proposer les meilleurs produits, nous nous occupons de l'entretien, des réparations et de la restauration de toutes les pièces Perrelet. Avec une attention méticuleuse aux détails et une expérience inégalée, nous garantissons le soin impeccable de votre montre Perrelet, préservant son élégance intemporelle et ses performances.</div>",
      "fullWidth": false,
      "unpublish": false,
      "key": null
    },
    {
      "id": 159,
      "__component": "landing.card-image-grid-v2",
      "fullWidth": false,
      "backgroundColor": null,
      "gutters": true,
      "key": null,
      "unpublish": null,
      "item": [
        {
          "id": 550,
          "text": "<div class=\"raw-html-embed\"><h3 class=\"mt-4 font-weight-bold paragraph-1 mb-2\">\n   LABORATOIRE PERRELET\n</h3>\n<p>\nTranquillité pour toute une vie de plaisir. Les montres Perrelet sont conçues pour durer, et nos maîtres horlogers veilleront à ce que votre montre reste en parfait état, service après service.\n</p></div>",
          "textPosition": "bottom",
          "title": null,
          "titleColor": null,
          "titleStyle": "header-2",
          "titlePosition": "top",
          "titleAlign": "center",
          "textButton": "DÉCOUVRIR",
          "buttonType": "btn-bottom-outline-i-arrow",
          "buttonPosition": "bottom",
          "url": "/laboratoire-horloger",
          "params": null,
          "effect": "none",
          "controls": false,
          "gridMedia": "col-lg-12",
          "gridMediaMobile": "col-12",
          "translateY": null,
          "key": null,
          "unpublish": null,
          "videoLoop": null,
          "buttonAlign": "left",
          "media": {
            "id": 2392,
            "name": "laboratorio perrelet991 x 375.png",
            "alternativeText": null,
            "caption": null,
            "width": 991,
            "height": 690,
            "formats": {
              "small": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1712241870713-small_laboratorio-perrelet991-x-375.png.png",
                "hash": "small_laboratorio_perrelet991_x_375_5c75d7f869",
                "mime": "image/png",
                "name": "small_laboratorio perrelet991 x 375.png",
                "path": null,
                "size": 276.15,
                "width": 500,
                "height": 348
              },
              "medium": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1712241870713-medium_laboratorio-perrelet991-x-375.png.png",
                "hash": "medium_laboratorio_perrelet991_x_375_5c75d7f869",
                "mime": "image/png",
                "name": "medium_laboratorio perrelet991 x 375.png",
                "path": null,
                "size": 560.49,
                "width": 750,
                "height": 522
              },
              "thumbnail": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1712241870507-thumbnail_laboratorio-perrelet991-x-375.png.png",
                "hash": "thumbnail_laboratorio_perrelet991_x_375_5c75d7f869",
                "mime": "image/png",
                "name": "thumbnail_laboratorio perrelet991 x 375.png",
                "path": null,
                "size": 68.4,
                "width": 224,
                "height": 156
              }
            },
            "hash": "laboratorio_perrelet991_x_375_5c75d7f869",
            "ext": ".png",
            "mime": "image/png",
            "size": 227.4,
            "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1712241870442-laboratorio-perrelet991-x-375.png.png",
            "previewUrl": null,
            "provider": "@strapi-community/strapi-provider-upload-google-cloud-storage",
            "provider_metadata": null,
            "createdAt": "2024-04-03T15:22:14.619Z",
            "updatedAt": "2024-04-04T14:44:30.968Z"
          },
          "mediaMobile": null
        },
        {
          "id": 551,
          "text": "<div class=\"raw-html-embed\"><h3 class=\"mt-4 font-weight-bold paragraph-1 mb-2\">\n   GARANTIE PERRELET\n</h3>\n<p>\nProfitez de la tranquillité d'une garantie internationale contre les défauts de fabrication sur vos montres Perrelet. Valable à partir de la date d'achat et avec un service personnalisé dans les centres officiels.\n</p></div>",
          "textPosition": "bottom",
          "title": null,
          "titleColor": null,
          "titleStyle": "header-2",
          "titlePosition": "top",
          "titleAlign": "center",
          "textButton": "PLUS D'INFORMATIONS",
          "buttonType": "btn-bottom-outline-i-arrow",
          "buttonPosition": "bottom",
          "url": "/garantie-fabrication",
          "params": null,
          "effect": "none",
          "controls": false,
          "gridMedia": "col-lg-12",
          "gridMediaMobile": "col-12",
          "translateY": null,
          "key": null,
          "unpublish": null,
          "videoLoop": null,
          "buttonAlign": "left",
          "media": {
            "id": 2393,
            "name": "garantia de fabricacion991 x 375.png",
            "alternativeText": null,
            "caption": null,
            "width": 991,
            "height": 690,
            "formats": {
              "small": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1712157734526-small_garantia-de-fabricacion991-x-375.png.png",
                "hash": "small_garantia_de_fabricacion991_x_375_4286d2647f",
                "mime": "image/png",
                "name": "small_garantia de fabricacion991 x 375.png",
                "path": null,
                "size": 209.96,
                "width": 500,
                "height": 348
              },
              "medium": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1712157734526-medium_garantia-de-fabricacion991-x-375.png.png",
                "hash": "medium_garantia_de_fabricacion991_x_375_4286d2647f",
                "mime": "image/png",
                "name": "medium_garantia de fabricacion991 x 375.png",
                "path": null,
                "size": 440.3,
                "width": 750,
                "height": 522
              },
              "thumbnail": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1712157734301-thumbnail_garantia-de-fabricacion991-x-375.png.png",
                "hash": "thumbnail_garantia_de_fabricacion991_x_375_4286d2647f",
                "mime": "image/png",
                "name": "thumbnail_garantia de fabricacion991 x 375.png",
                "path": null,
                "size": 53.29,
                "width": 224,
                "height": 156
              }
            },
            "hash": "garantia_de_fabricacion991_x_375_4286d2647f",
            "ext": ".png",
            "mime": "image/png",
            "size": 160.65,
            "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1712157734202-garantia-de-fabricacion991-x-375.png.png",
            "previewUrl": null,
            "provider": "@strapi-community/strapi-provider-upload-google-cloud-storage",
            "provider_metadata": null,
            "createdAt": "2024-04-03T15:22:14.741Z",
            "updatedAt": "2024-04-03T15:22:14.741Z"
          },
          "mediaMobile": null
        }
      ],
      "grid": {
        "id": 525,
        "gridMobile": "col-12",
        "grid": "col-lg-6"
      }
    },
    {
      "fullWidth": true,
      "id": 403,
      "show": false,
      "unpublish": false,
      "__component": "shared.subscribe"
    }
  ]
}
