{
  "content": [
    {
      "id": 60,
      "__component": "utils.text-on-background",
      "text": "<div class=\"raw-html-embed\"><h1 class=\"text-center text-light\">Warranty Extension</h1></div>",
      "positionX": "center",
      "positionY": "center",
      "mobilePositionY": "center",
      "buttonText": null,
      "url": null,
      "background": null,
      "buttonType": null,
      "unpublish": null,
      "height": 500,
      "params": null,
      "fragment": null,
      "key": null,
      "videoLoop": true,
      "mediaMobile": {
        "id": 2425,
        "name": "headgarantiamobile.png",
        "alternativeText": null,
        "caption": null,
        "width": 991,
        "height": 824,
        "formats": {
          "small": {
            "ext": ".png",
            "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1713449203487-small_headgarantiamobile.png.png",
            "hash": "small_headgarantiamobile_ed09457a4d",
            "mime": "image/png",
            "name": "small_headgarantiamobile.png",
            "path": null,
            "size": 145.31,
            "width": 500,
            "height": 416
          },
          "medium": {
            "ext": ".png",
            "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1713449203487-medium_headgarantiamobile.png.png",
            "hash": "medium_headgarantiamobile_ed09457a4d",
            "mime": "image/png",
            "name": "medium_headgarantiamobile.png",
            "path": null,
            "size": 310.02,
            "width": 750,
            "height": 624
          },
          "thumbnail": {
            "ext": ".png",
            "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1713449203406-thumbnail_headgarantiamobile.png.png",
            "hash": "thumbnail_headgarantiamobile_ed09457a4d",
            "mime": "image/png",
            "name": "thumbnail_headgarantiamobile.png",
            "path": null,
            "size": 27.21,
            "width": 188,
            "height": 156
          }
        },
        "hash": "headgarantiamobile_ed09457a4d",
        "ext": ".png",
        "mime": "image/png",
        "size": 133.61,
        "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1713449203371-headgarantiamobile.png.png",
        "previewUrl": null,
        "provider": "@strapi-community/strapi-provider-upload-google-cloud-storage",
        "provider_metadata": null,
        "createdAt": "2024-04-18T14:06:43.685Z",
        "updatedAt": "2024-04-18T14:06:43.685Z"
      },
      "media": {
        "id": 2424,
        "name": "headgarantia_desk.png",
        "alternativeText": null,
        "caption": null,
        "width": 2560,
        "height": 304,
        "formats": {
          "large": {
            "ext": ".png",
            "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1713449190642-large_headgarantia_desk.png.png",
            "hash": "large_headgarantia_desk_f197a0ae4b",
            "mime": "image/png",
            "name": "large_headgarantia_desk.png",
            "path": null,
            "size": 97.39,
            "width": 1000,
            "height": 119
          },
          "small": {
            "ext": ".png",
            "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1713449190642-small_headgarantia_desk.png.png",
            "hash": "small_headgarantia_desk_f197a0ae4b",
            "mime": "image/png",
            "name": "small_headgarantia_desk.png",
            "path": null,
            "size": 27.57,
            "width": 500,
            "height": 59
          },
          "medium": {
            "ext": ".png",
            "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1713449190642-medium_headgarantia_desk.png.png",
            "hash": "medium_headgarantia_desk_f197a0ae4b",
            "mime": "image/png",
            "name": "medium_headgarantia_desk.png",
            "path": null,
            "size": 56.76,
            "width": 750,
            "height": 89
          },
          "thumbnail": {
            "ext": ".png",
            "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1713449190553-thumbnail_headgarantia_desk.png.png",
            "hash": "thumbnail_headgarantia_desk_f197a0ae4b",
            "mime": "image/png",
            "name": "thumbnail_headgarantia_desk.png",
            "path": null,
            "size": 9.01,
            "width": 245,
            "height": 29
          }
        },
        "hash": "headgarantia_desk_f197a0ae4b",
        "ext": ".png",
        "mime": "image/png",
        "size": 142.49,
        "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1713449190431-headgarantia_desk.png.png",
        "previewUrl": null,
        "provider": "@strapi-community/strapi-provider-upload-google-cloud-storage",
        "provider_metadata": null,
        "createdAt": "2024-04-18T14:06:30.873Z",
        "updatedAt": "2024-04-18T14:06:30.873Z"
      }
    },
    {
      "id": 2544,
      "__component": "utils.rich-text",
      "text": "<p style=\"text-align:center;\">&nbsp;</p><h2 style=\"margin-left:0px;text-align:center;\">Perrelet Care Program</h2><div class=\"subtitle-4\">&nbsp;</div><div class=\"gris-5-color subtitle-4\"><strong>EXTEND YOUR INTERNATIONAL WARRANTY UP TO 5 YEARS</strong></div><p>&nbsp;</p><div class=\"paragraph-1\">Our master watchmakers have ensured the precision and reliability of PERRELET watches for more than 240 years. With this program of warranty extension, your watch will receive our expert care for a total of five years from the date of purchase.</div><div class=\"paragraph-1\">Register now and become a member of My PERRELET and benefit from a 2-year extension to our standard 3-year International Limited Warranty. As a club member, you will also receive useful tips on how to care your watch, the latest news and get a priority access to the limited editions.</div><p>&nbsp;</p><div class=\"paragraph-1\" style=\"text-align:center;\"><u>CONDITIONS</u></div><div class=\"paragraph-1\">&nbsp;</div><li class=\"paragraph-1\">This warranty extension is available for watches equipped with the movement P-331-MH, COSC certified and Chronofiable.</li><li class=\"paragraph-1\">The watch must have been purchased from our network of authorised dealers.</li><li class=\"paragraph-1\">The warranty extension request  must be made within a maximum of 90 days from the date of purchase.</li><p>&nbsp;</p><div class=\"paragraph-1\">Do not hesitate to contact your PERRELET dealer to find out if your watch is eligible for the warranty extension.</div><div class=\"paragraph-1\">Please check the <strong><u> <a class=\"font-weight-bold\" href=\"/en-GB/legal-terms-and-conditions\" target=\"_blank\">Legal Conditions</a></u></strong> of this Perrelet Warranty Extension service.</div><p>&nbsp;</p>",
      "fullWidth": false,
      "unpublish": false,
      "key": null
    },
    {
      "fullWidth": false,
      "id": 405,
      "show": false,
      "unpublish": false,
      "__component": "utils.warranty-extension"
    },
    {
      "id": 2544,
      "__component": "utils.rich-text",
      "text": "<div class=\"raw-html-embed\"><div class=\"pt-5\"></div></div>",
      "fullWidth": false,
      "unpublish": false,
      "key": null
    },
    {
      "id": 390,
      "__component": "landing.card-image-grid",
      "textButton": null,
      "linkButton": null,
      "fullWidth": true,
      "spaced": true,
      "params": null,
      "buttonType": null,
      "unpublish": false,
      "key": null,
      "item": [
        {
          "id": 4264,
          "title": null,
          "textButton": null,
          "url": null,
          "reverse": false,
          "text": "<div class=\"px-2 px-md-5\"><h2 style=\"margin-left:0px;\">Which watches can extend their warranty?</h2><div class=\"paragraph-1\" style=\"margin-left:0px;\">&nbsp;</div><div class=\"paragraph-1\" style=\"margin-left:0px;\"><span style=\"background-color:rgb(255,255,255);color:rgb(55,52,48);font-family:&quot;Work Sans&quot;, Helvetica, Arial, sans-serif;\">All PERRELET watches equipped with the in-house caliber P-331-MH, certified by COSC and purchased through our authorized network, will be eligible for this 2-year extension to reach a total of 5 years of international warranty.</span><br><span style=\"background-color:rgb(255,255,255);color:rgb(55,52,48);font-family:&quot;Work Sans&quot;, Helvetica, Arial, sans-serif;\">&nbsp;</span><br><span style=\"background-color:rgb(255,255,255);color:rgb(55,52,48);font-family:&quot;Work Sans&quot;, Helvetica, Arial, sans-serif;\">The warranty extension request must be made within a maximum of 90 days from the date of purchase of the watch.</span><br><span style=\"background-color:rgb(255,255,255);color:rgb(55,52,48);font-family:&quot;Work Sans&quot;, Helvetica, Arial, sans-serif;\">&nbsp;</span><br><span style=\"background-color:rgb(255,255,255);color:rgb(55,52,48);font-family:&quot;Work Sans&quot;, Helvetica, Arial, sans-serif;\">For more details, please refer to the Terms and Conditions of the International Limited Warranty extension.</span></div></div>",
          "effect": false,
          "illuminate": false,
          "gridMedia": "col-lg-6",
          "gridMediaMobile": "col-12",
          "titleImage": null,
          "titleImageColor": null,
          "controls": false,
          "params": null,
          "fullWidth": false,
          "reverseColumn": false,
          "background": null,
          "buttonType": null,
          "unpublish": false,
          "translateY": null,
          "fragment": null,
          "key": null,
          "videoLoop": true,
          "media": {
            "id": 2370,
            "name": "Que_relojes.png",
            "alternativeText": null,
            "caption": null,
            "width": 1060,
            "height": 900,
            "formats": {
              "large": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1711626144314-large_Que_relojes.png.png",
                "hash": "large_Que_relojes_3256eb1a2d",
                "mime": "image/png",
                "name": "large_Que_relojes.png",
                "path": null,
                "size": 537.63,
                "width": 1000,
                "height": 849
              },
              "small": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1711626144314-small_Que_relojes.png.png",
                "hash": "small_Que_relojes_3256eb1a2d",
                "mime": "image/png",
                "name": "small_Que_relojes.png",
                "path": null,
                "size": 154.55,
                "width": 500,
                "height": 425
              },
              "medium": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1711626144314-medium_Que_relojes.png.png",
                "hash": "medium_Que_relojes_3256eb1a2d",
                "mime": "image/png",
                "name": "medium_Que_relojes.png",
                "path": null,
                "size": 323.36,
                "width": 750,
                "height": 637
              },
              "thumbnail": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1711626144218-thumbnail_Que_relojes.png.png",
                "hash": "thumbnail_Que_relojes_3256eb1a2d",
                "mime": "image/png",
                "name": "thumbnail_Que_relojes.png",
                "path": null,
                "size": 26.91,
                "width": 184,
                "height": 156
              }
            },
            "hash": "Que_relojes_3256eb1a2d",
            "ext": ".png",
            "mime": "image/png",
            "size": 131.95,
            "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1711626144173-Que_relojes.png.png",
            "previewUrl": null,
            "provider": "@strapi-community/strapi-provider-upload-google-cloud-storage",
            "provider_metadata": null,
            "createdAt": "2024-03-28T11:42:24.632Z",
            "updatedAt": "2024-03-28T11:42:24.632Z"
          },
          "mediaMobile": null
        },
        {
          "id": 4265,
          "title": null,
          "textButton": null,
          "url": null,
          "reverse": true,
          "text": "<div class=\"px-2 px-md-5\"><h2 style=\"margin-left:0px;\">How to register your watch?</h2><div class=\"paragraph-1\" style=\"margin-left:0px;\">&nbsp;</div><div class=\"paragraph-1\" style=\"margin-left:0px;\"><span style=\"background-color:rgb(255,255,255);color:rgb(55,52,48);font-family:&quot;Work Sans&quot;, Helvetica, Arial, sans-serif;\">You can register your watch to request an extension of your warranty in three simple steps:</span></div><ol style=\"list-style-type:decimal;\"><li class=\"paragraph-1\">Have your Perrelet warranty card on hand.</li><li class=\"paragraph-1\">Fill out the form with the details of your Perrelet watch and your personal information.</li><li class=\"paragraph-1\">Complete the registration of your watch.</li></ol></div>",
          "effect": false,
          "illuminate": false,
          "gridMedia": "col-lg-6",
          "gridMediaMobile": "col-12",
          "titleImage": null,
          "titleImageColor": null,
          "controls": false,
          "params": null,
          "fullWidth": false,
          "reverseColumn": false,
          "background": null,
          "buttonType": null,
          "unpublish": false,
          "translateY": null,
          "fragment": null,
          "key": null,
          "videoLoop": true,
          "media": {
            "id": 2371,
            "name": "como_registrar.png",
            "alternativeText": null,
            "caption": null,
            "width": 1060,
            "height": 900,
            "formats": {
              "large": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1711626144788-large_como_registrar.png.png",
                "hash": "large_como_registrar_e283c5ef28",
                "mime": "image/png",
                "name": "large_como_registrar.png",
                "path": null,
                "size": 960.25,
                "width": 1000,
                "height": 849
              },
              "small": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1711626144789-small_como_registrar.png.png",
                "hash": "small_como_registrar_e283c5ef28",
                "mime": "image/png",
                "name": "small_como_registrar.png",
                "path": null,
                "size": 265.44,
                "width": 500,
                "height": 425
              },
              "medium": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1711626144789-medium_como_registrar.png.png",
                "hash": "medium_como_registrar_e283c5ef28",
                "mime": "image/png",
                "name": "medium_como_registrar.png",
                "path": null,
                "size": 564.7,
                "width": 750,
                "height": 637
              },
              "thumbnail": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1711626144478-thumbnail_como_registrar.png.png",
                "hash": "thumbnail_como_registrar_e283c5ef28",
                "mime": "image/png",
                "name": "thumbnail_como_registrar.png",
                "path": null,
                "size": 47.59,
                "width": 184,
                "height": 156
              }
            },
            "hash": "como_registrar_e283c5ef28",
            "ext": ".png",
            "mime": "image/png",
            "size": 231.88,
            "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1711626144403-como_registrar.png.png",
            "previewUrl": null,
            "provider": "@strapi-community/strapi-provider-upload-google-cloud-storage",
            "provider_metadata": null,
            "createdAt": "2024-03-28T11:42:25.603Z",
            "updatedAt": "2024-03-28T11:42:25.603Z"
          },
          "mediaMobile": null
        },
        {
          "id": 4266,
          "title": null,
          "textButton": null,
          "url": null,
          "reverse": false,
          "text": "<div class=\"px-2 px-md-5\"><h2 style=\"margin-left:0px;\">At your service</h2><div class=\"paragraph-1\" style=\"margin-left:0px;\">&nbsp;</div><div class=\"paragraph-1\" style=\"margin-left:0px;\"><span style=\"background-color:rgb(255,255,255);color:rgb(55,52,48);font-family:&quot;Work Sans&quot;, Helvetica, Arial, sans-serif;\">Our Concierge service is available to address any questions that may arise during the registration process of your watch.</span></div></div>",
          "effect": false,
          "illuminate": false,
          "gridMedia": "col-lg-6",
          "gridMediaMobile": "col-12",
          "titleImage": null,
          "titleImageColor": null,
          "controls": false,
          "params": null,
          "fullWidth": false,
          "reverseColumn": false,
          "background": null,
          "buttonType": null,
          "unpublish": false,
          "translateY": null,
          "fragment": null,
          "key": null,
          "videoLoop": true,
          "media": {
            "id": 2372,
            "name": "asu servicio.png",
            "alternativeText": null,
            "caption": null,
            "width": 1060,
            "height": 900,
            "formats": {
              "large": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1711626145453-large_asu-servicio.png.png",
                "hash": "large_asu_servicio_e70b631425",
                "mime": "image/png",
                "name": "large_asu servicio.png",
                "path": null,
                "size": 2093.25,
                "width": 1000,
                "height": 849
              },
              "small": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1711626145453-small_asu-servicio.png.png",
                "hash": "small_asu_servicio_e70b631425",
                "mime": "image/png",
                "name": "small_asu servicio.png",
                "path": null,
                "size": 526.01,
                "width": 500,
                "height": 425
              },
              "medium": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1711626145453-medium_asu-servicio.png.png",
                "hash": "medium_asu_servicio_e70b631425",
                "mime": "image/png",
                "name": "medium_asu servicio.png",
                "path": null,
                "size": 1193.88,
                "width": 750,
                "height": 637
              },
              "thumbnail": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1711626144851-thumbnail_asu-servicio.png.png",
                "hash": "thumbnail_asu_servicio_e70b631425",
                "mime": "image/png",
                "name": "thumbnail_asu servicio.png",
                "path": null,
                "size": 73.72,
                "width": 184,
                "height": 156
              }
            },
            "hash": "asu_servicio_e70b631425",
            "ext": ".png",
            "mime": "image/png",
            "size": 486.86,
            "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1711626144782-asu-servicio.png.png",
            "previewUrl": null,
            "provider": "@strapi-community/strapi-provider-upload-google-cloud-storage",
            "provider_metadata": null,
            "createdAt": "2024-03-28T11:42:26.039Z",
            "updatedAt": "2024-03-28T11:42:26.039Z"
          },
          "mediaMobile": null
        }
      ],
      "grid": {
        "id": 742,
        "gridMobile": "col-12",
        "grid": "col-lg-12"
      }
    },
    {
      "id": 2544,
      "__component": "utils.rich-text",
      "text": "<p style=\"text-align:center;\">&nbsp;</p><h1 style=\"text-align:center;\">Services</h1><div class=\"subtitle-4\" style=\"text-align:center;\">&nbsp;</div><div class=\"paragraph-1\">At Perrelet, our commitment to our customers is a cornerstone of our corporate culture. Beyond offering the finest products, we take care of the maintenance, repairs, and restoration of all Perrelet pieces. With meticulous attention to detail and unparalleled expertise, we guarantee the impeccable care of your Perrelet watch, preserving its timeless elegance and performance.</div>",
      "fullWidth": false,
      "unpublish": false,
      "key": null
    },
    {
      "id": 159,
      "__component": "landing.card-image-grid-v2",
      "fullWidth": false,
      "backgroundColor": null,
      "gutters": true,
      "key": null,
      "unpublish": null,
      "item": [
        {
          "id": 550,
          "text": "<div class=\"raw-html-embed\"><h3 class=\"mt-4 font-weight-bold paragraph-1 mb-2\">\n   PERRELET LABORATORY\n</h3>\n<p>\nTranquility for a lifetime of enjoyment. Perrelet watches are designed to last, and our master watchmakers will ensure that your watch remains in perfect condition, service after service.\n</p></div>",
          "textPosition": "bottom",
          "title": null,
          "titleColor": null,
          "titleStyle": "header-2",
          "titlePosition": "top",
          "titleAlign": "center",
          "textButton": "FIND OUT",
          "buttonType": "btn-bottom-outline-i-arrow",
          "buttonPosition": "bottom",
          "url": "/watchmaking-laboratory",
          "params": null,
          "effect": "none",
          "controls": false,
          "gridMedia": "col-lg-12",
          "gridMediaMobile": "col-12",
          "translateY": null,
          "key": null,
          "unpublish": null,
          "videoLoop": null,
          "buttonAlign": "left",
          "media": {
            "id": 2392,
            "name": "laboratorio perrelet991 x 375.png",
            "alternativeText": null,
            "caption": null,
            "width": 991,
            "height": 690,
            "formats": {
              "small": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1712241870713-small_laboratorio-perrelet991-x-375.png.png",
                "hash": "small_laboratorio_perrelet991_x_375_5c75d7f869",
                "mime": "image/png",
                "name": "small_laboratorio perrelet991 x 375.png",
                "path": null,
                "size": 276.15,
                "width": 500,
                "height": 348
              },
              "medium": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1712241870713-medium_laboratorio-perrelet991-x-375.png.png",
                "hash": "medium_laboratorio_perrelet991_x_375_5c75d7f869",
                "mime": "image/png",
                "name": "medium_laboratorio perrelet991 x 375.png",
                "path": null,
                "size": 560.49,
                "width": 750,
                "height": 522
              },
              "thumbnail": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1712241870507-thumbnail_laboratorio-perrelet991-x-375.png.png",
                "hash": "thumbnail_laboratorio_perrelet991_x_375_5c75d7f869",
                "mime": "image/png",
                "name": "thumbnail_laboratorio perrelet991 x 375.png",
                "path": null,
                "size": 68.4,
                "width": 224,
                "height": 156
              }
            },
            "hash": "laboratorio_perrelet991_x_375_5c75d7f869",
            "ext": ".png",
            "mime": "image/png",
            "size": 227.4,
            "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1712241870442-laboratorio-perrelet991-x-375.png.png",
            "previewUrl": null,
            "provider": "@strapi-community/strapi-provider-upload-google-cloud-storage",
            "provider_metadata": null,
            "createdAt": "2024-04-03T15:22:14.619Z",
            "updatedAt": "2024-04-04T14:44:30.968Z"
          },
          "mediaMobile": null
        },
        {
          "id": 551,
          "text": "<div class=\"raw-html-embed\"><h3 class=\"mt-4 font-weight-bold paragraph-1 mb-2\">\n   MANUFACTURER'S WARRANTY\n</h3>\n<p>\nEnjoy the peace of mind of an international warranty against manufacturing defects on your Perrelet watches. Valid from the date of purchase and with personalized attention at official centers.\n</p></div>",
          "textPosition": "bottom",
          "title": null,
          "titleColor": null,
          "titleStyle": "header-2",
          "titlePosition": "top",
          "titleAlign": "center",
          "textButton": "MORE INFORMATION",
          "buttonType": "btn-bottom-outline-i-arrow",
          "buttonPosition": "bottom",
          "url": "/manufacturing-warranty",
          "params": null,
          "effect": "none",
          "controls": false,
          "gridMedia": "col-lg-12",
          "gridMediaMobile": "col-12",
          "translateY": null,
          "key": null,
          "unpublish": null,
          "videoLoop": null,
          "buttonAlign": "left",
          "media": {
            "id": 2393,
            "name": "garantia de fabricacion991 x 375.png",
            "alternativeText": null,
            "caption": null,
            "width": 991,
            "height": 690,
            "formats": {
              "small": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1712157734526-small_garantia-de-fabricacion991-x-375.png.png",
                "hash": "small_garantia_de_fabricacion991_x_375_4286d2647f",
                "mime": "image/png",
                "name": "small_garantia de fabricacion991 x 375.png",
                "path": null,
                "size": 209.96,
                "width": 500,
                "height": 348
              },
              "medium": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1712157734526-medium_garantia-de-fabricacion991-x-375.png.png",
                "hash": "medium_garantia_de_fabricacion991_x_375_4286d2647f",
                "mime": "image/png",
                "name": "medium_garantia de fabricacion991 x 375.png",
                "path": null,
                "size": 440.3,
                "width": 750,
                "height": 522
              },
              "thumbnail": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1712157734301-thumbnail_garantia-de-fabricacion991-x-375.png.png",
                "hash": "thumbnail_garantia_de_fabricacion991_x_375_4286d2647f",
                "mime": "image/png",
                "name": "thumbnail_garantia de fabricacion991 x 375.png",
                "path": null,
                "size": 53.29,
                "width": 224,
                "height": 156
              }
            },
            "hash": "garantia_de_fabricacion991_x_375_4286d2647f",
            "ext": ".png",
            "mime": "image/png",
            "size": 160.65,
            "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1712157734202-garantia-de-fabricacion991-x-375.png.png",
            "previewUrl": null,
            "provider": "@strapi-community/strapi-provider-upload-google-cloud-storage",
            "provider_metadata": null,
            "createdAt": "2024-04-03T15:22:14.741Z",
            "updatedAt": "2024-04-03T15:22:14.741Z"
          },
          "mediaMobile": null
        }
      ],
      "grid": {
        "id": 525,
        "gridMobile": "col-12",
        "grid": "col-lg-6"
      }
    },
    {
      "fullWidth": true,
      "id": 403,
      "show": false,
      "unpublish": false,
      "__component": "shared.subscribe"
    }
  ]
}
