{
  "accesorio": {
    "h1": "Accessoires",
    "meta": "Vind het perfecte cadeau voor elke gelegenheid of trakteer uzelf en laat de beste accessoires zien. Bezoek nu onze catalogus. {{numPagina}}",
    "title": "Accessoires | Perrelet"
  },
  "accesorio.default": {
    "h1": "Accessoires {{tipoAccesorio}} {{subtipoAccesorio}} {{tipoBoligrafo}}",
    "meta": "Vind het perfecte cadeau voor elke gelegenheid of trakteer uzelf en laat de beste accessoires zien. Bezoek nu onze catalogus. {{numPagina}}",
    "robots": "false",
    "title": "Accessoires {{tipoAccesorio}} {{subtipoAccesorio}} {{tipoBoligrafo}} | Perrelet"
  },
  "accesorio.LEATHER_ITEM.CARD_HOLDER": {
    "h1": "Leren kaarthouders",
    "meta": "De beste manier om uw kaarten georganiseerd te houden is met onze lederen kaarthouders, gemaakt van het meest bestendige en elegante materiaal.",
    "title": "Leren kaarthouders : Perrelet"
  },
  "accesorio.LEATHER_ITEM.WALLET": {
    "h1": "Lederen portefeuilles",
    "meta": "Onze lederen portefeuilles zijn perfect om cadeau te geven. Gemaakt van duurzaam materiaal en verkrijgbaar in diverse kleuren, zijn ze ideaal om overal mee naartoe te nemen.",
    "title": "Leren Portemonnees : Perrelet"
  },
  "accesorio.subtipoAccesorio.default": {
    "h1": "{{subtipoAccesorio}}",
    "meta": "Ontdek onze selectie {{subtipoAccesorio}} in onze officiële online shop.",
    "title": "{{subtipoAccesorio}} | Perrelet"
  },
  "accesorio.subtipoAccesorio.SOLAR": {
    "h1": "Zonnebril",
    "meta": "Ontdek onze selectie zonnebrillen in onze officiële online shop.",
    "title": "Zonnebril | Perrelet"
  },
  "accesorio.tipoAccesorio.default": {
    "h1": "Accessoires {{tipoAccesorio}}",
    "meta": "Uit onze selectie Perrelet Accessoires vindt u het ideale geschenk voor speciale gelegenheden of om uzelf te verwennen.",
    "title": "Accessoires {{tipoAccesorio}} | Perrelet"
  },
  "accesorio.tipoAccesorio.KEYCHAIN": {
    "h1": "sleutelhangers",
    "meta": "Perrelet sleutelhangers zijn een must-have accessoire om uw stijl aan te vullen. Ze zijn gestroomlijnd en modern en geven de drager een karakteristiek tintje.",
    "title": "Sleutelhanger : Perrelet"
  },
  "accesorio.WRITING.BALLPOINT_PEN": {
    "h1": "Balpennen",
    "meta": "Onze balpennen zijn de gemakkelijkste en meest comfortabele pennen geworden om mee te schrijven. Ze hebben ook een subtiel en exclusief ontwerp.",
    "title": "Balpennen : Perrelet"
  },
  "accesorio.WRITING.FOUNTAIN_PEN": {
    "h1": "Vulpennen",
    "meta": "Geef een persoonlijk tintje aan uw kalligrafie met onze vulpennen. In onze catalogus vindt u ze in verschillende kleuren en uitvoeringen.",
    "title": "Perrelet Vulpennen"
  },
  "accesorio.WRITING.REPLACEMENT": {
    "h1": "Vullingen voor balpennen",
    "meta": "In onze online winkel kunt u ook navullingen voor pennen kopen, en wel in verpakkingen van twee, zodat u ze altijd bij de hand hebt.",
    "title": "Vullingen voor balpennen | Perrelet"
  },
  "accesorio.WRITING.ROLLERBALL_PEN": {
    "h1": "Rollerball pennen",
    "meta": "Ontdek onze rollerbalpennen, luxe pennen met een elegant en discreet design, perfect als geschenk voor speciale momenten.",
    "title": "Rollerball pennen : Perrelet"
  },
  "reloj": {
    "h1": "Horloges",
    "meta": "Op zoek naar een uniek en elegant horloge? Ontdek onze horloges van de beste merken en vind het perfecte model voor jou. {{numPagina}}",
    "title": "Horloges {{numPagina}} | Perrelet"
  },
  "reloj.C": {
    "h1": "Horloges voor heren",
    "meta": "Onze herenhorloges zijn perfect voor elke gelegenheid. Uniek ontworpen, zijn ze ideaal om een elegante en unieke stijl aan te vullen. {{numPagina}}",
    "title": "Herenhorloges {{numPagina}} | Perrelet"
  },
  "reloj.colorEsfera.default": {
    "h1": "Horloges met wijzerplaten {{ colorEsfera }}",
    "meta": "Op zoek naar een uniek en elegant horloge? Ontdek onze horloges met {{ colorEsfera }} wijzerplaten van de beste merken en vind het perfecte model voor u.",
    "title": "Horloges met wijzerplaten {{ colorEsfera }} | Perrelet"
  },
  "reloj.default": {
    "h1": "Klokken {{marca}} {{funcion}} {{estilos}} {{colorCorrea}} {{colorEsfera}} {{materialCaja}} {{materialCorrea}}",
    "meta": "Op zoek naar een uniek en elegant horloge? Ontdek onze horloges van de beste merken en vind het perfecte model voor jou. {{numPagina}}",
    "robots": "false",
    "title": "Klokken {{gama}} {{marca}} {{funcion}} {{estilos}} {{colorCorrea}} {{colorEsfera}} {{materialCaja}} {{materialCorrea}} {{numPagina}} | Perrelet"
  },
  "reloj.funcion.default": {
    "h1": "Horloges {{funcion}}",
    "meta": "Op zoek naar een klok {{funcion}}? Vind de beste modellen van de beste merken en kies het perfecte horloge voor jou. Bezoek nu onze catalogus {{numPagina}}",
    "title": "Horloges {{funcion}} {{numPagina}} | Perrelet"
  },
  "reloj.gama.colorCorrea.default": {
    "h1": "Horloges {{colorCorrea}} voor {{gama}}",
    "meta": "Wij hebben een uitgebreide keuze aan kleuren en materialen, zodat u de Horloges {{colorCorrea}} voor {{gama}} kunt kiezen die het beste bij uw stijl passen. {{numPagina}}",
    "title": "Horloges {{colorCorrea}} voor {{gama}} {{numPagina}} | Perrelet"
  },
  "reloj.gama.colorEsfera.default": {
    "h1": "Horloges met bol {{colorEsfera}} voor {{gama}}",
    "meta": "Onderscheidend, uniek en verfijnd. Dat zijn onze horloges met wijzerplaat {{colorEsfera}} voor {{gama}} die een vleugje elegantie aan uw pols zullen geven.",
    "title": "Horloges met bol {{colorEsfera}} voor {{gama}} {{numPagina}} | Perrelet"
  },
  "reloj.gama.funcion.default": {
    "h1": "Klokken {{funcion}} voor {{gama}}",
    "meta": "Vind de meest verbazingwekkende en verfijnde {{funcion}} horloges voor {{gama}} by Perrelet op de markt en draag ze voor elke gelegenheid. {{numPagina}}",
    "title": "Horloges {{funcion}} voor {{gama}} {{numPagina}} | Perrelet"
  },
  "reloj.gama.materialCaja.default": {
    "h1": "{{gama}} horloges met {{materialCaja}}behuizing",
    "meta": "De horloges met {{materialCaja}} tot {{gama}} koffers vallen op door hun zorgvuldige ontwerp. Ga naar onze winkel en kies degene die het beste bij u past.",
    "title": "{{gama}} horloges met {{materialCaja}} {{numPagina}} kast | Perrelet"
  },
  "reloj.gama.materialCorrea.default": {
    "h1": "Horloges van {{materialCorrea}} tot {{gama}}",
    "meta": "De {{materialCorrea}} tot {{gama}} horloges van Perrelet zijn de perfecte keuze om een exclusief en handig uurwerk om uw pols te dragen.",
    "title": "Horloges van {{materialCorrea}} tot {{gama}} | Perrelet"
  },
  "reloj.gama.tipoCierre.default": {
    "h1": "Houdt {{tipoCierre}} in de gaten voor {{gama}}",
    "meta": "Op onze website kunt u de ruime keuze aan Horloges {{tipoCierre}} voor {{gama}} bekijken die wij aanbieden. We hebben exclusieve stukken speciaal voor jou.",
    "title": "Horloges {{tipoCierre}} voor {{gama}} | Perrelet"
  },
  "reloj.gama.tipoCorrea.ARMYS": {
    "h1": "Houdt {{tipoCorrea}} in de gaten voor {{gama}}",
    "meta": "Wist u dat wij bij Perrelet Horloges {{tipoCorrea}} voor {{gama}} aanbieden voor allerlei gelegenheden? Vind de Horloges die het beste bij uw persoonlijkheid passen!",
    "title": "Horloges {{tipoCorrea}} voor {{gama}} | Perrelet"
  },
  "reloj.gama.tipoCorrea.CORREA": {
    "h1": "Houdt {{tipoCorrea}} in de gaten voor {{gama}}",
    "meta": "Wist u dat wij bij Perrelet Horloges {{tipoCorrea}} voor {{gama}} aanbieden voor allerlei gelegenheden? Vind de Horloges die het beste bij uw persoonlijkheid passen!",
    "title": "Horloges {{tipoCorrea}} voor {{gama}} | Perrelet"
  },
  "reloj.gama.tipoEstanqueidad.default": {
    "h1": "Horloges van {{tipoEstanqueidad}} tot {{gama}}",
    "meta": "Raadpleeg onze uitgebreide catalogus van Horloges van {{tipoEstanqueidad}} tot {{gama}} gemaakt met de beste materialen en technieken op de markt.",
    "title": "Horloges van {{tipoEstanqueidad}} tot {{gama}} | Perrelet"
  },
  "reloj.gama.tiposMovimiento.01": {
    "h1": "Digitale horloges voor  Perrelet",
    "meta": "Ben je op zoek naar een digitaal horloge voor  Perrelet? Bij {{gama}} hebben we een grote selectie horloges beschikbaar. Kom binnen en kies degene die het beste bij je past.",
    "title": "Digitale horloges voor  Perrelet | {{gama}}"
  },
  "reloj.gama.tiposMovimiento.02": {
    "h1": "Horloges voor {{gama}} met Digitaal Kwarts beweging.",
    "meta": "Ben je op zoek naar Perrelet horloges met Digitaal Kwarts beweging? Neem een kijkje in ons uitgebreide assortiment horloges voor  Perrelet en kies het perfecte model voor jou.",
    "robots": "false",
    "title": "Horloges voor {{gama}} met Digitaal Kwarts beweging | Perrelet"
  },
  "reloj.gama.tiposMovimiento.04": {
    "h1": "Automatische horloges voor  Perrelet",
    "meta": "Ben je op zoek naar een automatisch horloge voor  Perrelet? Bij {{gama}} hebben we een grote selectie horloges beschikbaar. Kom binnen en kies degene die het beste bij je past.",
    "title": "Automatische horloges voor  Perrelet | {{gama}}"
  },
  "reloj.gama.tiposMovimiento.05": {
    "h1": "Hybride horloges voor  Perrelet",
    "meta": "Ben je op zoek naar een hybride horloge voor  Perrelet? Bij {{gama}} hebben we een grote selectie horloges beschikbaar. Kom binnen en kies degene die het beste bij je past.",
    "title": "Hybride horloges voor  Perrelet | {{gama}}"
  },
  "reloj.gama.tiposMovimiento.06": {
    "h1": "Smartwatches voor  Perrelet",
    "meta": "Ben je op zoek naar een smartwatch voor  Perrelet? Bij {{gama}} hebben we een grote selectie slimme horloges beschikbaar. Kom binnen en kies degene die het beste bij je past.",
    "title": "Smartwatches voor  Perrelet - Slimme horloges | {{gama}}"
  },
  "reloj.gama.tiposMovimiento.07": {
    "h1": "Kwarts horloges voor  Perrelet",
    "meta": "Ben je op zoek naar een quartz horloge voor  Perrelet? Bij {{gama}} hebben we een grote selectie horloges beschikbaar. Kom binnen en kies degene die het beste bij je past.",
    "title": "Kwarts horloges voor  Perrelet | {{gama}}"
  },
  "reloj.IN": {
    "h1": "Horloges voor kinderen",
    "meta": "Op zoek naar de beste horloges voor kinderen? In onze onlineshop vindt u leuke en gevarieerde horloges voor de allerkleinsten van het gezin. {{numPagina}}",
    "title": "Kinderhorloges {{numPagina}} | Perrelet"
  },
  "reloj.IN.funcion": {
    "h1": "Horloges {{funcion}} voor kinderen",
    "meta": "Wilt u een {{funcion}} horloge voor een kind? Vind een verscheidenheid aan kinderhorloges {{funcion}} van de beste merken. Bezoek onze catalogus! {{numPagina}}",
    "title": "Horloges {{funcion}} voor kinderen {{numPagina}} | Perrelet"
  },
  "reloj.materialCaja.default": {
    "h1": "Horloges met {{materialCaja}}kast",
    "meta": "Hou je van {{materialCaja}}horloges? Ontdek onze uitgebreide catalogus en vind het horloge dat uw pols laat stralen. {{numPagina}}",
    "title": "Horloges met {{materialCaja}} {{numPagina}} kast | Perrelet"
  },
  "reloj.materialCorrea.default": {
    "h1": "Horloges {{materialCorrea}}",
    "meta": "Houd je van horloges met een {{materialCorrea}}riempje? Ontdek onze uitgebreide catalogus en vind het horloge dat uw pols laat stralen. {{numPagina}}",
    "title": "Horloges {{materialCorrea}} {{numPagina}} | Perrelet"
  },
  "reloj.S": {
    "h1": "Dames Horloges",
    "meta": "Zoek in onze catalogus met dameshorloges het exemplaar dat het beste bij uw stijl en persoonlijkheid past en draag het comfortabel in uw dagelijks leven. {{numPagina}}",
    "title": "Dameshorloges {{numPagina}} | Perrelet"
  },
  "reloj.tipoCierre.default": {
    "h1": "Horloges {{tipoCierre}}",
    "meta": "Op zoek naar een klok {{tipoCierre}}? Vind de beste modellen van de beste merken en kies het perfecte horloge voor jou. Bezoek nu onze catalogus {{numPagina}}",
    "title": "Horloges {{tipoCierre}} {{numPagina}} | Perrelet"
  },
  "reloj.tipoCorrea.default": {
    "h1": "Horloges {{tipoCorrea}}",
    "meta": "Op zoek naar een klok {{tipoCorrea}}? Vind de beste modellen van de beste merken en kies het perfecte horloge voor jou. Bezoek nu onze catalogus {{numPagina}}",
    "title": "Horloges {{tipoCorrea}} {{numPagina}} | Perrelet"
  },
  "reloj.tiposMovimiento.01": {
    "h1": "Digitale horloges",
    "meta": "Ben je op zoek naar een digitale klok? Bij Perrelet hebben we een grote selectie digitale klokken beschikbaar. Kom binnen en kies degene die het beste bij je past.",
    "robots": "false",
    "title": "Digitale horloges | Perrelet"
  },
  "reloj.tiposMovimiento.02": {
    "h1": "Horloges met beweging Digitaal Kwarts",
    "meta": "Ben je op zoek naar horloges met Digitaal Kwarts beweging? Neem een kijkje in ons uitgebreide assortiment horloges voor  Perrelet en kies het perfecte model voor jou.",
    "robots": "false",
    "title": "Horloges met beweging Digitaal Kwarts | Perrelet"
  },
  "reloj.tiposMovimiento.03": {
    "h1": "Horloges met beweging Handmatig",
    "meta": "Ben je op zoek naar horloges met Handmatig beweging? Neem een kijkje in ons uitgebreide assortiment horloges en kies het perfecte model voor jou.",
    "robots": "false",
    "title": "Horloges met beweging Handmatig | Perrelet"
  },
  "reloj.tiposMovimiento.04": {
    "h1": "Automatische horloges",
    "meta": "Ben je op zoek naar een automatisch horloge? Bij Perrelet hebben we een grote selectie automatische horloges beschikbaar. Kom binnen en kies degene die het beste bij je past.",
    "title": "Automatische horloges | Perrelet"
  },
  "reloj.tiposMovimiento.05": {
    "h1": "Hybride horloges",
    "meta": "Ben je op zoek naar een automatisch horloge? Bij Perrelet hebben we een grote selectie automatische horloges beschikbaar. Kom binnen en kies degene die het beste bij je past.",
    "robots": "false",
    "title": "Hybride horloges | Perrelet"
  },
  "reloj.tiposMovimiento.06": {
    "h1": "Slimme horloges",
    "meta": "Ben je op zoek naar een smartwatch? Bij Perrelet hebben we een grote selectie slimme horloges beschikbaar. Kom binnen en kies degene die het beste bij jouw stijl past.",
    "title": "Smartwatches - Slimme horloges | Perrelet"
  },
  "reloj.tiposMovimiento.07": {
    "h1": "Kwarts horloges",
    "meta": "Ben je op zoek naar een kwarts horloge? Bij Perrelet hebben we een grote selectie kwarts horloges beschikbaar. Kom binnen en kies degene die het beste bij je past.",
    "robots": "false",
    "title": "Kwarts horloges | Perrelet"
  }
}
