import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { injectSpeedInsights } from '@vercel/speed-insights';
import { AppBrowserModule } from './app/app.browser.module';
import { environment } from './environments/environment';

Sentry.init({
  dsn: 'https://4e1bc591e476d39b418be866d1ada138@o4508642024357888.ingest.de.sentry.io/4508676848353360',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  environment: environment.appEnv,
  release: environment.version,
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enableds
  tracePropagationTargets: ['localhost', environment.JWTDomainPublic, environment.cms.endpoint],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (environment.production) {
  enableProdMode();
  injectSpeedInsights();
}

platformBrowserDynamic()
  .bootstrapModule(AppBrowserModule)
  .catch(err => console.error(err));
