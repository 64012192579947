{
  "content": [
    {
      "id": 60,
      "__component": "utils.text-on-background",
      "text": "<div class=\"raw-html-embed\"><h1 class=\"text-center text-light\">Extensión de Garantia</h1></div>",
      "positionX": "center",
      "positionY": "center",
      "mobilePositionY": "center",
      "buttonText": null,
      "url": null,
      "background": null,
      "buttonType": null,
      "unpublish": null,
      "height": 500,
      "params": null,
      "fragment": null,
      "key": null,
      "videoLoop": true,
      "mediaMobile": {
        "id": 2425,
        "name": "headgarantiamobile.png",
        "alternativeText": null,
        "caption": null,
        "width": 991,
        "height": 824,
        "formats": {
          "small": {
            "ext": ".png",
            "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1713449203487-small_headgarantiamobile.png.png",
            "hash": "small_headgarantiamobile_ed09457a4d",
            "mime": "image/png",
            "name": "small_headgarantiamobile.png",
            "path": null,
            "size": 145.31,
            "width": 500,
            "height": 416
          },
          "medium": {
            "ext": ".png",
            "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1713449203487-medium_headgarantiamobile.png.png",
            "hash": "medium_headgarantiamobile_ed09457a4d",
            "mime": "image/png",
            "name": "medium_headgarantiamobile.png",
            "path": null,
            "size": 310.02,
            "width": 750,
            "height": 624
          },
          "thumbnail": {
            "ext": ".png",
            "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1713449203406-thumbnail_headgarantiamobile.png.png",
            "hash": "thumbnail_headgarantiamobile_ed09457a4d",
            "mime": "image/png",
            "name": "thumbnail_headgarantiamobile.png",
            "path": null,
            "size": 27.21,
            "width": 188,
            "height": 156
          }
        },
        "hash": "headgarantiamobile_ed09457a4d",
        "ext": ".png",
        "mime": "image/png",
        "size": 133.61,
        "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1713449203371-headgarantiamobile.png.png",
        "previewUrl": null,
        "provider": "@strapi-community/strapi-provider-upload-google-cloud-storage",
        "provider_metadata": null,
        "createdAt": "2024-04-18T14:06:43.685Z",
        "updatedAt": "2024-04-18T14:06:43.685Z"
      },
      "media": {
        "id": 2424,
        "name": "headgarantia_desk.png",
        "alternativeText": null,
        "caption": null,
        "width": 2560,
        "height": 304,
        "formats": {
          "large": {
            "ext": ".png",
            "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1713449190642-large_headgarantia_desk.png.png",
            "hash": "large_headgarantia_desk_f197a0ae4b",
            "mime": "image/png",
            "name": "large_headgarantia_desk.png",
            "path": null,
            "size": 97.39,
            "width": 1000,
            "height": 119
          },
          "small": {
            "ext": ".png",
            "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1713449190642-small_headgarantia_desk.png.png",
            "hash": "small_headgarantia_desk_f197a0ae4b",
            "mime": "image/png",
            "name": "small_headgarantia_desk.png",
            "path": null,
            "size": 27.57,
            "width": 500,
            "height": 59
          },
          "medium": {
            "ext": ".png",
            "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1713449190642-medium_headgarantia_desk.png.png",
            "hash": "medium_headgarantia_desk_f197a0ae4b",
            "mime": "image/png",
            "name": "medium_headgarantia_desk.png",
            "path": null,
            "size": 56.76,
            "width": 750,
            "height": 89
          },
          "thumbnail": {
            "ext": ".png",
            "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1713449190553-thumbnail_headgarantia_desk.png.png",
            "hash": "thumbnail_headgarantia_desk_f197a0ae4b",
            "mime": "image/png",
            "name": "thumbnail_headgarantia_desk.png",
            "path": null,
            "size": 9.01,
            "width": 245,
            "height": 29
          }
        },
        "hash": "headgarantia_desk_f197a0ae4b",
        "ext": ".png",
        "mime": "image/png",
        "size": 142.49,
        "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1713449190431-headgarantia_desk.png.png",
        "previewUrl": null,
        "provider": "@strapi-community/strapi-provider-upload-google-cloud-storage",
        "provider_metadata": null,
        "createdAt": "2024-04-18T14:06:30.873Z",
        "updatedAt": "2024-04-18T14:06:30.873Z"
      }
    },
    {
      "id": 2544,
      "__component": "utils.rich-text",
      "text": "<p style=\"text-align:center;\">&nbsp;</p><h2 style=\"margin-left:0px;text-align:center;\">Perrelet Care Program</h2><div class=\"subtitle-4\">&nbsp;</div><div class=\"gris-5-color subtitle-4\"><strong>EXTIENDA SU GARANTÍA INTERNACIONAL HASTA LOS 5 AÑOS</strong></div><p>&nbsp;</p><div class=\"paragraph-1\">Nuestros maestros relojeros llevan más de 240 años garantizando la precisión y fiabilidad de los relojes PERRELET. Con este programa de garantía ampliada, su reloj se beneficiará de nuestro cuidado experto durante cinco años a partir de la fecha de compra.</div><div class=\"paragraph-1\">Regístrese ahora y conviértase en miembro de My PERRELET para beneficiarse de una extensión de garantía adicional de 2 años además de la garantía internacional estándar de 3 años. Como miembro del club, también recibirá consejos útiles sobre el mantenimiento de su reloj, las últimas novedades y acceso prioritario a ediciones limitadas.</div><p>&nbsp;</p><div class=\"paragraph-1\" style=\"text-align:center;\"><u>CONDICIONES</u></div><div class=\"paragraph-1\">&nbsp;</div><li class=\"paragraph-1\">Esta garantía ampliada está disponible exclusivamente para los relojes equipados con el movimiento P-331-MH, certificado COSC y Chronofiable.</li><li class=\"paragraph-1\">El reloj debe haber sido adquirido en nuestra red de distribuidores autorizados.</li><li class=\"paragraph-1\">La solicitud de extensión de garantía debe realizarse en un plazo máximo de 90 días a partir de la fecha de compra.</li><p>&nbsp;</p><div class=\"paragraph-1\">No dude en ponerse en contacto con su distribuidor PERRELET para saber si su reloj puede beneficiarse de la extensión de garantía.</div><div class=\"paragraph-1\">Consulte las <strong><u> <a class=\"font-weight-bold\" href=\"/es-ES/terminos-legales-y-condiciones\" target=\"_blank\">Condiciones legales</a></u></strong> de este servicio de extensión de garantía Perrelet.</div><p>&nbsp;</p>",
      "fullWidth": false,
      "unpublish": false,
      "key": null
    },
    {
      "fullWidth": false,
      "id": 405,
      "show": false,
      "unpublish": false,
      "__component": "utils.warranty-extension"
    },
    {
      "id": 2544,
      "__component": "utils.rich-text",
      "text": "<div class=\"raw-html-embed\"><div class=\"pt-5\"></div></div>",
      "fullWidth": false,
      "unpublish": false,
      "key": null
    },
    {
      "id": 390,
      "__component": "landing.card-image-grid",
      "textButton": null,
      "linkButton": null,
      "fullWidth": true,
      "spaced": true,
      "params": null,
      "buttonType": null,
      "unpublish": false,
      "key": null,
      "item": [
        {
          "id": 4264,
          "title": null,
          "textButton": null,
          "url": null,
          "reverse": false,
          "text": "<div class=\"px-2 px-md-5\"> <h2 style=\"margin-left:0px;\">¿Qué relojes pueden extender su garantía?</h2><div class=\"paragraph-1\" style=\"margin-left:0px;\">&nbsp;</div><div class=\"paragraph-1\" style=\"margin-left:0px;\"><span style=\"background-color:rgb(255,255,255);color:rgb(55,52,48);font-family:&quot;Work Sans&quot;, Helvetica, Arial, sans-serif;\">Todos los relojes PERRELET equipados con el calibre de manufactura P-331-MH, con certificación COSC y comprados a través de nuestra red autorizada, podrán beneficiarse de esta extensión de 2 años para alcanzar un total de 5 años de garantía internacional.</span><br><span style=\"background-color:rgb(255,255,255);color:rgb(55,52,48);font-family:&quot;Work Sans&quot;, Helvetica, Arial, sans-serif;\">&nbsp;</span><br><span style=\"background-color:rgb(255,255,255);color:rgb(55,52,48);font-family:&quot;Work Sans&quot;, Helvetica, Arial, sans-serif;\">La solicitud de extensión de garantía deberá realizarse en un plazo máximo de 90 días a partir de la fecha de compra del reloj.</span><br><span style=\"background-color:rgb(255,255,255);color:rgb(55,52,48);font-family:&quot;Work Sans&quot;, Helvetica, Arial, sans-serif;\">&nbsp;</span><br><span style=\"background-color:rgb(255,255,255);color:rgb(55,52,48);font-family:&quot;Work Sans&quot;, Helvetica, Arial, sans-serif;\">Para obtener más detalles, consulte los&nbsp;Términos y Condiciones&nbsp;de la extensión de la Garantía Internacional Limitada.</span></div></div>",
          "effect": false,
          "illuminate": false,
          "gridMedia": "col-lg-6",
          "gridMediaMobile": "col-12",
          "titleImage": null,
          "titleImageColor": null,
          "controls": false,
          "params": null,
          "fullWidth": false,
          "reverseColumn": false,
          "background": null,
          "buttonType": null,
          "unpublish": false,
          "translateY": null,
          "fragment": null,
          "key": null,
          "videoLoop": true,
          "media": {
            "id": 2370,
            "name": "Que_relojes.png",
            "alternativeText": null,
            "caption": null,
            "width": 1060,
            "height": 900,
            "formats": {
              "large": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1711626144314-large_Que_relojes.png.png",
                "hash": "large_Que_relojes_3256eb1a2d",
                "mime": "image/png",
                "name": "large_Que_relojes.png",
                "path": null,
                "size": 537.63,
                "width": 1000,
                "height": 849
              },
              "small": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1711626144314-small_Que_relojes.png.png",
                "hash": "small_Que_relojes_3256eb1a2d",
                "mime": "image/png",
                "name": "small_Que_relojes.png",
                "path": null,
                "size": 154.55,
                "width": 500,
                "height": 425
              },
              "medium": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1711626144314-medium_Que_relojes.png.png",
                "hash": "medium_Que_relojes_3256eb1a2d",
                "mime": "image/png",
                "name": "medium_Que_relojes.png",
                "path": null,
                "size": 323.36,
                "width": 750,
                "height": 637
              },
              "thumbnail": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1711626144218-thumbnail_Que_relojes.png.png",
                "hash": "thumbnail_Que_relojes_3256eb1a2d",
                "mime": "image/png",
                "name": "thumbnail_Que_relojes.png",
                "path": null,
                "size": 26.91,
                "width": 184,
                "height": 156
              }
            },
            "hash": "Que_relojes_3256eb1a2d",
            "ext": ".png",
            "mime": "image/png",
            "size": 131.95,
            "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1711626144173-Que_relojes.png.png",
            "previewUrl": null,
            "provider": "@strapi-community/strapi-provider-upload-google-cloud-storage",
            "provider_metadata": null,
            "createdAt": "2024-03-28T11:42:24.632Z",
            "updatedAt": "2024-03-28T11:42:24.632Z"
          },
          "mediaMobile": null
        },
        {
          "id": 4265,
          "title": null,
          "textButton": null,
          "url": null,
          "reverse": true,
          "text": "<div class=\"px-2 px-md-5\"><h2 style=\"margin-left:0px;\">¿Cómo registrar su reloj?</h2><div class=\"paragraph-1\" style=\"margin-left:0px;\">&nbsp;</div><div class=\"paragraph-1\" style=\"margin-left:0px;\"><span style=\"background-color:rgb(255,255,255);color:rgb(55,52,48);font-family:&quot;Work Sans&quot;, Helvetica, Arial, sans-serif;\">En tres simples pasos puede registrar su reloj para solicitar su extensión de garantía:</span></div><ol style=\"list-style-type:decimal;\"><li class=\"paragraph-1\">Tenga a mano su tarjeta de garantía Perrelet.</li><li class=\"paragraph-1\">Rellene el formulario con los detalles de su reloj Perrelet y su información personal.</li><li class=\"paragraph-1\">Finalice el registro de su reloj.</li></ol></div>",
          "effect": false,
          "illuminate": false,
          "gridMedia": "col-lg-6",
          "gridMediaMobile": "col-12",
          "titleImage": null,
          "titleImageColor": null,
          "controls": false,
          "params": null,
          "fullWidth": false,
          "reverseColumn": false,
          "background": null,
          "buttonType": null,
          "unpublish": false,
          "translateY": null,
          "fragment": null,
          "key": null,
          "videoLoop": true,
          "media": {
            "id": 2371,
            "name": "como_registrar.png",
            "alternativeText": null,
            "caption": null,
            "width": 1060,
            "height": 900,
            "formats": {
              "large": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1711626144788-large_como_registrar.png.png",
                "hash": "large_como_registrar_e283c5ef28",
                "mime": "image/png",
                "name": "large_como_registrar.png",
                "path": null,
                "size": 960.25,
                "width": 1000,
                "height": 849
              },
              "small": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1711626144789-small_como_registrar.png.png",
                "hash": "small_como_registrar_e283c5ef28",
                "mime": "image/png",
                "name": "small_como_registrar.png",
                "path": null,
                "size": 265.44,
                "width": 500,
                "height": 425
              },
              "medium": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1711626144789-medium_como_registrar.png.png",
                "hash": "medium_como_registrar_e283c5ef28",
                "mime": "image/png",
                "name": "medium_como_registrar.png",
                "path": null,
                "size": 564.7,
                "width": 750,
                "height": 637
              },
              "thumbnail": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1711626144478-thumbnail_como_registrar.png.png",
                "hash": "thumbnail_como_registrar_e283c5ef28",
                "mime": "image/png",
                "name": "thumbnail_como_registrar.png",
                "path": null,
                "size": 47.59,
                "width": 184,
                "height": 156
              }
            },
            "hash": "como_registrar_e283c5ef28",
            "ext": ".png",
            "mime": "image/png",
            "size": 231.88,
            "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1711626144403-como_registrar.png.png",
            "previewUrl": null,
            "provider": "@strapi-community/strapi-provider-upload-google-cloud-storage",
            "provider_metadata": null,
            "createdAt": "2024-03-28T11:42:25.603Z",
            "updatedAt": "2024-03-28T11:42:25.603Z"
          },
          "mediaMobile": null
        },
        {
          "id": 4266,
          "title": null,
          "textButton": null,
          "url": null,
          "reverse": false,
          "text": "<div class=\"px-2 px-md-5\"><h2 style=\"margin-left:0px;\">A su servicio</h2><div class=\"paragraph-1\" style=\"margin-left:0px;\">&nbsp;</div><div class=\"paragraph-1\" style=\"margin-left:0px;\"><span style=\"background-color:rgb(255,255,255);color:rgb(55,52,48);font-family:&quot;Work Sans&quot;, Helvetica, Arial, sans-serif;\">Nuestro servicio Concierge está disponible para solucionar cualquier duda que pueda surgir durante el proceso de registro de su reloj.</span></div></div>",
          "effect": false,
          "illuminate": false,
          "gridMedia": "col-lg-6",
          "gridMediaMobile": "col-12",
          "titleImage": null,
          "titleImageColor": null,
          "controls": false,
          "params": null,
          "fullWidth": false,
          "reverseColumn": false,
          "background": null,
          "buttonType": null,
          "unpublish": false,
          "translateY": null,
          "fragment": null,
          "key": null,
          "videoLoop": true,
          "media": {
            "id": 2372,
            "name": "asu servicio.png",
            "alternativeText": null,
            "caption": null,
            "width": 1060,
            "height": 900,
            "formats": {
              "large": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1711626145453-large_asu-servicio.png.png",
                "hash": "large_asu_servicio_e70b631425",
                "mime": "image/png",
                "name": "large_asu servicio.png",
                "path": null,
                "size": 2093.25,
                "width": 1000,
                "height": 849
              },
              "small": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1711626145453-small_asu-servicio.png.png",
                "hash": "small_asu_servicio_e70b631425",
                "mime": "image/png",
                "name": "small_asu servicio.png",
                "path": null,
                "size": 526.01,
                "width": 500,
                "height": 425
              },
              "medium": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1711626145453-medium_asu-servicio.png.png",
                "hash": "medium_asu_servicio_e70b631425",
                "mime": "image/png",
                "name": "medium_asu servicio.png",
                "path": null,
                "size": 1193.88,
                "width": 750,
                "height": 637
              },
              "thumbnail": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1711626144851-thumbnail_asu-servicio.png.png",
                "hash": "thumbnail_asu_servicio_e70b631425",
                "mime": "image/png",
                "name": "thumbnail_asu servicio.png",
                "path": null,
                "size": 73.72,
                "width": 184,
                "height": 156
              }
            },
            "hash": "asu_servicio_e70b631425",
            "ext": ".png",
            "mime": "image/png",
            "size": 486.86,
            "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1711626144782-asu-servicio.png.png",
            "previewUrl": null,
            "provider": "@strapi-community/strapi-provider-upload-google-cloud-storage",
            "provider_metadata": null,
            "createdAt": "2024-03-28T11:42:26.039Z",
            "updatedAt": "2024-03-28T11:42:26.039Z"
          },
          "mediaMobile": null
        }
      ],
      "grid": {
        "id": 742,
        "gridMobile": "col-12",
        "grid": "col-lg-12"
      }
    },
    {
      "id": 2544,
      "__component": "utils.rich-text",
      "text": "<p style=\"text-align:center;\">&nbsp;</p><h1 style=\"text-align:center;\">Servicios</h1><div class=\"subtitle-4\" style=\"text-align:center;\">&nbsp;</div><div class=\"paragraph-1\">En Perrelet, el compromiso con nuestros clientes es una piedra angular de nuestra cultura corporativa. Más allá de ofrecer los mejores productos, nos encargamos del mantenimiento, las reparaciones y la restauración de todas las piezas Perrelet. Con una meticulosa atención al detalle y una experiencia sin igual, garantizamos el cuidado impecable de su reloj Perrelet, preservando su elegancia atemporal y su rendimiento.</div>",
      "fullWidth": false,
      "unpublish": false,
      "key": null
    },
    {
      "id": 159,
      "__component": "landing.card-image-grid-v2",
      "fullWidth": false,
      "backgroundColor": null,
      "gutters": true,
      "key": null,
      "unpublish": null,
      "item": [
        {
          "id": 550,
          "text": "<div class=\"raw-html-embed\"><h3 class=\"mt-4 font-weight-bold paragraph-1 mb-2\">\n   LABORATORIO PERRELET\n</h3>\n<p>\nTranquilidad para toda una vida de disfrute. Los relojes Perrelet están diseñados para perdurar, y nuestros maestros relojeros se asegurarán de que su reloj se mantenga en perfectas condiciones, servicio tras servicio.\n</p></div>",
          "textPosition": "bottom",
          "title": null,
          "titleColor": null,
          "titleStyle": "header-2",
          "titlePosition": "top",
          "titleAlign": "center",
          "textButton": "DESCÚBRALO",
          "buttonType": "btn-bottom-outline-i-arrow",
          "buttonPosition": "bottom",
          "url": "/laboratorio-relojero",
          "params": null,
          "effect": "none",
          "controls": false,
          "gridMedia": "col-lg-12",
          "gridMediaMobile": "col-12",
          "translateY": null,
          "key": null,
          "unpublish": null,
          "videoLoop": null,
          "buttonAlign": "left",
          "media": {
            "id": 2392,
            "name": "laboratorio perrelet991 x 375.png",
            "alternativeText": null,
            "caption": null,
            "width": 991,
            "height": 690,
            "formats": {
              "small": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1712241870713-small_laboratorio-perrelet991-x-375.png.png",
                "hash": "small_laboratorio_perrelet991_x_375_5c75d7f869",
                "mime": "image/png",
                "name": "small_laboratorio perrelet991 x 375.png",
                "path": null,
                "size": 276.15,
                "width": 500,
                "height": 348
              },
              "medium": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1712241870713-medium_laboratorio-perrelet991-x-375.png.png",
                "hash": "medium_laboratorio_perrelet991_x_375_5c75d7f869",
                "mime": "image/png",
                "name": "medium_laboratorio perrelet991 x 375.png",
                "path": null,
                "size": 560.49,
                "width": 750,
                "height": 522
              },
              "thumbnail": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1712241870507-thumbnail_laboratorio-perrelet991-x-375.png.png",
                "hash": "thumbnail_laboratorio_perrelet991_x_375_5c75d7f869",
                "mime": "image/png",
                "name": "thumbnail_laboratorio perrelet991 x 375.png",
                "path": null,
                "size": 68.4,
                "width": 224,
                "height": 156
              }
            },
            "hash": "laboratorio_perrelet991_x_375_5c75d7f869",
            "ext": ".png",
            "mime": "image/png",
            "size": 227.4,
            "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1712241870442-laboratorio-perrelet991-x-375.png.png",
            "previewUrl": null,
            "provider": "@strapi-community/strapi-provider-upload-google-cloud-storage",
            "provider_metadata": null,
            "createdAt": "2024-04-03T15:22:14.619Z",
            "updatedAt": "2024-04-04T14:44:30.968Z"
          },
          "mediaMobile": null
        },
        {
          "id": 551,
          "text": "<div class=\"raw-html-embed\"><h3 class=\"mt-4 font-weight-bold paragraph-1 mb-2\">\n   GARANTÍA DE FABRICACIÓN\n</h3>\n<p>\nDisfrute de la tranquilidad de una garantía internacional contra defectos de fabricación en sus relojes Perrelet. Válida desde la fecha de compra y con atención personalizada en centros oficiales.\n</p></div>",
          "textPosition": "bottom",
          "title": null,
          "titleColor": null,
          "titleStyle": "header-2",
          "titlePosition": "top",
          "titleAlign": "center",
          "textButton": "MÁS INFORMACIÓN",
          "buttonType": "btn-bottom-outline-i-arrow",
          "buttonPosition": "bottom",
          "url": "/garantia-fabricacion",
          "params": null,
          "effect": "none",
          "controls": false,
          "gridMedia": "col-lg-12",
          "gridMediaMobile": "col-12",
          "translateY": null,
          "key": null,
          "unpublish": null,
          "videoLoop": null,
          "buttonAlign": "left",
          "media": {
            "id": 2393,
            "name": "garantia de fabricacion991 x 375.png",
            "alternativeText": null,
            "caption": null,
            "width": 991,
            "height": 690,
            "formats": {
              "small": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1712157734526-small_garantia-de-fabricacion991-x-375.png.png",
                "hash": "small_garantia_de_fabricacion991_x_375_4286d2647f",
                "mime": "image/png",
                "name": "small_garantia de fabricacion991 x 375.png",
                "path": null,
                "size": 209.96,
                "width": 500,
                "height": 348
              },
              "medium": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1712157734526-medium_garantia-de-fabricacion991-x-375.png.png",
                "hash": "medium_garantia_de_fabricacion991_x_375_4286d2647f",
                "mime": "image/png",
                "name": "medium_garantia de fabricacion991 x 375.png",
                "path": null,
                "size": 440.3,
                "width": 750,
                "height": 522
              },
              "thumbnail": {
                "ext": ".png",
                "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1712157734301-thumbnail_garantia-de-fabricacion991-x-375.png.png",
                "hash": "thumbnail_garantia_de_fabricacion991_x_375_4286d2647f",
                "mime": "image/png",
                "name": "thumbnail_garantia de fabricacion991 x 375.png",
                "path": null,
                "size": 53.29,
                "width": 224,
                "height": 156
              }
            },
            "hash": "garantia_de_fabricacion991_x_375_4286d2647f",
            "ext": ".png",
            "mime": "image/png",
            "size": 160.65,
            "url": "https://storage.googleapis.com/bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3/festina-swiss-cms/1712157734202-garantia-de-fabricacion991-x-375.png.png",
            "previewUrl": null,
            "provider": "@strapi-community/strapi-provider-upload-google-cloud-storage",
            "provider_metadata": null,
            "createdAt": "2024-04-03T15:22:14.741Z",
            "updatedAt": "2024-04-03T15:22:14.741Z"
          },
          "mediaMobile": null
        }
      ],
      "grid": {
        "id": 525,
        "gridMobile": "col-12",
        "grid": "col-lg-6"
      }
    },
    {
      "fullWidth": true,
      "id": 403,
      "show": false,
      "unpublish": false,
      "__component": "shared.subscribe"
    }
  ]
}
