import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ProductItemListBaseComponent } from '@fgp/product/feature-product-item-list/base';

@Component({
    selector: 'fgp-product-item-list-perrelet',
    templateUrl: './product-item-list-perrelet.component.html',
    styleUrls: ['./product-item-list-perrelet.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ProductItemListPerreletComponent extends ProductItemListBaseComponent {}
