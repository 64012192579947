{
  "accesorio": {
    "h1": "Accesoires",
    "meta": "Finden Sie das perfekte Geschenk für jeden Anlass oder gönnen Sie sich etwas und zeigen Sie die besten Accessoires. Besuchen Sie jetzt unseren Katalog. {{numPagina}}",
    "title": "Accesoires | Perrelet"
  },
  "accesorio.default": {
    "h1": "Accesoires {{tipoAccesorio}} {{subtipoAccesorio}} {{tipoBoligrafo}} ",
    "meta": "Finden Sie das perfekte Geschenk für jeden Anlass oder gönnen Sie sich etwas und zeigen Sie die besten Accessoires. Besuchen Sie jetzt unseren Katalog. {{numPagina}}",
    "robots": "false",
    "title": "Accesoires {{tipoAccesorio}} {{subtipoAccesorio}} {{tipoBoligrafo}} | Perrelet"
  },
  "accesorio.LEATHER_ITEM.CARD_HOLDER": {
    "h1": "Kartenhalter aus Leder",
    "meta": "Die beste Art, Ihre Karten zu ordnen, sind unsere Kartenetuis aus Leder, die aus dem widerstandsfähigsten und elegantesten Material hergestellt sind.",
    "title": "Kartenhalter aus Leder : Perrelet"
  },
  "accesorio.LEATHER_ITEM.WALLET": {
    "h1": "Leder-Geldbörsen",
    "meta": "Unsere Lederbrieftaschen eignen sich perfekt zum Verschenken. Sie sind aus strapazierfähigem Material gefertigt und in verschiedenen Farben erhältlich, so dass sie überallhin mitgenommen werden können.",
    "title": "Portemonnaies aus Leder : Perrelet"
  },
  "accesorio.subtipoAccesorio.default": {
    "h1": "{{subtipoAccesorio}}",
    "meta": "Entdecken Sie unsere Auswahl an {{subtipoAccesorio}} in unserem offiziellen Online Shop.",
    "title": "{{subtipoAccesorio}} | Perrelet"
  },
  "accesorio.subtipoAccesorio.SOLAR": {
    "h1": "Sonnenbrille",
    "meta": "Entdecken Sie unsere Auswahl an Sonnenbrillen in unserem offiziellen Online Shop.",
    "title": "Sonnenbrille | Perrelet"
  },
  "accesorio.tipoAccesorio.default": {
    "h1": "Accesoires {{tipoAccesorio}}",
    "meta": "In unserer Auswahl an Perrelet-Accessoires finden Sie das ideale Geschenk für besondere Anlässe oder um sich selbst zu verwöhnen.",
    "title": "Accesoires {{tipoAccesorio}} | Perrelet"
  },
  "accesorio.tipoAccesorio.KEYCHAIN": {
    "h1": "Schlüsselanhänger",
    "meta": "Die Schlüsselanhänger von Perrelet sind ein unverzichtbares Accessoire, das Ihren Stil ergänzt. Sie sind schlank und modern und verleihen der Trägerin einen unverwechselbaren Touch.",
    "title": "Schlüsselanhänger : Perrelet"
  },
  "accesorio.WRITING.BALLPOINT_PEN": {
    "h1": "Kugelschreiber",
    "meta": "Unsere Kugelschreiber sind die einfachsten und bequemsten Stifte, mit denen man schreiben kann. Sie zeichnen sich außerdem durch ein dezentes und exklusives Design aus.",
    "title": "Kugelschreiber : Perrelet"
  },
  "accesorio.WRITING.FOUNTAIN_PEN": {
    "h1": "Füllfederhalter",
    "meta": "Verleihen Sie Ihrer Kalligraphie mit unseren Füllfederhaltern eine persönliche Note. In unserem Katalog finden Sie sie in verschiedenen Farben und Ausführungen.",
    "title": "Perrelet Füllfederhalter"
  },
  "accesorio.WRITING.REPLACEMENT": {
    "h1": "Minen für Kugelschreiber",
    "meta": "In unserem Online-Shop können Sie auch Kugelschreiberminen kaufen, und zwar im Zweierpack, damit Sie sie immer zur Hand haben.",
    "title": "Minen für Kugelschreiber | Perrelet"
  },
  "accesorio.WRITING.ROLLERBALL_PEN": {
    "h1": "Rollerball-Stifte",
    "meta": "Entdecken Sie unsere Tintenroller, luxuriöse Schreibgeräte mit einem eleganten und diskreten Design, perfekt als Geschenk für besondere Momente.",
    "title": "Rollerball-Stifte : Perrelet"
  },
  "reloj": {
    "h1": "Uhren",
    "meta": "Auf der Suche nach einer einzigartigen und eleganten Uhr? Entdecken Sie unsere Uhren der besten Marken und finden Sie das perfekte Modell für Sie. {{numPagina}}",
    "title": "Uhren {{numPagina}} | Perrelet"
  },
  "reloj.C": {
    "h1": "Herrenuhren",
    "meta": "Unsere Herrenuhren sind perfekt für jeden Anlass. Einzigartig gestaltet, sind sie ideal, um einen eleganten und einzigartigen Stil zu ergänzen. {{numPagina}}",
    "title": "Herrenuhren {{numPagina}} | Perrelet"
  },
  "reloj.colorEsfera.default": {
    "h1": "Uhren mit Zifferblatt {{ colorEsfera }}",
    "meta": "Sie suchen eine einzigartige und elegante Uhr? Entdecken Sie unsere Uhren mit {{ colorEsfera }} Ziffernblättern von den besten Marken und finden Sie das perfekte Modell für sich.",
    "title": "Uhren mit Zifferblättern {{ colorEsfera }} | Perrelet"
  },
  "reloj.default": {
    "h1": "Uhren {{marca}} {{funcion}} {{estilos}} {{colorCorrea}} {{colorEsfera}} {{materialCaja}} {{materialCorrea}}",
    "meta": "Auf der Suche nach einer einzigartigen und eleganten Uhr? Entdecken Sie unsere Uhren der besten Marken und finden Sie das perfekte Modell für Sie. {{numPagina}}",
    "robots": "false",
    "title": "Uhren {{gama}} {{marca}} {{funcion}} {{estilos}} {{colorCorrea}} {{colorEsfera}} {{materialCaja}} {{materialCorrea}} {{numPagina}} | Perrelet"
  },
  "reloj.funcion.default": {
    "h1": "Uhren {{funcion}}",
    "meta": "Auf der Suche nach einer Uhr {{funcion}}? Finden Sie die besten Modelle der besten Marken und wählen Sie die perfekte Uhr für Sie. Besuchen Sie jetzt unseren Katalog {{numPagina}}",
    "title": "Uhren {{funcion}} {{numPagina}} | Perrelet"
  },
  "reloj.gama.colorCorrea.default": {
    "h1": "Uhren {{colorCorrea}} für {{gama}}",
    "meta": "Wir haben eine große Auswahl an Farben und Materialien, damit Sie die Uhren {{colorCorrea}} für {{gama}} auswählen können, die am besten zu Ihrem Stil passen. {{numPagina}}",
    "title": "Uhren {{colorCorrea}} für {{gama}} {{numPagina}} | Perrelet"
  },
  "reloj.gama.colorEsfera.default": {
    "h1": "Uhren mit Kugel {{colorEsfera}} für {{genero}}",
    "meta": "Distinguiert, einzigartig und anspruchsvoll. Das sind unsere Uhren mit Zifferblatt {{colorEsfera}} für {{gama}} , die Ihrem Handgelenk einen Hauch von Eleganz verleihen.",
    "title": "Uhren mit Kugel {{colorEsfera}} für {{genero}} {{numPagina}} | Perrelet"
  },
  "reloj.gama.funcion.default": {
    "h1": "Uhren {{funcion}} für {{gama}}",
    "meta": "Finden Sie die schönsten und raffiniertesten {{funcion}} Uhren für {{gama}} von Perrelet auf dem Markt und tragen Sie sie zu jedem Anlass. {{numPagina}}",
    "title": "Uhren {{funcion}}  für {{gama}} {{numPagina}} | Perrelet"
  },
  "reloj.gama.materialCaja.default": {
    "h1": "{{gama}} Uhren mit {{materialCaja}}Gehäuse",
    "meta": "Die Uhren mit {{materialCaja}} bis {{gama}} Gehäuse zeichnen sich durch ihr sorgfältiges Design aus. Besuchen Sie unseren Shop und wählen Sie das für Sie passende Produkt aus.",
    "title": "{{gama}} Uhren mit {{materialCaja}} {{numPagina}} Gehäuse | Perrelet"
  },
  "reloj.gama.materialCorrea.default": {
    "h1": "Uhren von {{materialCorrea}} bis {{gama}}",
    "meta": "Die {{materialCorrea}} bis {{gama}} Uhren von Perrelet sind die perfekte Wahl, um einen exklusiven und praktischen Zeitmesser am Handgelenk zu tragen.",
    "title": "Uhren von {{materialCorrea}} bis {{gama}} | Perrelet"
  },
  "reloj.gama.tipoCierre.default": {
    "h1": "Überwacht {{tipoCierre}} für {{gama}}",
    "meta": "Auf unserer Website können Sie die große Auswahl an Uhren {{tipoCierre}} für {{gama}} sehen, die wir anbieten. Wir haben exklusive Stücke nur für Sie.",
    "title": "Uhren {{tipoCierre}} für {{gama}} | Perrelet"
  },
  "reloj.gama.tipoCorrea.ARMYS": {
    "h1": "Überwacht {{tipoCorrea}} für {{gama}}",
    "meta": "Wussten Sie, dass wir bei Perrelet Uhren {{tipoCorrea}} für {{gama}} für alle möglichen Anlässe anbieten? Finden Sie die Uhr, die am besten zu Ihrer Persönlichkeit passt!",
    "title": "Uhren {{tipoCorrea}} für {{gama}} | Perrelet"
  },
  "reloj.gama.tipoCorrea.CORREA": {
    "h1": "Überwacht {{tipoCorrea}} für {{gama}}",
    "meta": "Wussten Sie, dass wir bei Perrelet Uhren {{tipoCorrea}} für {{gama}} für alle möglichen Anlässe anbieten? Finden Sie die Uhr, die am besten zu Ihrer Persönlichkeit passt!",
    "title": "Uhren {{tipoCorrea}} für {{gama}} | Perrelet"
  },
  "reloj.gama.tipoEstanqueidad.default": {
    "h1": "Uhren von {{tipoEstanqueidad}} bis {{gama}}",
    "meta": "Konsultieren Sie unseren umfangreichen Katalog mit Uhren von {{tipoEstanqueidad}} bis {{gama}} , die mit den besten Materialien und Techniken auf dem Markt hergestellt werden.",
    "title": "Uhren von {{tipoEstanqueidad}} bis {{gama}} | Perrelet"
  },
  "reloj.gama.tiposMovimiento.01": {
    "h1": "Digitale Uhren für {{gama}}",
    "meta": "Suchen Sie eine digitale Uhr für {{gama}}? BeiPerrelet haben wir eine große Auswahl an verfügbaren Uhren. Kommen Sie herein und wählen Sie diejenige aus, die am besten zu Ihnen passt.",
    "title": "Digitale Uhren für {{gama}} | Perrelet"
  },
  "reloj.gama.tiposMovimiento.02": {
    "h1": "Uhren für {{gama}} mit Quarz-digital Bewegung.",
    "meta": "Suchen Sie nach {{gama}} Uhren mit Quarz-digital? Werfen Sie einen Blick auf unsere große Auswahl an Uhren für {{gama}} und wählen Sie das perfekte Modell für sich aus.",
    "robots": "false",
    "title": "Uhren für {{gama}} mit Quarz-digital | Perrelet"
  },
  "reloj.gama.tiposMovimiento.04": {
    "h1": "Automatische Uhren für {{gama}}",
    "meta": "Suchst du eine Automatikuhr für {{gama}}? BeiPerrelet haben wir eine große Auswahl an verfügbaren Uhren. Komm rein und wähle diejenige aus, die am besten zu dir passt.",
    "title": "Automatische Uhren für {{gama}} | Perrelet"
  },
  "reloj.gama.tiposMovimiento.05": {
    "h1": "Hybriduhren für{{gama}}",
    "meta": "Suchst du eine Hybriduhr für {{gama}}? BeiPerrelet haben wir eine große Auswahl an verfügbaren Uhren. Komm rein und wähle diejenige aus, die am besten zu dir passt.",
    "title": "Hybriduhren für{{gama}} | Perrelet"
  },
  "reloj.gama.tiposMovimiento.06": {
    "h1": "Smartwatches für {{gama}}",
    "meta": "Suchst du eine Smartwatch für {{gama}}? BeiPerrelet haben wir eine große Auswahl an verfügbaren Smartwatches. Komm rein und wähle diejenige aus, die am besten zu dir passt.",
    "title": "Smartwatches für {{gama}} - Smartwatches | Perrelet"
  },
  "reloj.gama.tiposMovimiento.07": {
    "h1": "Quarzuhren für {{gama}}",
    "meta": "Suchst du eine Quarzuhr für {{gama}}? BeiPerrelet haben wir eine große Auswahl an verfügbaren Uhren. Komm rein und wähle diejenige aus, die am besten zu dir passt.",
    "title": "Quarzuhren für {{gama}} | Perrelet"
  },
  "reloj.IN": {
    "h1": "Kinderuhren",
    "meta": "Sie suchen die besten Uhren für Kinder? In unserem Online-Shop finden Sie lustige und abwechslungsreiche Uhren für die jüngsten Mitglieder der Familie. {{numPagina}}",
    "title": "Kinderuhren {{numPagina}} | Perrelet"
  },
  "reloj.IN.funcion": {
    "h1": "Uhren {{funcion}} für Kinder",
    "meta": "Möchten Sie eine {{funcion}} Uhr für ein Kind? Hier finden Sie eine Vielzahl von Kinderuhren {{funcion}} der besten Marken. Besuchen Sie unseren Katalog! {{numPagina}}",
    "title": "Uhren {{funcion}} für Kinder {{numPagina}} | Perrelet"
  },
  "reloj.materialCaja.default": {
    "h1": "Uhren mit {{materialCaja}}Fall",
    "meta": "Magst du {{materialCaja}}Uhren? Entdecken Sie unseren umfangreichen Katalog und finden Sie die Uhr, die Ihr Handgelenk zum Leuchten bringt. {{numPagina}}",
    "title": "Uhren mit {{materialCaja}} {{numPagina}} Fall | Perrelet"
  },
  "reloj.materialCorrea.default": {
    "h1": "Uhren {{materialCorrea}}",
    "meta": "Magst du Uhren mit einem {{materialCorrea}}Armband? Entdecken Sie unseren umfangreichen Katalog und finden Sie die Uhr, die Ihr Handgelenk zum Leuchten bringt. {{numPagina}}",
    "title": "Uhren {{materialCorrea}} {{numPagina}} | Perrelet"
  },
  "reloj.S": {
    "h1": "Damenuhren",
    "meta": "Check our catalog and discover all our Perrelet ladies watches. Get Watches for woman that best suit your style. {{numPagina}}",
    "title": "Damenuhren {{numPagina}} | Perrelet"
  },
  "reloj.tipoCierre.default": {
    "h1": "Uhren {{tipoCierre}}",
    "meta": "Auf der Suche nach einer Uhr {{tipoCierre}}? Finden Sie die besten Modelle der besten Marken und wählen Sie die perfekte Uhr für Sie. Besuchen Sie jetzt unseren Katalog {{numPagina}}",
    "title": "Uhren {{tipoCierre}} {{numPagina}} | Perrelet"
  },
  "reloj.tipoCorrea.default": {
    "h1": "Uhren {{tipoCorrea}}",
    "meta": "Auf der Suche nach einer Uhr {{tipoCorrea}}? Finden Sie die besten Modelle der besten Marken und wählen Sie die perfekte Uhr für Sie. Besuchen Sie jetzt unseren Katalog {{numPagina}}",
    "title": "Uhren {{tipoCorrea}} {{numPagina}} | Perrelet"
  },
  "reloj.tiposMovimiento.01": {
    "h1": "Digitale Uhren",
    "meta": "Suchen Sie eine digitale Uhr? BeiPerrelet haben wir eine große Auswahl an digitalen Uhren. Kommen Sie herein und wählen Sie diejenige aus, die am besten zu Ihnen passt.",
    "robots": "false",
    "title": "Digitale Uhren | Perrelet"
  },
  "reloj.tiposMovimiento.02": {
    "h1": "Uhren mit Bewegung Quarz-digital",
    "meta": "Suchen Sie Uhren mit Quarz-digital? Werfen Sie einen Blick auf unsere große Auswahl an Uhren und wählen Sie das perfekte Modell für sich aus.",
    "robots": "false",
    "title": "Uhren mit Bewegung Quarz-digital | Perrelet"
  },
  "reloj.tiposMovimiento.03": {
    "h1": "Uhren mit Bewegung Manuell",
    "meta": "Suchen Sie Uhren mit Manuell? Werfen Sie einen Blick auf unsere große Auswahl an Uhren und wählen Sie das perfekte Modell für sich aus.",
    "robots": "false",
    "title": "Uhren mit Bewegung Manuell | Perrelet"
  },
  "reloj.tiposMovimiento.04": {
    "h1": "Automatische Uhren",
    "meta": "Suchst du eine Automatikuhr? BeiPerrelet haben wir eine große Auswahl an Automatikuhren. Komm rein und wähle diejenige aus, die am besten zu dir passt.",
    "title": "Automatische Uhren | Perrelet"
  },
  "reloj.tiposMovimiento.05": {
    "h1": "Hybriduhren",
    "meta": "Suchen Sie eine Automatikuhr? BeiPerrelet haben wir eine große Auswahl an Automatikuhren. Kommen Sie herein und wählen Sie diejenige aus, die am besten zu Ihnen passt.",
    "robots": "false",
    "title": "Hybriduhren | Perrelet"
  },
  "reloj.tiposMovimiento.06": {
    "h1": "Smartwatches",
    "meta": "Suchst du eine Smartwatch? Bei Perrelet haben wir eine große Auswahl an verfügbaren Smartwatches. Komm rein und wähle diejenige aus, die am besten zu deinem Stil passt.",
    "title": "Smartwatches - Smartwatches | {Geschäft}"
  },
  "reloj.tiposMovimiento.07": {
    "h1": "Quarzuhren",
    "meta": "Suchst du eine Quarzuhr? BeiPerrelet haben wir eine große Auswahl an Quarzuhren. Komm rein und wähle diejenige aus, die am besten zu dir passt.",
    "robots": "false",
    "title": "Quarzuhren | Perrelet"
  }
}
