import { Location } from '@angular/common';
import { inject, NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { RedirectService } from '@fgp/utils/redirect';
import {
  CacheMechanism,
  LocalizeParser,
  LocalizeRouterModule,
  LocalizeRouterSettings,
  ManualParserLoader
} from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
  bindToComponentInputs: true
};

export const routes: Routes = [
  {
    path: 'caja',
    loadComponent: () => import('./layout/checkout-layout/checkout-layout.component').then(m => m.CheckoutLayoutComponent),
    data: { breadcrumb: 'breadcrumb.cesta', skipRouteLocalization: true },
    loadChildren: () => import('./pages/caja/caja.module').then(m => m.CajaModule)
  },
  {
    path: '',
    loadComponent: () => import('./layout/main-layout/main-layout.component').then(m => m.MainLayoutComponent),
    loadChildren: () => import('./main/main.module').then(m => m.MainModule)
  },
  {
    path: '**',
    component: AppComponent,
    data: { skipRouteLocalization: true },
    canActivate: [
      () => {
        inject(RedirectService).redirect404();
        return false;
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, routerOptions),
    LocalizeRouterModule.forRoot(routes, {
      parser: {
        provide: LocalizeParser,
        useFactory: (translate, location, settings) =>
          new ManualParserLoader(translate, location, settings, environment.idiomasAplicacion, 'ROUTES.', '!'),
        deps: [TranslateService, Location, LocalizeRouterSettings]
      },
      cacheMechanism: CacheMechanism.LocalStorage,
      cookieFormat: '{{value}};{{expires}};path=/'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
