import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { BasketSelectors } from '@fgp/basket/data-access';
import { fromFooter } from '@fgp/footer/data-access';
import { Store } from '@ngrx/store';
import { FooterBarPerreletMenuItemsComponent } from './components/footer-bar-perrelet-menu-items/footer-bar-perrelet-menu-items.component';
import { FooterBarPerreletPaymentIconsComponent } from './components/footer-bar-perrelet-payment-icons/footer-bar-perrelet-payment-icons.component';
import { PAYMENT_METHOD_ICONS } from './models/footer-bar-perrelet.data';

@Component({
    selector: 'fgp-footer-bar-perrelet',
    templateUrl: './footer-bar-perrelet.component.html',
    styleUrls: ['./footer-bar-perrelet.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, FooterBarPerreletMenuItemsComponent, FooterBarPerreletPaymentIconsComponent]
})
export class FooterBarPerreletComponent {
  #store = inject(Store);
  physicalShop$ = this.#store.selectSignal(BasketSelectors.selectPhysicalShop);
  footerBar$ = this.#store.selectSignal(fromFooter.selectFooterBar);
  icons = PAYMENT_METHOD_ICONS;
}
