<ul class="footer-menu text-center m-0 p-0 d-flex flex-column flex-xl-row align-items-md-center">
  @for (menuItem of menuItems; track menuItem.id) {
    <li class="footer-menu__item px-md-0 px-xl-2">
      @if (menuItem?.urlPath && menuItem?.urlPath?.charAt(0) === '/') {
        <a class="white-color font-weight-bold" [routerLink]="[menuItem.urlPath] | localize" [rel]="menuItem.atributes ?? ''">
          {{ menuItem.text | uppercase }}
        </a>
      } @else {
        <a class="white-color font-weight-bold" target="_blank" [href]="menuItem?.urlPath" [rel]="menuItem.atributes ?? ''">
          {{ menuItem.text | uppercase }}
        </a>
      }
    </li>
  }
</ul>
