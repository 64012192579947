{
	"acabado.AC-OR": "ACERO-ORO",
	"acabado.B": "BRILLO",
	"acabado.BP01": "BP01",
	"acabado.BP01C": "CÍRCULO COLOR",
	"acabado.BSL": "BSL",
	"acabado.CAUCH": "CAUCHO",
	"acabado.CORB": "CORBATA",
	"acabado.CRISA": "CRISÁLIDA",
	"acabado.ESTAM": "Estampado",
	"acabado.F+R": "PLANA+REDONDA",
	"acabado.F+S": "PLANA+RECTA",
	"acabado.F+S C": "PLANA+REC.COLOR",
	"acabado.F+T": "PLANA+CORBATA",
	"acabado.FAC06": "FAC06",
	"acabado.FR11": "FRAP 11",
	"acabado.FRA15": "FRAP15",
	"acabado.GRAB": "Grabado",
	"acabado.LAPI": "LAPIDADO",
	"acabado.LASER": "Láser",
	"acabado.M": "MATE",
	"acabado.M+S": "MONTAÑA+RECTA",
	"acabado.M+S C": "MONT.RECT.COLOR",
	"acabado.M+T": "MONTAÑA+CORBATA",
	"acabado.MAL": "MALLA",
	"acabado.MB": "MATE-BRILLO",
	"acabado.MECA": "Mecanizado",
	"acabado.MF5P": "MICROFUSIÓN 5PZ",
	"acabado.MMILA": "MALLA MILANESA",
	"acabado.MOUNT": "MONTAÑA",
	"acabado.MTCA": "MATE CALADA",
	"acabado.OYS": "OYSTER",
	"acabado.PIR3": "PIRAMIDAL 3M",
	"acabado.PMP": "PULI-MATE-PULI",
	"acabado.PPM": "PANTER PULIDO-MATE",
	"acabado.PPU": "PANTER PULIDO",
	"acabado.PRES": "PRESIDENT",
	"acabado.PULID": "PULIDO",
	"acabado.RAS": "RASCADO",
	"acabado.ROUND": "REDONDO",
	"acabado.S7M": "ESTAM 7 3MAT",
	"acabado.S7R": "STAMP 7PCS RAYA",
	"acabado.S73M": "ESTAMP 7-3MATE",
	"acabado.ST3": "ESTAMPADO 3PZS",
	"acabado.ST4": "ESTAMPADO 4PZS",
	"acabado.ST5": "ESTAMPADO 5PZS",
	"acabado.ST6": "ESTAMPADO 6PZS",
	"acabado.ST7": "ESTAMPADO 7PIEZ",
	"acabado.TPMP": "TUBO P-M-P",
	"acabado.TUB": "TUBO",
	"acabado.V620": "V620",
	"acabado.Y": "MONTAÑA PULIDA",
	"acabado.YHLO": "MONTAÑA 1/2B+M",
	"accesorios.subtipo.BALLPOINT_PEN": "bolígrafo ballpoint",
	"accesorios.subtipo.BELT": "Cinturón",
	"accesorios.subtipo.CARD_HOLDER": "tarjetero",
	"accesorios.subtipo.DOCUMENT_BAG": "Bolsa documentos",
	"accesorios.subtipo.DRESSING_CASE": "Neceser",
	"accesorios.subtipo.FOLDER": "carpeta",
	"accesorios.subtipo.FOUNTAIN_PEN": "pluma",
	"accesorios.subtipo.LAPTOP_BAG": "Bolsa portátil",
	"accesorios.subtipo.LEATHER_PEN_SET": "conjunto cuero bolígrafo",
	"accesorios.subtipo.OPTIC": "Gafas ópticas",
	"accesorios.subtipo.OPTIC_SOLAR_CLIP": "",
	"accesorios.subtipo.PEN": "bolígrafo",
	"accesorios.subtipo.REPLACEMENT": "Recambio",
	"accesorios.subtipo.REPORTER_BAG": "Bandolera",
	"accesorios.subtipo.ROLLERBALL_PEN": "bolígrafo rollerball",
	"accesorios.subtipo.SOLAR": "Gafas de sol",
	"accesorios.subtipo.WALLET": "cartera",
	"accesorios.subtipo.WATCH_CASE": "Estuche relojes",
	"accesorios.subtipo.WRITING_CASE": "Estuche escritura",
	"accesorios.subtipo.WRITING_SET": "conjunto escritura",
	"accesorios.tipo.CLEANER": "Limpiadores",
	"accesorios.tipo.EYEWEAR": "Gafas",
	"accesorios.tipo.FRAGANCE": "perfumes",
	"accesorios.tipo.JEWELLERY_BOX": "Joyeros",
	"accesorios.tipo.KEYCHAIN": "llaveros",
	"accesorios.tipo.LEATHER_ITEM": "artículo piel",
	"accesorios.tipo.SET": "Conjuntos",
	"accesorios.tipo.SPARE_PARTS": "recambios",
	"accesorios.tipo.TOOL": "herramientas",
	"accesorios.tipo.WRITING": "Escritura",
	"accesorios.tipo_boligrafo.BALLPOINT": "Ballpoint",
	"accesorios.tipo_boligrafo.ROLLERBALL": "Rollerball",
	"agrupacion.coleccion": "colección",
	"agrupacion.seleccion": "selección",
	"anchopuente.14": "14 mm",
	"anchopuente.15": "15 mm",
	"anchopuente.16": "16 mm",
	"anchopuente.17": "17 mm",
	"anchopuente.18": "18 mm",
	"anchopuente.21": "21 mm",
	"caracteristicas.alarma": "Alarma",
	"caracteristicas.balancier_visible": "Balancier visible",
	"caracteristicas.complicacion_automatico": "Complicación Automático",
	"caracteristicas.corona_rosca": "Corona de rosca",
	"caracteristicas.dual_time": "Dual Time",
	"caracteristicas.gmt": "GMT",
	"caracteristicas.luminova": "Luminova",
	"caracteristicas.luz": "Luz",
	"caracteristicas.reserva_marcha": "Reserva de Marcha",
	"caracteristicas.skeleton": "Skeleton",
	"caracteristicas.solar": "Solar",
	"caracteristicas.superluminova": "Superluminova",
	"caracteristicas.tourbillon": "Tourbillon",
	"cierre.agrupado.CAJ": "Desplegable",
	"cierre.agrupado.CAP": "CAJETÍN PULSADOR",
	"cierre.agrupado.CCAT": "Catalán / de pala",
	"cierre.agrupado.CCJ": "CIERRE DE CAJÓN",
	"cierre.agrupado.CGAN": "Gancho",
	"cierre.agrupado.CI": "Mariposa",
	"cierre.agrupado.CIP": "De presión",
	"cierre.agrupado.CLIP": "Clip",
	"cierre.agrupado.CM": "MAGNÉTICO",
	"cierre.agrupado.COMEGA": "Omega",
	"cierre.agrupado.CPC": "CAJ PULS CORREA",
	"cierre.agrupado.CPL": "LATERAL",
	"cierre.agrupado.CPR": "Presión",
	"cierre.agrupado.CSE": "Cierre con seguro",
	"cierre.agrupado.CSP": "CIERRE SEGURO PULSADOR",
	"cierre.agrupado.HEB": "Hebilla",
	"cierre.agrupado.HKSALO": "SAFE LOCK",
	"cierre.agrupado.LI": "Presión libro",
	"cierre.agrupado.MOS": "MOSQUETÓN",
	"cierre.agrupado.OCL": "Oculto",
	"cierre.agrupado.OCP": "OCULTO PULSADOR",
	"cierre.agrupado.OCU": "Africano",
	"cierre.agrupado.ROS": "De rosca",
	"cierre.agrupado.SO": "Doble Pulsador",
	"cierre.agrupado.SOP": "Doble Pulsador",
	"cierre.CAJ": "Desplegable",
	"cierre.CAP": "CAJETÍN PULSADOR",
	"cierre.CCJ": "CIERRE DE CAJÓN (STYLE)",
	"cierre.CI": "CIERRE 1",
	"cierre.CIP": "De presión",
	"cierre.CM": "MAGNÉTICO",
	"cierre.CMS": "MAGNÉTICO CON SEGURO",
	"cierre.CPC": "CAJ.PULS.CORREA",
	"cierre.CPL": "CAJETÍN PULS.LATER.",
	"cierre.CPR": "Presión",
	"cierre.CSE": "Cierre con seguro",
	"cierre.CSP": "CIERRE SEGURO PULSADOR",
	"cierre.HEB": "Hebilla",
	"cierre.HKBB": "BUTTERFLY BUCKLE",
	"cierre.HKCB": "CLIP BUCKLE",
	"cierre.HKCLB": "CLIP LOCK BUCKLE",
	"cierre.HKJEWBUK": "JEWELLERY BUCKLE",
	"cierre.HKLB": "LEATHER BUCKLE",
	"cierre.HKLOBUK": "Mariposa",
	"cierre.HKMB": "MAGNETIC BUCKLE",
	"cierre.HKSALO": "SAFE LOCK",
	"cierre.HKSCB": "SPRING C-CLIP BUCKLE",
	"cierre.HKT-BABUK": "T-BAR BUCKLE",
	"cierre.HOR": "HEBILLA ORO",
	"cierre.LI": "Presión libro",
	"cierre.MOS": "MOSQUETÓN",
	"cierre.NEO": "NO LLEVA",
	"cierre.NOSE": "NO SE SABE",
	"cierre.OCH": "CIERRE OCULTO HEBILLA",
	"cierre.OCL": "Oculto",
	"cierre.OCP": "OCULTO PULSADOR",
	"cierre.OCS": "OCULTO SOLAPA",
	"cierre.OCU": "Africano",
	"cierre.OPC": "OCULTO PULS.CORREA",
	"cierre.OSP": "OCULTO SOLAPA PULS",
	"cierre.ROS": "CIERRE DE ROSCA",
	"cierre.SO": "Doble pulsador",
	"cierre.SOC": "SOLAPA CORREA",
	"cierre.SOH": "SOLAPA HEBILLA",
	"cierre.SOP": "Doble Pulsador",
	"cierre.SPC": "SOLAPA PULS.CORREA",
	"cierre.undefined": "",
	"colores_base.1": "CRO",
	"colores_base.2N18": "2N18 DORADO",
	"colores_base.2NBN": "CENTRO 2N18 CUERPO NEGRO",
	"colores_base.2NMA": "2N18 CON MARCAS",
	"colores_base.2NTN": "2N18 CON FILETE NEGRO",
	"colores_base.3N": "3N GOLD",
	"colores_base.3N18": "3N18",
	"colores_base.4N18": "ORO ROSA CLARO",
	"colores_base.5N": "5N ROSE GOLD",
	"colores_base.5N18": "ORO ROSA",
	"colores_base.026": "IVORY",
	"colores_base.032": "Marrón oscuro",
	"colores_base.037": "Marrón Havana",
	"colores_base.041": "Dorado medio",
	"colores_base.061": "Azul medio",
	"colores_base.065": "Azul claro",
	"colores_base.066": "Azul claro",
	"colores_base.068": "AZURE",
	"colores_base.084": "CORAL",
	"colores_base.100": "SHINY STEEL",
	"colores_base.128": "NUDE",
	"colores_base.591": "PVD BLACK",
	"colores_base.600": "Rosa dorado",
	"colores_base.840": "STEEL + GOLD",
	"colores_base.850": "STEEL + ROSE GOLD",
	"colores_base.ACCC": "ACERO CIRCONITAS",
	"colores_base.AG": "PLATA",
	"colores_base.AGAB": "PLATA ÁMBAR",
	"colores_base.AGCR": "PLATA CRISTAL",
	"colores_base.AGLA": "PLATA LAVANDA",
	"colores_base.AGLM": "PLATA LIME",
	"colores_base.AGMC": "PLATA MARCADA",
	"colores_base.AGMO": "PLATA MORADO",
	"colores_base.AGPE": "PLATA PERLA",
	"colores_base.AGRO": "PLATA ROJO",
	"colores_base.AGTP": "PLATA TOPACIO",
	"colores_base.AGU": "Aguamarina",
	"colores_base.AM": "Amarillo",
	"colores_base.AMA": "Amatista",
	"colores_base.AMBA": "Ámbar",
	"colores_base.AMBR": "AMARILLO BRONCE",
	"colores_base.AMCC": "AMARILLO CIRCONITAS",
	"colores_base.AMMA": "AMARILLO MARRÓN",
	"colores_base.AMPE": "AMARILLO PERLA",
	"colores_base.AMT": "AMATISTA",
	"colores_base.AQ": "AQUAMARINA",
	"colores_base.AQBO": "AQUA BOHÉMICA",
	"colores_base.AUR": "AURORA",
	"colores_base.AURB": "AURORA BOREAL",
	"colores_base.AVAZ": "AVENTURINA AZUL",
	"colores_base.AZ": "AZUL",
	"colores_base.AZRS": "AZUL/ROSA",
	"colores_base.BDIA": "DIAMANTE NEGRO",
	"colores_base.BE": "Beige",
	"colores_base.BICO": "BICOLOR",
	"colores_base.BL": "BLANCO",
	"colores_base.BLCO": "BLANCO CORAL",
	"colores_base.BLPE": "BLANCO PERLA",
	"colores_base.BLRY": "BLANCO RAYADO",
	"colores_base.BR": "Bronce",
	"colores_base.BRCC": "BRONCE CIRCONITAS",
	"colores_base.BRIL": "BRILLANTE",
	"colores_base.BRLI": "BRONCE LILA",
	"colores_base.BRRS": "BRONCE ROSA",
	"colores_base.BU": "BURDEOS",
	"colores_base.BUCR": "BURDEOS CREMA",
	"colores_base.CA": "CAMEL",
	"colores_base.CABA": "CABALLO",
	"colores_base.CAL": "CALADA",
	"colores_base.CALD": "CALDERA",
	"colores_base.CCPE": "CIRCONITA PERLA",
	"colores_base.CER": "CEREZA",
	"colores_base.CH": "CHAMPAGNE",
	"colores_base.CHA": "CHAPADO",
	"colores_base.ChGR": "CHAPADO GRIS",
	"colores_base.CHOC": "CHOCOLATE",
	"colores_base.CIRC": "CIRCONITA",
	"colores_base.CITR": "CITRINO",
	"colores_base.CM": "CREMA",
	"colores_base.CMFU": "CREMA FUCSIA",
	"colores_base.CO": "COBRE",
	"colores_base.CORA": "CORAL",
	"colores_base.CQ": "CAQUI",
	"colores_base.CR": "CROMADO",
	"colores_base.CRDO": "CRISTAL DORADO",
	"colores_base.CRIS": "CRISTAL",
	"colores_base.CRTR": "CRISTAL TRANSPARENTE",
	"colores_base.CURR": "CURRO",
	"colores_base.CZ": "CUARZO",
	"colores_base.CZAZ": "CUARZO AZUL",
	"colores_base.CZMA": "CUARZO MARRÓN",
	"colores_base.CZRS": "CUARZO ROSA",
	"colores_base.DELF": "Delfín",
	"colores_base.DI": "DIGITAL",
	"colores_base.DIAM": "DIAMANTES",
	"colores_base.DO": "DORADO",
	"colores_base.DONE": "DORADO NEGRO",
	"colores_base.ESM": "ESMERALDA",
	"colores_base.EXPO": "EXPO",
	"colores_base.FLOR": "FLOR",
	"colores_base.FLUO": "FLUORESCENTE",
	"colores_base.FOCA": "FOCA",
	"colores_base.FR": "FRESA",
	"colores_base.FRAM": "FRAMBUESA",
	"colores_base.FU": "FUCSIA",
	"colores_base.FUCC": "FUCSIA CIRCONITAS",
	"colores_base.FUME": "FUME",
	"colores_base.GATO": "GATO",
	"colores_base.GN": "GRANATE",
	"colores_base.GR": "GRIS",
	"colores_base.GRA": "GRANATE",
	"colores_base.GRAG": "Gris Plata",
	"colores_base.GRPL": "GRIS PLATEADO",
	"colores_base.HA": "HAMILTON",
	"colores_base.HAB": "HABANA",
	"colores_base.HAHA": "HAMILTON HAMILTON",
	"colores_base.HAMC": "HAMILTON MARCADO",
	"colores_base.HANA": "HAMILTON NÁCAR",
	"colores_base.HANC": "HAMILTON NACARADO",
	"colores_base.HEM": "HEMATITE",
	"colores_base.INDI": "INDICOLITE",
	"colores_base.JET": "JET",
	"colores_base.KOAL": "KOALA",
	"colores_base.LI": "LILA",
	"colores_base.LIOS": "LILA OSCURO",
	"colores_base.LIPE": "LILA PERLA",
	"colores_base.LUMI": "SUPERLUMINOVA",
	"colores_base.LUN": "LUNA",
	"colores_base.MA": "Marrón",
	"colores_base.MAAG": "MARRÓN/PLATA",
	"colores_base.MAPE": "MARRÓN PERLA",
	"colores_base.MBLU": "AZUL METALIZADO",
	"colores_base.MC": "MARCADO",
	"colores_base.ME": "Melocotón",
	"colores_base.MEN": "Menta",
	"colores_base.MF": "MARFIL",
	"colores_base.MFNE": "MARFIL-NEGRO",
	"colores_base.MGRE": "VERDE METALIZADO",
	"colores_base.MO": "MORADO",
	"colores_base.MOBL": "MORADO BLANCO",
	"colores_base.MOS": "MOSTAZA",
	"colores_base.MS": "MOSTAZA",
	"colores_base.MSIL": "PLATA METALIZADA",
	"colores_base.MT": "MATE",
	"colores_base.MULT": "MULTICOLOR",
	"colores_base.MULTAM": "MULTICOLOR AMARILLO",
	"colores_base.MULTAZ": "MULTICOLOR AZUL",
	"colores_base.MULTRS": "MULTICOLOR ROSA",
	"colores_base.NA": "NÁCAR",
	"colores_base.NAAZ": "NÁCAR AZUL",
	"colores_base.NE": "NEGRO",
	"colores_base.NEAM": "NEGRO AMARILLO",
	"colores_base.NECC": "NEGRA CIRCONITAS",
	"colores_base.NEDO": "NEGRO DORADO",
	"colores_base.NEGD": "BLACK DIAMOND",
	"colores_base.NEO": "NO LLEVA",
	"colores_base.NEPE": "NEGRO PERLA",
	"colores_base.NJ": "NARANJA",
	"colores_base.NJNC": "NARANJA NACARADO",
	"colores_base.NYB": "NYLON BLANCA",
	"colores_base.NYBE": "NYLON BEIG",
	"colores_base.NYBL": "NYLON BLANCA",
	"colores_base.NYC": "NYLON CREMA",
	"colores_base.NYCM": "NYLON CREMA",
	"colores_base.NYG": "NYLON GRIS",
	"colores_base.NYGR": "NYLON GRIS",
	"colores_base.NYM": "NYLON MARRÓN",
	"colores_base.NYMA": "NYLON MARRÓN",
	"colores_base.NYN": "NYLON NEGRA",
	"colores_base.NYNE": "NYLON NEGRA",
	"colores_base.NYO": "NYLON OCRE",
	"colores_base.NYR": "NYLON ROJO",
	"colores_base.OBSI": "OBSIDIANA",
	"colores_base.OC": "OCRE",
	"colores_base.OCR": "OCRE",
	"colores_base.OLIV": "OLIVINA",
	"colores_base.ONIX": "ONIX",
	"colores_base.OR": "ORO",
	"colores_base.ORAZ": "ORO AZUL",
	"colores_base.PACH": "PAVONADO CHAMPÁN",
	"colores_base.PAJA": "Pájaro",
	"colores_base.PAV": "PAVONADO",
	"colores_base.PD": "PALADIO",
	"colores_base.PER": "PERLA",
	"colores_base.PERN": "PERLA NEGRA",
	"colores_base.PERR": "PERRO",
	"colores_base.PLAG": "PLATEADO PLATA",
	"colores_base.PLAM": "PLATEADA AMARILLA",
	"colores_base.PLAZ": "PLATEADO AZUL",
	"colores_base.PLLI": "PLATEADO LILA",
	"colores_base.PLNE": "PLATEADO/NEGRO",
	"colores_base.PLPL": "PLATEADO PLATEADO",
	"colores_base.PLRS": "PLATEADO ROSA",
	"colores_base.PLVE": "PLATEADO VERDE",
	"colores_base.PV": "PAVONADO",
	"colores_base.PVCH": "PAVONADO CHAMPAN",
	"colores_base.PVD": "PVD",
	"colores_base.RH": "RODIO",
	"colores_base.RH5N": "ORO BLANCO + ORO 5N",
	"colores_base.RO": "ROJO",
	"colores_base.ROCA": "ROJA CALADA",
	"colores_base.ROMS": "ROJA MOSTAZA",
	"colores_base.RONE": "ROJO NEGRO",
	"colores_base.RS": "ROSA",
	"colores_base.RSCC": "ROSA CIRCONITAS",
	"colores_base.RSPE": "ROSA PERLA",
	"colores_base.RSRO": "ROSA ROJO",
	"colores_base.RU": "RUTENIO",
	"colores_base.RUBI": "Rubí",
	"colores_base.SASA": "Salmón salmón",
	"colores_base.ST": "SEMITRANSPARENTE",
	"colores_base.TA": "TIGRE AZUL",
	"colores_base.TEJA": "TEJA",
	"colores_base.TIT": "TITANIO",
	"colores_base.TOP": "TOPAZIO",
	"colores_base.TP": "TOPACIO",
	"colores_base.TPCL": "TOPACIO CLARO",
	"colores_base.TR": "TIGRE ROSA",
	"colores_base.TRAN": "TRANSPARENTE",
	"colores_base.TRCO": "TRICOLOR",
	"colores_base.TRI": "TRICOLOR",
	"colores_base.TRNE": "TRANSP NEGRO",
	"colores_base.TW/W": "TOP WESSELTON A WESSELTON",
	"colores_base.VA": "VAINILLA",
	"colores_base.VE": "VERDE",
	"colores_base.VEBRI": "VERDE BRILLANTE",
	"colores_base.VECC": "VERDE CIRCONITAS",
	"colores_base.VI": "VIOLETA",
	"colores_base.VIDI": "VIOLETA DIGITAL",
	"colores_base.VIGR": "VIOLETE GRIS",
	"colores_base.VIN": "VINO",
	"colores_base.VIOS": "VIOLETA OSCURO",
	"colores_base.ZAFI": "ZAFIRO",
	"colores_catalogo.AG": "PLATA",
	"colores_catalogo.AM": "AMARILLO",
	"colores_catalogo.AZ": "AZUL",
	"colores_catalogo.BE": "BEIGE",
	"colores_catalogo.BL": "BLANCO",
	"colores_catalogo.BU": "BURDEOS",
	"colores_catalogo.CH": "CHAMPAN",
	"colores_catalogo.CM": "CREMA",
	"colores_catalogo.CO": "COBRE",
	"colores_catalogo.DO": "DORADO",
	"colores_catalogo.GR": "GRIS",
	"colores_catalogo.MA": "Marrón",
	"colores_catalogo.MULT": "MULTICOLOR",
	"colores_catalogo.NA": "Nácar",
	"colores_catalogo.NE": "NEGRO",
	"colores_catalogo.NJ": "NARANJA",
	"colores_catalogo.PL": "PLATEADO",
	"colores_catalogo.PLA": "PLATEADO",
	"colores_catalogo.PLAZ": "PLATEADO AZUL",
	"colores_catalogo.PLRS": "PLATEADO ROSA",
	"colores_catalogo.RO": "ROJO",
	"colores_catalogo.ROSE": "ROSE",
	"colores_catalogo.RS": "ROSA",
	"colores_catalogo.VE": "VERDE",
	"colores_catalogo.VI": "VIOLETA",
	"correa.agrupada.ARMYS": "ARMYS",
	"correa.agrupada.CORREA": "CORREA",
	"estanqueidad.1A": "1 ATM",
	"estanqueidad.3A": "3 ATM",
	"estanqueidad.5A": "5 ATM",
	"estanqueidad.10A": "10 ATM",
	"estanqueidad.20A": "20 ATM",
	"estanqueidad.?": "Sin estanqueidad",
	"estanqueidad.IP68": "IP68",
	"estanqueidad.NEO": "NO TIENE",
	"estanqueidad.R.P.": "RESISTENTE AL POLVO",
	"estanqueidad.RP": "RESISTENTE AL POLVO",
	"estanqueidad.SHPR": "SHOWERPROOF",
	"estanqueidad.W.P.": "WATER PROFF.",
	"estanqueidad.W.R.": "WATER RESISTANT",
	"estanqueidad.WR": "WATER RESISTANT",
	"estilos.CA": "CASUAL",
	"estilos.CH": "CHIC",
	"estilos.CL": "Clásico",
	"estilos.EL": "ELEGANCE",
	"estilos.LE": "LIMITED EDITION",
	"estilos.SP": "DEPORTIVO",
	"estilos.VI": "VINTAGE",
	"formaCaja.C": "Cuadrada",
	"formaCaja.CR": "Cruz",
	"formaCaja.DI": "Digital",
	"formaCaja.IR": "Irregular",
	"formaCaja.O": "Oval",
	"formaCaja.RC": "Rectangular",
	"formaCaja.RD": "Redonda",
	"formaCaja.TO": "Tonel",
	"formaCaja.TV": "Televisión",
	"funcion.agrupada.101": "Funciones Digitales",
	"funcion.agrupada.102": "Multifunción",
	"funcion.agrupada.103": "HORAS Y MINUTOS",
	"funcion.agrupada.104": "Cronógrafo",
	"funcion.agrupada.105": "Funciones Inteligentes",
	"gamas.?": "",
	"gamas.agrupada.BE": "Bebe",
	"gamas.agrupada.C": "HOMBRE",
	"gamas.agrupada.IN": "INFANTIL",
	"gamas.agrupada.NA": "Nina",
	"gamas.agrupada.NI": "Nino",
	"gamas.agrupada.S": "MUJER",
	"gamas.agrupada.UN": "Unisex",
	"gamas.AN": "ANILLOS",
	"gamas.BE": "Bebe",
	"gamas.C": "HOMBRE",
	"gamas.CD": "JUNIOR",
	"gamas.CO": "COLGANTE",
	"gamas.IN": "JUNIOR",
	"gamas.NA": "JUNIOR",
	"gamas.NI": "JUNIOR",
	"gamas.PE": "MUJER",
	"gamas.PU": "PULSERAS",
	"joyas.tipo.agrupada.ANILLO": "ANILLO",
	"joyas.tipo.agrupada.BROCHE": "BROCHE",
	"joyas.tipo.agrupada.CIERRE": "Cierres",
	"joyas.tipo.agrupada.COLGANTE": "COLGANTE",
	"joyas.tipo.agrupada.COLLAR": "COLLAR",
	"joyas.tipo.agrupada.CONJUNTO": "CONJUNTO",
	"joyas.tipo.agrupada.GEMELOS": "GEMELOS",
	"joyas.tipo.agrupada.PENDIENTE_CADENA": "PENDIENTE CADENA",
	"joyas.tipo.agrupada.PENDIENTE_TREPADOR": "PENDIENTE TREPADOR",
	"joyas.tipo.agrupada.PENDIENTES": "PENDIENTES",
	"joyas.tipo.agrupada.PIERCING": "PIERCING",
	"joyas.tipo.agrupada.PULSERA": "PULSERA",
	"joyas.tipo.agrupada.TOBILLERA": "TOBILLERA",
	"joyas.tipo.ANILLO": "ANILLO",
	"joyas.tipo.BROCHE": "BROCHE",
	"joyas.tipo.COLGANTE": "COLGANTE",
	"joyas.tipo.COLLAR": "COLLAR",
	"joyas.tipo.CONJUNTO": "CONJUNTO",
	"joyas.tipo.GEMELOS": "GEMELOS",
	"joyas.tipo.PENDIENTE_CADENA": "PENDIENTE CADENA",
	"joyas.tipo.PENDIENTE_TREPADOR": "PENDIENTE TREPADOR",
	"joyas.tipo.PENDIENTES": "PENDIENTES",
	"joyas.tipo.PIERCING": "PIERCING",
	"joyas.tipo.PULSERA": "PULSERA",
	"joyas.tipo.sub.1-1": "LISA",
	"joyas.tipo.sub.1-2": "CON PIEDRAS",
	"joyas.tipo.sub.1-3": "CON DIAMANTES",
	"joyas.tipo.sub.1-4": "ALIANZA LISA",
	"joyas.tipo.sub.1-5": "ALIANZA LAPIDADA",
	"joyas.tipo.sub.1-6": "ALIANZA CON PIEDRA",
	"joyas.tipo.sub.1-7": "SELLO",
	"joyas.tipo.sub.1-8": "ESTAMPADO ESMALTE",
	"joyas.tipo.sub.1-9": "ALIANZA DIAMANTES",
	"joyas.tipo.sub.1-10": "ESTAMPADO",
	"joyas.tipo.sub.1-11": "ESMALTADO",
	"joyas.tipo.sub.1-12": "CALADO",
	"joyas.tipo.sub.1-13": "PIDRA CRISTAL",
	"joyas.tipo.sub.1-14": "SWAROVSKI",
	"joyas.tipo.sub.2-1": "LISO",
	"joyas.tipo.sub.2-2": "CON PIEDRAS",
	"joyas.tipo.sub.2-3": "ESCAPULARIO",
	"joyas.tipo.sub.2-4": "CRUZ LISA",
	"joyas.tipo.sub.2-5": "VIRGEN NIÑA",
	"joyas.tipo.sub.2-6": "PERGAMINO/PLACA",
	"joyas.tipo.sub.2-7": "ANGELITOS",
	"joyas.tipo.sub.2-8": "CRUZ CON PIEDRAS",
	"joyas.tipo.sub.2-9": "CRUZ CON CRISTO",
	"joyas.tipo.sub.2-10": "FETICHE",
	"joyas.tipo.sub.2-11": "CRUZ CON DIAMANTES",
	"joyas.tipo.sub.2-12": "CON DIAMANTES",
	"joyas.tipo.sub.2-13": "FETICHE CON PIEDRAS",
	"joyas.tipo.sub.2-14": "ELÁSTICO",
	"joyas.tipo.sub.2-15": "VIRGEN",
	"joyas.tipo.sub.2-16": "CRISTO",
	"joyas.tipo.sub.2-17": "CRUZ CARAVACA",
	"joyas.tipo.sub.2-18": "ESMALTADO",
	"joyas.tipo.sub.2-19": "COMUNIÓN",
	"joyas.tipo.sub.2-20": "CALADO",
	"joyas.tipo.sub.2-21": "CRUZ CALADA",
	"joyas.tipo.sub.3-1": "CHATÓN",
	"joyas.tipo.sub.3-2": "Circonita",
	"joyas.tipo.sub.3-3": "PIEDRA COLOR",
	"joyas.tipo.sub.3-4": "ESMALTE",
	"joyas.tipo.sub.3-5": "ELECTROFORMING",
	"joyas.tipo.sub.3-6": "PERLAS",
	"joyas.tipo.sub.3-7": "TU Y YO",
	"joyas.tipo.sub.3-8": "ARO",
	"joyas.tipo.sub.3-9": "BOLA",
	"joyas.tipo.sub.3-10": "VARIOS",
	"joyas.tipo.sub.3-11": "Cerámicas",
	"joyas.tipo.sub.3-12": "LARGO CON PIEDRAS",
	"joyas.tipo.sub.3-13": "ARO",
	"joyas.tipo.sub.3-14": "ESMALTADO",
	"joyas.tipo.sub.3-15": "CON DIAMANTES",
	"joyas.tipo.sub.3-16": "ELÁSTICO",
	"joyas.tipo.sub.3-17": "ARO CON PIEDRA",
	"joyas.tipo.sub.3-18": "ESTAMPADO",
	"joyas.tipo.sub.3-19": "CALADO",
	"joyas.tipo.sub.4-1": "LISA",
	"joyas.tipo.sub.4-2": "GRABADA",
	"joyas.tipo.sub.4-3": "RIZADA",
	"joyas.tipo.sub.4-4": "RIZADA CON PIEDRAS",
	"joyas.tipo.sub.4-5": "LISA CON PIEDRAS",
	"joyas.tipo.sub.4-6": "LISA CON DIAMANTES",
	"joyas.tipo.sub.4-7": "ESMALTE",
	"joyas.tipo.sub.4-8": "CON PIEL",
	"joyas.tipo.sub.5-1": "1ªPOSTURA CON CHAPA",
	"joyas.tipo.sub.5-2": "FINA",
	"joyas.tipo.sub.5-3": "GRUESA",
	"joyas.tipo.sub.5-4": "GRUESA MACIZA CON CHAPA",
	"joyas.tipo.sub.5-5": "GRUESA MACIZA",
	"joyas.tipo.sub.5-6": "GRUESA HUECA CON ABALORIOS",
	"joyas.tipo.sub.5-7": "GRUESA HUECA",
	"joyas.tipo.sub.5-8": "GRUESA CON PIEDRAS",
	"joyas.tipo.sub.5-9": "RIVIERE",
	"joyas.tipo.sub.5-10": "GRUESA HUECA CON CHAPA",
	"joyas.tipo.sub.5-11": "FINA HUECA CON CHAPA",
	"joyas.tipo.sub.5-12": "CON PIEDRA",
	"joyas.tipo.sub.5-13": "BOLAS",
	"joyas.tipo.sub.5-14": "BOLA CON PIEDRA",
	"joyas.tipo.sub.5-15": "1ªPOSTURA",
	"joyas.tipo.sub.5-16": "FINA HUECA",
	"joyas.tipo.sub.5-17": "FINA MACIZA CON CHAPA",
	"joyas.tipo.sub.5-18": "ELÁSTICA",
	"joyas.tipo.sub.5-19": "CON ABALORIOS",
	"joyas.tipo.sub.5-20": "CORREA PIEL",
	"joyas.tipo.sub.5-21": "CON DIAMANTES",
	"joyas.tipo.sub.5-22": "CORREA PIEL DIAMANTES",
	"joyas.tipo.sub.5-23": "CON PERLAS",
	"joyas.tipo.sub.5-24": "CORREA CAUCHO",
	"joyas.tipo.sub.6-1": "FINA HUECA",
	"joyas.tipo.sub.6-2": "GRUESA HUECA",
	"joyas.tipo.sub.6-3": "FINA MACIZA",
	"joyas.tipo.sub.6-4": "GRUESA MACIZA",
	"joyas.tipo.sub.6-5": "CAUCHO CON CIERRE",
	"joyas.tipo.sub.6-6": "CUERO CON PLATA",
	"joyas.tipo.sub.6-7": "CUERO CON CIERRE ORO",
	"joyas.tipo.sub.6-8": "HUECO LAPIDADO",
	"joyas.tipo.sub.6-9": "NYLON CIERRE PLAT",
	"joyas.tipo.sub.7-1": "HUECO LAPIDADO",
	"joyas.tipo.sub.7-2": "FINA CON PIEDRA",
	"joyas.tipo.sub.7-3": "FINA CON COLGANTE",
	"joyas.tipo.sub.7-4": "ANILLAS",
	"joyas.tipo.sub.7-5": "FINA COLGANTE PIEDRA",
	"joyas.tipo.sub.7-6": "GRUESA CON COLGANTE",
	"joyas.tipo.sub.7-7": "FINA",
	"joyas.tipo.sub.7-8": "BOLAS",
	"joyas.tipo.sub.7-9": "FINA CON COLGANTE ESMALTE",
	"joyas.tipo.sub.7-10": "GRUESA CON PIEDRAS",
	"joyas.tipo.sub.7-11": "GRUESO HUECO",
	"joyas.tipo.sub.7-12": "FINA HUECA",
	"joyas.tipo.sub.7-13": "CAUCHO CON COLGANTE",
	"joyas.tipo.sub.7-14": "FINA COLGANTE DIAMANTE",
	"joyas.tipo.sub.7-15": "ELÁSTICO",
	"joyas.tipo.sub.7-16": "GRUESA CON DIAMANTES",
	"joyas.tipo.sub.7-17": "CORDÓN PIEL",
	"joyas.tipo.sub.7-18": "CORDÓN CON COLGANTE",
	"joyas.tipo.sub.7-19": "CON ABALORIOS",
	"joyas.tipo.sub.8-1": "1ªPOSTURA",
	"joyas.tipo.sub.8-2": "CORBATA",
	"joyas.tipo.sub.9-1": "FINA LISA",
	"joyas.tipo.sub.9-2": "GRUESA LISA",
	"joyas.tipo.sub.9-3": "FINA CON PIEDRAS",
	"joyas.tipo.sub.9-4": "GRUESA CON PIEDRAS",
	"joyas.tipo.sub.9-5": "FINA CON DIAMANTES",
	"joyas.tipo.sub.9-6": "GRUESA CON DIAMANTES",
	"joyas.tipo.sub.9-7": "BOLAS",
	"joyas.tipo.sub.9-8": "RIVIERE",
	"joyas.tipo.sub.10-1": "LISO",
	"joyas.tipo.sub.10-2": "CON PIEDRAS",
	"joyas.tipo.sub.10-3": "PERLAS",
	"joyas.tipo.sub.11-1": "LISO",
	"joyas.tipo.sub.11-2": "CON PIEDRAS",
	"joyas.tipo.sub.12-1": "CON DIAMANTES",
	"joyas.tipo.sub.12-2": "LISO",
	"joyas.tipo.sub.13-1": "LISO",
	"joyas.tipo.sub.13-2": "CON PIEDRAS",
	"joyas.tipo.sub.13-3": "CON DIAMANTES",
	"joyas.tipo.sub.14-1": "Presión",
	"joyas.tipo.sub.14-2": "ROSCA",
	"joyas.tipo.sub.15-1": "CON ABALORIOS",
	"joyas.tipo.sub.17-1": "PERNOS PAR",
	"joyas.tipo.sub.25-1": "EASY CLICK",
	"joyas.tipo.sub.99": "SIN DEFINIR",
	"joyas.tipo.sub.900-1": "9K AMARILLO",
	"joyas.tipo.sub.900-2": "9K BLANCO",
	"joyas.tipo.sub.900-3": "9K BICOLOR",
	"joyas.tipo.sub.undefined": "",
	"joyas.tipo.TOBILLERA": "TOBILLERA",
	"kilataje.ORO9K": "ORO 9K",
	"kilataje.ORO18K": "ORO 18K",
	"longpatillas.140": "140 mm",
	"longpatillas.142": "142 mm",
	"longpatillas.145": "145 mm",
	"material.9K": "Oro 9K de 375 ml",
	"material.18K": "ORO 18K",
	"material.24K": "ORO FINO",
	"material.?": "?",
	"material.AC": "ACERO",
	"material.AC-EB": "ACERO-EBANO",
	"material.ACAC": "ACERO Y ACERO",
	"material.ACBI": "ACERO-BICOLOR",
	"material.ACBR": "ACERO BRILLANTES",
	"material.ACCA": "ACERO Y CAUCHO",
	"material.ACCC": "ACERO CIRCONITAS",
	"material.ACCE": "ACERO CERAMICA",
	"material.ACCH": "ACERO CHAPADO",
	"material.ACDI": "ACERO DIAMANTES",
	"material.ACE": "Acero inoxidable 316l",
	"material.ACERO": "Acero inoxidable 316l",
	"material.ACETA": "Acetato",
	"material.ACFC": "FIBRA DE CARBONO",
	"material.ACHB": "ACERO CHAPADO BICOLOR",
	"material.ACHO": "ACERO CHAPADO ORO",
	"material.ACMA": "ACERO Y MADERA",
	"material.ACOR": "ACERO Y ORO",
	"material.ACPI": "ACERO Y PIEL",
	"material.ACPL": "ACERO Y PLÁSTICO",
	"material.ACTI": "ACERO TITANIUM",
	"material.AGCI": "PLATA-CIRCONITAS",
	"material.AGES": "PLATA ESMALTADA",
	"material.AGG": "PLATA",
	"material.AGPE": "PLATA Y PERLAS",
	"material.agrupado.ACERO": "ACERO",
	"material.agrupado.ACETA": "ACETATO",
	"material.agrupado.CAUCHO": "CAUCHO",
	"material.agrupado.CERAMICA": "Cerámica",
	"material.agrupado.CUERO": "CUERO",
	"material.agrupado.GOMA": "GOMA",
	"material.agrupado.METAL": "METAL",
	"material.agrupado.ORO": "ORO",
	"material.agrupado.ORO9K": "ORO9K",
	"material.agrupado.ORO18K": "ORO18K",
	"material.agrupado.PLATA": "PLATA",
	"material.agrupado.TITANIO": "TITANIO",
	"material.AGS": "PLATA SWAROVSKI",
	"material.ALG": "ALGODÓN",
	"material.ALUM": "ALUMINIO",
	"material.ALZ": "Aleacion de zinc",
	"material.ANTE": "ANTE",
	"material.BELC": "VELCRO",
	"material.BICO": "BICOLOR",
	"material.BRO": "Bronce",
	"material.C18K": "CAUCHO + CIERRE ORO",
	"material.CAAC": "CAUCHO ACERO",
	"material.CAU": "CAUCHO",
	"material.CAUCHO": "Caucho",
	"material.CEBR": "CERÁMICA-BRILLANTES",
	"material.CER": "CERÁMICA",
	"material.CERAMICA": "Cerámica",
	"material.CHA": "CHAPADA",
	"material.CHAP": "CHAPADO",
	"material.CHBI": "CHAPADO BICOLOR",
	"material.CHOR": "CHAPADO ORO",
	"material.COCO": "PIEL COCODRILO",
	"material.COCT": "COCO CORTE",
	"material.CRBI": "CROMADO BICOLOR",
	"material.CRMO": "CROMO",
	"material.CRO": "CROMADO",
	"material.CU": "CUERDA",
	"material.CUERO": "CUERO",
	"material.FIB": "FIBRA",
	"material.GO": "GOMA",
	"material.GOCA": "GOMA CAUCHO",
	"material.GOM": "GOMA",
	"material.HNAU": "HILO NÁUTICO",
	"material.LACH": "LATÓN CHAPADO",
	"material.LACR": "LATÓN CROMADO",
	"material.LACT": "LAGARTO CORTE",
	"material.LAG": "LAGARTO",
	"material.LAT": "LATÓN",
	"material.LCHB": "LATÓN CHAPADO BICOLOR",
	"material.LIQ": "Líquido",
	"material.METAL": "METAL",
	"material.MIL": "MINERAL LUPA",
	"material.MIN": "MINERAL",
	"material.MINP": "MINERAL PLANO",
	"material.MINT": "MINERAL TINTADO",
	"material.NEO": "NEOPRENO",
	"material.NOB": "NOBUCK",
	"material.NY": "NYLON",
	"material.NYBE": "NYLON BELCRO",
	"material.NYCA": "NYLON CAUCHO",
	"material.NYCT": "NYLON CORTE",
	"material.NYL": "NYLON",
	"material.NYPC": "NYLON-PIEL CORTE",
	"material.NYPI": "NYLON BASE PIEL",
	"material.O18": "ORO 18 KILATES",
	"material.OASP": "ORO 18K SIN PRECIO",
	"material.OB5N": "ORO BLANCO + ORO 5N",
	"material.OBSP": "ORO BLANCO SIN PRECIO",
	"material.OR18": "ORO 18 KTES.",
	"material.OR24": "ORO 24 KTES.",
	"material.ORO": "Oro",
	"material.OROB": "ORO BLANCO 18K",
	"material.P+O": "PIEL+CIERRE ORO",
	"material.P-CC": "PIEL C/CORTE (7.5X4.2)",
	"material.PABA": "PAVE BALL",
	"material.PALD": "PALADIO",
	"material.PAST": "PASTA",
	"material.PC": "PLÁSTICO+FIBRA CRISTAL",
	"material.PEL": "PLEX LUPA",
	"material.PI": "PIEL",
	"material.PI-C": "PIEL C/CORTE (7.5X3.8)",
	"material.PICA": "PIEL CAUCHO",
	"material.PICH": "PIEL CHAROL",
	"material.PICT": "PIEL CORTE",
	"material.PIEL": "CUERO",
	"material.PIEL-PU": "PIEL-POLIURETANO",
	"material.PIGR": "PIEL GRABADA",
	"material.PILI": "PIEL LISA",
	"material.PINA": "PIEL NAYLON",
	"material.PINY": "PIEL NYLON",
	"material.PIPL": "PIEL PLASTIFICADA",
	"material.PIRA": "PIEL DE RAYA",
	"material.PISI": "PIEL SINTÉTICA",
	"material.PISIN": "PIEL SINTÉTICA",
	"material.PL": "PLEX",
	"material.PLA": "Plástico",
	"material.PLAA": "PLÁSTICO ARO",
	"material.PLAL": "PLÁSTICO-LUPA",
	"material.PLAS": "Plástico",
	"material.PLAT": "Plata 925 ml",
	"material.PLATA": "Plata",
	"material.PLEX": "PLEX PLÁSTICO",
	"material.PLNY": "PLÁSTICO NYLON",
	"material.PLPI": "PLÁSTICO BASE PIEL",
	"material.PLPL": "PLPL",
	"material.PLXL": "PLAST+LUPA",
	"material.POLICARBONATO": "POLICARBONATO",
	"material.PU": "POLIURETANO",
	"material.PUPI": "PURPURINA BASE PIEL",
	"material.PVC": "PVC",
	"material.RAYA": "PIEL RAYA",
	"material.RH": "RODIO",
	"material.RU": "RUTENIO",
	"material.SERP": "SERPIENTE",
	"material.SPBP": "SINTÉTICO BASE PIEL",
	"material.STBP": "SINTÉTICO BASE PIEL",
	"material.SWA": "SWAROVSKI",
	"material.TELA": "TELA",
	"material.TEPI": "TELA BASE PIEL",
	"material.TIBI": "TITANIO BICOLOR",
	"material.TIBU": "PIEL TIBURÓN",
	"material.TIT": "TITANIO",
	"material.TITANIO": "Titanio",
	"material.TORT": "PIEL TORTUGA",
	"material.TUNG": "TUNGSTENO",
	"material.undefined": "",
	"material.Z+L": "ZAFIRO+LUPA",
	"material.Z-G": "ZAF+LUPA+GRABADO",
	"material.ZAF": "ZAFIRO",
	"material.ZAFP": "ZAFIRO PINTADO",
	"movimiento.funcion.1": "SIMPLE(CAL)",
	"movimiento.funcion.4": "CRONO(NO CAL)",
	"movimiento.funcion.12": "RESER-MARCHA",
	"movimiento.funcion.101": "Digital",
	"movimiento.funcion.102": "MULTIFUNCION",
	"movimiento.funcion.103": "CUARZO",
	"movimiento.funcion.104": "CRONÓGRAFO",
	"movimiento.funcion.105": "Digital",
	"movimiento.funcion.106": "Digital",
	"movimiento.funcion.107": "ANALOGICO ALARMA",
	"movimiento.funcion.108": "MULTIFUNCION",
	"movimiento.funcion.109": "DUAL-TIME",
	"movimiento.funcion.110": "AUTOMATICO",
	"movimiento.funcion.111": "Tourbillon",
	"movimiento.funcion.112": "GMT",
	"movimiento.funcion.113": "RESER-MARCHA",
	"movimiento.funcion.agrupada.ANALO": "Analógico",
	"movimiento.funcion.agrupada.AUTO": "RESER-MARCHA",
	"movimiento.funcion.agrupada.CRONO": "Cronógrafo",
	"movimiento.funcion.agrupada.DIGI": "DIGITAL",
	"movimiento.funcion.agrupada.HYBRID": "Híbridos",
	"movimiento.funcion.agrupada.MULTI": "Multifunción",
	"movimiento.funcion.agrupada.SMART": "smartwatch",
	"movimiento.funcion.ANA-AL": "ANALÓGICO ALARMA",
	"movimiento.funcion.ANA-DI": "ANALÓGICO DIGITAL",
	"movimiento.funcion.ANA-DIGI": "ANALÓGICO DIGITAL",
	"movimiento.funcion.AUT-AL": "AUTOMÁTICO-ALARMA",
	"movimiento.funcion.AUTO": "Automático",
	"movimiento.funcion.CRO-SUI": "CRONÓGRAFO SUIZO",
	"movimiento.funcion.CRO.SOL": "CRONO SOLAR",
	"movimiento.funcion.CRONO": "Cronógrafo",
	"movimiento.funcion.CRONO-AL": "CRONO-ALARMA",
	"movimiento.funcion.DI-MULTI": "DIGITAL MULTIFUCIÓN",
	"movimiento.funcion.DIGI": "DIGITAL",
	"movimiento.funcion.DUAL-TIM": "DUAL-TIME",
	"movimiento.funcion.HYBRID": "Híbridos",
	"movimiento.funcion.MULTI": "Multifunción",
	"movimiento.funcion.QUTZ-ANA": "QUARTZ/ANALÓGICO",
	"movimiento.funcion.SI": "SIMPLE",
	"movimiento.funcion.SIM": "SIMPLE",
	"movimiento.funcion.SIM-SUI": "SIMPLE SUIZO",
	"movimiento.funcion.SMART": "SMART WATCH",
	"movimiento.tipo.01": "DIGITAL",
	"movimiento.tipo.1": "SIMPLE(CAL)",
	"movimiento.tipo.02": "ANALOGICO DIGITAL",
	"movimiento.tipo.03": "QUARTZ/ANALOGICO",
	"movimiento.tipo.04": "AUTOMATICO",
	"movimiento.tipo.05": "HIBRID",
	"movimiento.tipo.06": "SMART WATCH",
	"movimiento.tipo.07": "SOLARES",
	"movimiento.tipo.7": "MULTIF(CAL)",
	"movimiento.tipo.12": "RESER-MARCHA",
	"movimiento.tipo.ANA-AL": "ANALÓGICO ALARMA",
	"movimiento.tipo.ANA-DI": "ANALÓGICO DIGITAL",
	"movimiento.tipo.ANA-DIGI": "ANALÓGICO DIGITAL",
	"movimiento.tipo.AUT-AL": "AUTOMÁTICO-ALARMA",
	"movimiento.tipo.AUTO": "AUTOMÁTICO",
	"movimiento.tipo.AUTO-CRO": "CRONOGRAFO AUTOMÁTICO",
	"movimiento.tipo.AUTOM": "Automático",
	"movimiento.tipo.C": "CUERDA",
	"movimiento.tipo.CRO-SUI": "CRONÓGRAFO SUIZO",
	"movimiento.tipo.CRONO": "Cronógrafo",
	"movimiento.tipo.CUER-MEC": "CUERDA/MECÁNICO",
	"movimiento.tipo.CUER.": "CUERDA",
	"movimiento.tipo.D": "DIGITALES",
	"movimiento.tipo.DI": "DIGITAL",
	"movimiento.tipo.DIGI": "DIGITAL",
	"movimiento.tipo.DUAL-TIM": "DUAL-TIME",
	"movimiento.tipo.F": "SOLARES",
	"movimiento.tipo.HIBRID": "HIBRID",
	"movimiento.tipo.ME.CRO.": "MECA.CRONO",
	"movimiento.tipo.ME.SIM.": "MECA SIMPLE",
	"movimiento.tipo.MULTI": "Multifunción",
	"movimiento.tipo.MULTI.CR": "MULTI.CRONO",
	"movimiento.tipo.PROF.": "PROFUNDÍMETRO",
	"movimiento.tipo.QUTZ-ANA": "Cuarzo/analógico",
	"movimiento.tipo.SIM": "SIMPLE",
	"movimiento.tipo.SIM-SUI": "SIMPLE SUIZO",
	"movimiento.tipo.SMART": "SMART WATCH",
	"movimiento.tipo.TOURBILLON": "TOURBILLON",
	"numeraciones.1A+AP": "1AR.AP+AR.PI",
	"numeraciones.1A+CC": "1AR.AP+CRIRC",
	"numeraciones.1A+IA": "1AR.AP+IN.AP",
	"numeraciones.1A+IP": "1AR.AP+IN.PI",
	"numeraciones.1A.AP": "1AR.AP",
	"numeraciones.1A.PI": "1AR.PI",
	"numeraciones.1AA": "1AR.AP",
	"numeraciones.1AP+I": "1AR.PI+IN.AP",
	"numeraciones.1AP+P": "1AR.PI+IN.PI",
	"numeraciones.1BR+I": "1BRILL+IN.AP",
	"numeraciones.1CC": "1CIRC",
	"numeraciones.1CC+I": "1CIRCO+IN.AP",
	"numeraciones.1CC+IA": "1CIRC+IN.AP",
	"numeraciones.1CIR+I": "1CIRCO+IN.AP.",
	"numeraciones.1D+IN": "1DIAM+IND APLI",
	"numeraciones.1I.AP": "1IN.AP",
	"numeraciones.1IA": "1IN.AP",
	"numeraciones.1IP": "1IN.PI",
	"numeraciones.1R+IA": "1RO.AP+IN.AP",
	"numeraciones.1R+IP": "1RO.AP+IN.PI",
	"numeraciones.1R+RP": "1RO.AP+RO.PI",
	"numeraciones.1R.AP": "1RO.AP",
	"numeraciones.1R.P": "1RO.PI",
	"numeraciones.1R.PI": "1RO.PI",
	"numeraciones.1RA": "1RO.AP.",
	"numeraciones.1RA+P": "1R.APL+RO.PIN.",
	"numeraciones.1RP+I": "1RO.PI+IN.AP",
	"numeraciones.1RP+P": "1RO.PI+IN.PI",
	"numeraciones.2A+AP": "2AR.AP+AR.PI",
	"numeraciones.2A+CC": "2AR.AP+CIRC",
	"numeraciones.2A+IA": "2AR.AP+IN.AP",
	"numeraciones.2A+IP": "2AR.AP+IN.PI",
	"numeraciones.2A.AP": "2AR.AP",
	"numeraciones.2A.PI": "2AR.PI",
	"numeraciones.2AA+2RA": "2AR.AP+2RO.AP",
	"numeraciones.2AP+2RP": "2AR.PI+2RO.PI",
	"numeraciones.2AP+ARP": "2AR.PI+2RO.PI",
	"numeraciones.2AP+I": "2AR.PI+IN.AP",
	"numeraciones.2AP+P": "2AR.PI+IN.PI",
	"numeraciones.2CC": "2CIRC.",
	"numeraciones.2CC+I": "2CIRC+IN.AP",
	"numeraciones.2CC+IA": "2CIRC+IN.AP",
	"numeraciones.2I+RP": "2IN.AP+10RO.PI",
	"numeraciones.2I.AP": "2IN.AP",
	"numeraciones.2IA": "2IN.AP",
	"numeraciones.2IA+P": "2IN.AP+IN.PI.",
	"numeraciones.2IP": "2IN.PI",
	"numeraciones.2R+CC": "2RO.AP+CIRC",
	"numeraciones.2R+IA": "2RO.AP+IN.AP",
	"numeraciones.2R+IP": "2RO.AP+IN.PI",
	"numeraciones.2R+RP": "2RO.AP+RO.PI",
	"numeraciones.2R.AP": "2RO.AP",
	"numeraciones.2R.P": "2RO.PI.",
	"numeraciones.2R.PI": "2RO.PI",
	"numeraciones.2RA": "2ROM.APL.",
	"numeraciones.2RP+C": "2RO.PI+CIRC",
	"numeraciones.2RP+I": "2RO.PI+IN.AP",
	"numeraciones.2RP+P": "2RO.PI+IN.PI",
	"numeraciones.3A+AP": "3AR.AP+AR.PI",
	"numeraciones.3A+IA": "3AR.AP+IN.AP",
	"numeraciones.3A+IP": "3AR.AP+IN.PI",
	"numeraciones.3A.AP": "3AR.AP",
	"numeraciones.3A.PI": "3AR.PI",
	"numeraciones.3AA+P": "3ARA.A+8ARA.PIN.",
	"numeraciones.3AP+I": "3AR.PI+IN.AP.",
	"numeraciones.3AP+P": "3AR.PI+IN.PI",
	"numeraciones.3CC": "3CIRC.",
	"numeraciones.3CC+IA": "3CIRC+IN.AP",
	"numeraciones.3I+RP": "3IN.AP+RO.PI",
	"numeraciones.3I.AP": "3IN.AP",
	"numeraciones.3IA": "3IN.AP",
	"numeraciones.3IP": "3IN.PI",
	"numeraciones.3R+CC": "3ROM + CIRCONITAS",
	"numeraciones.3R+IA": "3RO.AP+IN.AP",
	"numeraciones.3R+IP": "3RO.AP+IN.PI",
	"numeraciones.3R+IR": "3/4 ROMANOS+IND.APL",
	"numeraciones.3R+RP": "3RO.AP+RO.PI",
	"numeraciones.3R.AP": "3RO.AP",
	"numeraciones.3R.P": "3RO.PI.",
	"numeraciones.3R.PI": "3RO.PI",
	"numeraciones.3RA+CC": "3RA.AP+CC",
	"numeraciones.3RA+P": "3RO.AP+8RO.PI.",
	"numeraciones.3RE+E": "3ROM.EST+IND.ESTAMP.",
	"numeraciones.3RP+I": "3RO.PI+IN.AP",
	"numeraciones.3RP+P": "3RO.PI+IN.PI",
	"numeraciones.4A+4A": "4AR.PI+4A.PI",
	"numeraciones.4A+4AP": "4AR.AP+4AR.P",
	"numeraciones.4A+4R": "4AR+IA/4R+IA.",
	"numeraciones.4A+AP": "4AR.AP+AR.PI",
	"numeraciones.4A+CC": "4ARA.AP+CIRC",
	"numeraciones.4A+IA": "4AR.AP+IN.AP",
	"numeraciones.4A+IP": "4AR.AP+IN.PI",
	"numeraciones.4A+P": "4ARP+IND.PIN.",
	"numeraciones.4A.AP": "4AR.AP",
	"numeraciones.4A.PI": "4AR.PI",
	"numeraciones.4AA+6": "4AR.A+6AR.P",
	"numeraciones.4AA+P": "4ARA.A+8ARA.PIN.",
	"numeraciones.4AP+I": "4AR.PI+IN.AP",
	"numeraciones.4AP+P": "4AR.PI+IN.PI",
	"numeraciones.4AR.P": "4ARA.PINT+IND.PIN.",
	"numeraciones.4CC": "4CIRC.",
	"numeraciones.4DIA": "4 DIAMANTES",
	"numeraciones.4I+CC": "4IN.AP+CIRC",
	"numeraciones.4IA": "4IN.AP",
	"numeraciones.4IA+P": "4IN.AP+IN.PI",
	"numeraciones.4IN.A": "4 ÍNDICES APLICADOS",
	"numeraciones.4INDS": "4ÍNDICES",
	"numeraciones.4IP": "4IN.PI",
	"numeraciones.4R+CC": "4ROM.+CIRC.",
	"numeraciones.4R+DI": "/4ROM.APLIC.+8DIAM",
	"numeraciones.4R+IA": "4RO.AP+IN.AP",
	"numeraciones.4R+IP": "4RO.AP+IN.PI",
	"numeraciones.4R+RP": "4RO.AP+RO.PI",
	"numeraciones.4R.AP": "4RO.AP",
	"numeraciones.4R.P": "4RO.PI.",
	"numeraciones.4R.PI": "4RO.PI",
	"numeraciones.4RA+I": "4/ROMAN.APL.+IND.APL",
	"numeraciones.4RA+P": "4RO.AP+8RO.PI",
	"numeraciones.4RE+E": "4RO.EST+IN.EST.",
	"numeraciones.4ROM": "4 ROMANOS",
	"numeraciones.4RP+I": "4RO.PI+IN.AP",
	"numeraciones.4RP+P": "4RO.PI+IN.PI",
	"numeraciones.5A+5CC": "5AR.AP+5CIRC",
	"numeraciones.5A+AP": "5AR.AP+AR.PI",
	"numeraciones.5A+IA": "5AR.AP+IN.AP",
	"numeraciones.5A+IP": "5AR.AP+IN.PI",
	"numeraciones.5A.AP": "5AR.AP",
	"numeraciones.5A.PI": "5AR.PI",
	"numeraciones.5AP+I": "5AR.PI+IN.AP",
	"numeraciones.5AP+P": "5AR.PI+IN.PI",
	"numeraciones.5CC": "5CIRC.",
	"numeraciones.5D+IA": "5 DIAM + IND. APLI",
	"numeraciones.5DIAM": "5 DIAMANTES",
	"numeraciones.5IA": "5IN.AP",
	"numeraciones.5IP": "5IN.PI",
	"numeraciones.5R+IA": "5RO.AP+IN.AP",
	"numeraciones.5R+IP": "5RO.AP+IN.PI",
	"numeraciones.5R+RP": "5RO.AP+RO.PI",
	"numeraciones.5R.AP": "5RO.AP",
	"numeraciones.5R.PI": "5RO.PI",
	"numeraciones.5RP+I": "5RO.PI+IN.AP",
	"numeraciones.5RP+P": "5RO.PI+IN.PI",
	"numeraciones.6A+AP": "6AR.AP+AR.PI",
	"numeraciones.6A+CC": "6AR.AP+CIRC.",
	"numeraciones.6A+IA": "6AR.AP+IN.AP",
	"numeraciones.6A+IP": "6AR.AP+IN.PI",
	"numeraciones.6A.AP": "6AR.AP",
	"numeraciones.6A.PI": "6AR.PI",
	"numeraciones.6AP+I": "6AR.PI+IN.AP",
	"numeraciones.6AP+P": "6AR.PI+IN.PI.",
	"numeraciones.6CC.": "6CIRC.",
	"numeraciones.6IA": "6IN.AP",
	"numeraciones.6IP": "6IN.PI",
	"numeraciones.6R+IA": "6RO.AP+IN.AP",
	"numeraciones.6R+IP": "6RO.AP+IN.PI",
	"numeraciones.6R+RP": "6RO.AP+RO.PI",
	"numeraciones.6R.AP": "6RO.AP",
	"numeraciones.6R.P": "6RO.PIN.",
	"numeraciones.6RP+I": "6RO.PI+IN.AP",
	"numeraciones.6RP+P": "6RO.PI+IN.PI",
	"numeraciones.7A+IA": "7AR.AP+IN.AP",
	"numeraciones.7AR.P": "7AR.P",
	"numeraciones.7DIA": "7 DIAMANTES",
	"numeraciones.8A+IA": "8AR.AP+IN.AP",
	"numeraciones.8AP": "8ARA.PIN.",
	"numeraciones.8AP+P": "8ARA.PIN+IND.PIN.",
	"numeraciones.8R+IA": "8RO.AP+IN.AP",
	"numeraciones.8RP+P": "8RO.PI+IN.PI.",
	"numeraciones.9ARAB": "9 ÁRABES",
	"numeraciones.10ARA": "10 ÁRABES",
	"numeraciones.A+R.P": "ARA+ROM.PIN.",
	"numeraciones.AA+AP": "ARA PINT+ARA APLIC",
	"numeraciones.AP+AA": "ARA PINT + ARA APLIC",
	"numeraciones.AP+IA": "ARA.PIN+IND.A",
	"numeraciones.AP+IP": "AR.PI+IN.PI",
	"numeraciones.AR+DI": "AR.+ 10 DIAMANTES",
	"numeraciones.AR.AP": "ÁRABES APLICADOS",
	"numeraciones.AR.PI": "ÁRABES PINTADOS",
	"numeraciones.AR4/4": "ÁRABES 4/4 + INDICES",
	"numeraciones.ARA.A": "ÁRABES APLICADOS",
	"numeraciones.ARA.E": "ÁRABES ESTAMPADOS",
	"numeraciones.ARA.P": "ÁRABES PINTADOS",
	"numeraciones.ARAB": "ÁRABES",
	"numeraciones.ARABE": "ÁRABES 12/12",
	"numeraciones.BAND": "Banderas",
	"numeraciones.BOLAS": "BOLAS 2N18",
	"numeraciones.CIRC": "CIRCONITA",
	"numeraciones.CR": "CRISTAL SWAROVSKI",
	"numeraciones.DIAM": "12 DIAMANTES",
	"numeraciones.DIAMG": "DIAMANTES GRANDES",
	"numeraciones.DIAN": "DIGITAL ANALÓGICO",
	"numeraciones.DIAR": "DIGITAL ÁRABES",
	"numeraciones.DIG": "DIGITAL",
	"numeraciones.DIIN": "DIGITAL ÍNDICES",
	"numeraciones.FANT": "Fantasía",
	"numeraciones.FL": "FLORES",
	"numeraciones.IN+CC": "IND+CIRCONITAS",
	"numeraciones.IN.AP": "ÍNDICES APLICADOS",
	"numeraciones.IN.PI": "ÍNDICES PINTADOS",
	"numeraciones.INAR": "12INDIC+12ARAB",
	"numeraciones.IND.A": "ÍNDICES APLICADOS",
	"numeraciones.IND.E": "IND.EST.",
	"numeraciones.IND.P": "IND.PINTADOS",
	"numeraciones.INDIC": "Índices",
	"numeraciones.INFAN": "ÍNDICES FANTASÍA",
	"numeraciones.INPIN": "IND.PIN.",
	"numeraciones.INRO": "12 ÍNDICES+12 ROMANO",
	"numeraciones.IP+AP": "12IN.PI+12AR.PI",
	"numeraciones.NEO": "NO LLEVA",
	"numeraciones.RA+RP": "4ROM APLI+4ROM PIN",
	"numeraciones.RO+IN": "ROMANOS 12+10 ÍNDICE",
	"numeraciones.RO.AP": "ROMANOS APLICADOS",
	"numeraciones.RO.PI": "ROMANOS PINTADOS",
	"numeraciones.RO2/2": "ROMANOS 2/2 + ÍNDICE",
	"numeraciones.RO4/4": "ROMANOS 4/4+ÍNDICES",
	"numeraciones.ROES": "ROM.ESTAMPADOS",
	"numeraciones.ROM": "ROMANOS",
	"numeraciones.ROM.A": "ROMANOS APLIQUES",
	"numeraciones.ROM.E": "ROMANOS ESTAMPADOS",
	"numeraciones.ROM.P": "ROMANOS PINTADOS",
	"numeraciones.ROMAN": "12/12 ROMANOS",
	"numeraciones.RP+IA": "12ROM.PIN+12IND.A.",
	"numeraciones.RP+IP": "12RO.PI+12IN.PI",
	"piedras.tipo.agrupada.ABA": "ABALORIOS DIFERENTES FORMAS",
	"piedras.tipo.agrupada.ACBA": "BOLA DE ACERO",
	"piedras.tipo.agrupada.AGA": "ÁGATA",
	"piedras.tipo.agrupada.AGBA": "BOLA PLATA",
	"piedras.tipo.agrupada.AMA": "Amatista",
	"piedras.tipo.agrupada.AMAN": "AMANZONITA",
	"piedras.tipo.agrupada.ARCI": "ARO PLATA CIRCONITAS",
	"piedras.tipo.agrupada.ARGO": "ARGOLLA",
	"piedras.tipo.agrupada.AVEN": "AVENTURINA",
	"piedras.tipo.agrupada.BAEN": "BARRA CIRCONITAS ENGASTADAS",
	"piedras.tipo.agrupada.BAPA": "BARRA PAVE",
	"piedras.tipo.agrupada.BGSP": "BAGUES SIN PRECIO",
	"piedras.tipo.agrupada.BOL": "BOLA PLATA FACETADA",
	"piedras.tipo.agrupada.BRI": "BRILLANTE",
	"piedras.tipo.agrupada.BS": "BS",
	"piedras.tipo.agrupada.BUTT": "PERLA CULTIVADA BUTTON",
	"piedras.tipo.agrupada.CALC": "CALCEDONIA",
	"piedras.tipo.agrupada.CER": "Cerámica",
	"piedras.tipo.agrupada.CIR": "CIRCONITA AMARILLA (CZ-YE1)",
	"piedras.tipo.agrupada.CIRC": "CIRCONITAS ENGASTADAS",
	"piedras.tipo.agrupada.COR": "CORNALINA",
	"piedras.tipo.agrupada.CRBA": "CRYSTAL BALL",
	"piedras.tipo.agrupada.CRI": "CRISTAL",
	"piedras.tipo.agrupada.CRPA": "CRUZ PAVE",
	"piedras.tipo.agrupada.CUAR": "CUARZO",
	"piedras.tipo.agrupada.DIAM": "DIAMANTE",
	"piedras.tipo.agrupada.DISP": "0",
	"piedras.tipo.agrupada.ESMA": "ESMALTE",
	"piedras.tipo.agrupada.ESP": "ESPINELA",
	"piedras.tipo.agrupada.FERI": "FERIDO",
	"piedras.tipo.agrupada.G030": "FO1030",
	"piedras.tipo.agrupada.G031": "FO1031",
	"piedras.tipo.agrupada.G034": "BRILLANTES",
	"piedras.tipo.agrupada.G036": "FO1036",
	"piedras.tipo.agrupada.G050": "FO1050",
	"piedras.tipo.agrupada.GI10": "PIEDRA 0,90",
	"piedras.tipo.agrupada.GI11": "38640",
	"piedras.tipo.agrupada.GI12": "MOD FO282",
	"piedras.tipo.agrupada.GI13": "BISEL ENGASTADO SHOCKWAVE",
	"piedras.tipo.agrupada.GI14": "PIEDRAS 1,6DIAM",
	"piedras.tipo.agrupada.GI15": "PIEDRA 0,80",
	"piedras.tipo.agrupada.GIL": "DIAM ENGAS MIGUEL GIL 0.015K",
	"piedras.tipo.agrupada.GIL1": "PIEDRAS 1,20 + 1,50",
	"piedras.tipo.agrupada.GIL2": "FO436",
	"piedras.tipo.agrupada.GIL3": "DIAMANTE 1.10",
	"piedras.tipo.agrupada.GIL4": "PIEDRA MODELO LO314",
	"piedras.tipo.agrupada.GIL5": "PIEDRAS MODELO FO323",
	"piedras.tipo.agrupada.GIL6": "PIEDRAS DE 1,20",
	"piedras.tipo.agrupada.GIL7": "PIEDRAS 1,40",
	"piedras.tipo.agrupada.GIL8": "PIEDRAS 1,30",
	"piedras.tipo.agrupada.GIL9": "PIEDRA 1,00",
	"piedras.tipo.agrupada.GLAM": "GLAM",
	"piedras.tipo.agrupada.HEMA": "HEMATITE",
	"piedras.tipo.agrupada.JADE": "JADE",
	"piedras.tipo.agrupada.LABR": "LABRADORITA",
	"piedras.tipo.agrupada.LAPI": "LAPISLAZULI",
	"piedras.tipo.agrupada.LAVA": "BOLA DE LAVA",
	"piedras.tipo.agrupada.MP": "MADRE PERLA",
	"piedras.tipo.agrupada.NAC": "Nácar",
	"piedras.tipo.agrupada.OJGA": "OJO DE GATO",
	"piedras.tipo.agrupada.ONI": "ONIX",
	"piedras.tipo.agrupada.OPPE": "OPALO PERUANO",
	"piedras.tipo.agrupada.OTIG": "OJO DE TIGRE (GRANATE)",
	"piedras.tipo.agrupada.OTUR": "OJO TURCO",
	"piedras.tipo.agrupada.PABA": "PAVE BALL",
	"piedras.tipo.agrupada.PEBA": "PERLA BARROCA CULTIVADA",
	"piedras.tipo.agrupada.PECU": "PERLA CULTIVADA",
	"piedras.tipo.agrupada.PERL": "PERLA NATURAL",
	"piedras.tipo.agrupada.PESY": "PERLA SINTÉTICA",
	"piedras.tipo.agrupada.PILU": "PIEDRA LUNA",
	"piedras.tipo.agrupada.QURS": "CUARZO ROSA",
	"piedras.tipo.agrupada.RES": "BOLA RESINA",
	"piedras.tipo.agrupada.RESI": "RESINA",
	"piedras.tipo.agrupada.RORU": "RODIO RUTENIO",
	"piedras.tipo.agrupada.RUB": "RUBÍ",
	"piedras.tipo.agrupada.SWA": "SWAROVSKI",
	"piedras.tipo.agrupada.SZA1": "PIEDRA 1,50",
	"piedras.tipo.agrupada.SZA2": "PIEDRA 1,20",
	"piedras.tipo.agrupada.TUCI": "TUBO CIRCONITAS",
	"piedras.tipo.agrupada.TUR": "TURQUESA",
	"producto.coleccion": "Colección",
	"producto.tipo.accesorio": "accesorio",
	"producto.tipo.joya": "Joya",
	"producto.tipo.reloj": "Reloj",
	"tipo.movimiento.agrupado.01": "Digital",
	"tipo.movimiento.agrupado.02": "CUARZO DIGITAL",
	"tipo.movimiento.agrupado.03": "Manual",
	"tipo.movimiento.agrupado.04": "RESER-MARCHA",
	"tipo.movimiento.agrupado.05": "Híbridos",
	"tipo.movimiento.agrupado.06": "SMARTWATCH",
	"tipo.movimiento.agrupado.07": "CUARZO",
	"tipolente.POLARIZED": "Polarizado"
}
