{
  "acabado.AC-OR": "ACIER-OR",
  "acabado.B": "luminosité",
  "acabado.BP01": "BP01",
  "acabado.BP01C": "CERCLE DE COULEUR",
  "acabado.BSL": "BSL",
  "acabado.CAUCH": "Caoutchouc",
  "acabado.CORB": "Attacher",
  "acabado.CRISA": "CHRYSALIDE",
  "acabado.ESTAM": "Impression",
  "acabado.F+R": "PLAT + ROND",
  "acabado.F+S": "PLAT + DROIT",
  "acabado.F+S C": "PLAT + COLOR REC",
  "acabado.F+T": "PLAT + CRAVATE",
  "acabado.FAC06": "FAC06",
  "acabado.FR11": "FRAP 11",
  "acabado.FRA15": "FRAP15",
  "acabado.GRAB": "Enregistré",
  "acabado.LAPI": "LAPIDADO",
  "acabado.LASER": "ÊTRE",
  "acabado.M": "CAMARADE",
  "acabado.M+S": "MONTAGNE + DROITE",
  "acabado.M+S C": "COULEUR MONT.RECT",
  "acabado.M+T": "MONTAGNE + CRAVATE",
  "acabado.MAL": "Engrener",
  "acabado.MB": "MAT-BRILLANT",
  "acabado.MECA": "usinage",
  "acabado.MF5P": "MICROFUSION 5PC",
  "acabado.MMILA": "MESH MILANAIS",
  "acabado.MOUNT": "Montana",
  "acabado.MTCA": "MATE CALADA",
  "acabado.OYS": "huître",
  "acabado.PIR3": "PYRAMIDAL 3M",
  "acabado.PMP": "PULI-MATE-PULI",
  "acabado.PPM": "PANTER POLI MAT",
  "acabado.PPU": "PANTER POLI",
  "acabado.PRES": "Président",
  "acabado.PULID": "brillant",
  "acabado.RAS": "Rayé",
  "acabado.ROUND": "Rond",
  "acabado.S7M": "ESTAM 7 3MAT",
  "acabado.S7R": "STAMP 7PCS RAYURE",
  "acabado.S73M": "ESTAMP 7-3MATE",
  "acabado.ST3": "ESTAMPAGE 3PCS",
  "acabado.ST4": "ESTAMPAGE 4PCS",
  "acabado.ST5": "ESTAMPÉ 5PCS",
  "acabado.ST6": "ESTAMPÉ 6PCS",
  "acabado.ST7": "ESTAMPAGE 7 PIÈCES",
  "acabado.TPMP": "TUBE P-M-P",
  "acabado.TUB": "TUBE",
  "acabado.V620": "V620",
  "acabado.Y": "MONTAGNE POLIE",
  "acabado.YHLO": "MONTAGNE 1 / 2B + M",
  "accesorios.subtipo.BALLPOINT_PEN": "Stylo à bille",
  "accesorios.subtipo.BELT": "Ceinture",
  "accesorios.subtipo.CARD_HOLDER": "porte-cartes",
  "accesorios.subtipo.DOCUMENT_BAG": "Sac à documents",
  "accesorios.subtipo.DRESSING_CASE": "Boîte à pansements",
  "accesorios.subtipo.FOLDER": "dossier",
  "accesorios.subtipo.FOUNTAIN_PEN": "stylo à plume",
  "accesorios.subtipo.LAPTOP_BAG": "Sac pour ordinateur portable",
  "accesorios.subtipo.LEATHER_PEN_SET": "set stylos cuir",
  "accesorios.subtipo.OPTIC": "Lunettes optiques",
  "accesorios.subtipo.OPTIC_SOLAR_CLIP": "",
  "accesorios.subtipo.PEN": "Stylos bille",
  "accesorios.subtipo.REPLACEMENT": "Pièce de rechange",
  "accesorios.subtipo.REPORTER_BAG": "Sac à bandoulière",
  "accesorios.subtipo.ROLLERBALL_PEN": "Stylo à bille Roller",
  "accesorios.subtipo.SOLAR": "Lunettes de soleil",
  "accesorios.subtipo.WALLET": "portefeuilles",
  "accesorios.subtipo.WATCH_CASE": "Boîte de montre",
  "accesorios.subtipo.WRITING_CASE": "Cas d'écriture",
  "accesorios.subtipo.WRITING_SET": "set écriture",
  "accesorios.tipo.CLEANER": "nettoyeurs",
  "accesorios.tipo.EYEWEAR": "Lunettes",
  "accesorios.tipo.FRAGANCE": "parfums",
  "accesorios.tipo.JEWELLERY_BOX": "Bijoutiers",
  "accesorios.tipo.KEYCHAIN": "Porte-clés",
  "accesorios.tipo.LEATHER_ITEM": "maroquinerie",
  "accesorios.tipo.SET": "ensembles",
  "accesorios.tipo.SPARE_PARTS": "pièces détachées",
  "accesorios.tipo.TOOL": "outils",
  "accesorios.tipo.WRITING": "ecriture",
  "accesorios.tipo_boligrafo.BALLPOINT": "Ballpoint",
  "accesorios.tipo_boligrafo.ROLLERBALL": "Rollerball",
  "agrupacion.coleccion": "collection",
  "agrupacion.seleccion": "selection",
  "anchopuente.14": "14 mm",
  "anchopuente.15": "15 mm",
  "anchopuente.16": "16 mm",
  "anchopuente.17": "17 mm",
  "anchopuente.18": "18 mm",
  "anchopuente.21": "21 mm",
  "caracteristicas.alarma": "Alarme",
  "caracteristicas.balancier_visible": "Balancier visible",
  "caracteristicas.complicacion_automatico": "Complication Automatique",
  "caracteristicas.corona_rosca": "Couronne vissée",
  "caracteristicas.dual_time": "Double Fuseau Horaire",
  "caracteristicas.gmt": "GMT",
  "caracteristicas.luminova": "Luminova",
  "caracteristicas.luz": "Lumière",
  "caracteristicas.reserva_marcha": "Réserve de marche",
  "caracteristicas.skeleton": "Skeleton",
  "caracteristicas.solar": "Solaire",
  "caracteristicas.superluminova": "Superluminova",
  "caracteristicas.tourbillon": "Tourbillon",
  "cierre.agrupado.CAJ": "Déployante",
  "cierre.agrupado.CAP": "BOÎTE À BOUTONS",
  "cierre.agrupado.CCAT": "Brisure/Dormeuse",
  "cierre.agrupado.CCJ": "FERMETURE DU TIROIR",
  "cierre.agrupado.CGAN": "Crochet",
  "cierre.agrupado.CI": "BUTTERFLY",
  "cierre.agrupado.CIP": "De pression",
  "cierre.agrupado.CLIP": "Clips",
  "cierre.agrupado.CM": "MAGNÉTIQUE",
  "cierre.agrupado.COMEGA": "Omega",
  "cierre.agrupado.CPC": "CEINTURE BOX PULS",
  "cierre.agrupado.CPL": "Cote",
  "cierre.agrupado.CPR": "La pression",
  "cierre.agrupado.CSE": "Fermeture verrouillable",
  "cierre.agrupado.CSP": "BOUTON-POUSSOIR DE FERMETURE SÉCURISÉE",
  "cierre.agrupado.HEB": "Ardillon",
  "cierre.agrupado.HKSALO": "FERMETURE DE SÉCURITÉ",
  "cierre.agrupado.LI": "Pression du livre",
  "cierre.agrupado.MOS": "Mousqueton",
  "cierre.agrupado.OCL": "HIDDEN",
  "cierre.agrupado.OCP": "BOUTON-POUSSOIR CACHÉ",
  "cierre.agrupado.OCU": "Africains",
  "cierre.agrupado.ROS": "À vis",
  "cierre.agrupado.SO": "Double bouton poussoir",
  "cierre.agrupado.SOP": "RABAT À BOUTON",
  "cierre.CAJ": "Déployante",
  "cierre.CAP": "BOÎTE À BOUTONS",
  "cierre.CCJ": "FERMETURE DE TIROIR (STYLE)",
  "cierre.CI": "FERMETURE 1",
  "cierre.CIP": "De pression",
  "cierre.CM": "MAGNÉTIQUE",
  "cierre.CMS": "MAGNÉTIQUE AVEC ASSURANCE",
  "cierre.CPC": "BOÎTE DE PULS DE CEINTURE",
  "cierre.CPL": "BOÎTE PULS PLUS TARD.",
  "cierre.CPR": "La pression",
  "cierre.CSE": "Fermeture verrouillable",
  "cierre.CSP": "BOUTON-POUSSOIR DE FERMETURE SÉCURISÉE",
  "cierre.HEB": "Ardillon",
  "cierre.HKBB": "BOUCLE PAPILLON",
  "cierre.HKCB": "BOUCLE À CLIP",
  "cierre.HKCLB": "BOUCLE CLIP LOCK",
  "cierre.HKJEWBUK": "BOUCLE DE BIJOUX",
  "cierre.HKLB": "BOUCLE EN CUIR",
  "cierre.HKLOBUK": "Fermoir papillon",
  "cierre.HKMB": "BOUCLE MAGNÉTIQUE",
  "cierre.HKSALO": "FERMETURE DE SÉCURITÉ",
  "cierre.HKSCB": "BOUCLE C-CLIP PRINTEMPS",
  "cierre.HKT-BABUK": "BOUCLE EN T",
  "cierre.HOR": "BOUCLE D'OR",
  "cierre.LI": "Pression du livre",
  "cierre.MOS": "Mousqueton",
  "cierre.NEO": "NE PORTE PAS",
  "cierre.NOSE": "ON NE SAIT PAS",
  "cierre.OCH": "BOUCLE DE FERMETURE CACHÉE",
  "cierre.OCL": "Fermoir à boucle déployante",
  "cierre.OCP": "BOUTON-POUSSOIR CACHÉ",
  "cierre.OCS": "RABAT CACHÉ",
  "cierre.OCU": "Africains",
  "cierre.OPC": "POUSSOIR CACHÉ À SANGLE",
  "cierre.OSP": "IMPULSIONS HIDDEN FLAP",
  "cierre.ROS": "FERMETURE DE FILETAGE",
  "cierre.SO": "Double bouton poussoir",
  "cierre.SOC": "SANGLE À RABAT",
  "cierre.SOH": "BOUCLE À RABAT",
  "cierre.SOP": "RABAT À BOUTON",
  "cierre.SPC": "CEINTURE FLAP PULS",
  "cierre.undefined": "",
  "colores_base.1": "CRO",
  "colores_base.2N18": "2N18 DORÉ",
  "colores_base.2NBN": "CORPS NOIR CENTRE 2N18",
  "colores_base.2NMA": "2N18 AVEC LES MARQUES",
  "colores_base.2NTN": "2N18 AVEC FILET NOIR",
  "colores_base.3N": "OR 3N",
  "colores_base.3N18": "3N18",
  "colores_base.4N18": "OR ROSE CLAIR",
  "colores_base.5N": "OR ROSE 5N",
  "colores_base.5N18": "Or rose",
  "colores_base.026": "IVOIRE",
  "colores_base.032": "MARRON FONCÉ",
  "colores_base.037": "MARRON HAVANE",
  "colores_base.041": "OR MOYEN",
  "colores_base.061": "BLEU MOYEN",
  "colores_base.065": "Bleu clair",
  "colores_base.066": "Bleu clair",
  "colores_base.068": "AZUR",
  "colores_base.084": "CORAIL",
  "colores_base.100": "ACIER BRILLANT",
  "colores_base.128": "Nu",
  "colores_base.591": "PVD NOIR",
  "colores_base.600": "Or rose",
  "colores_base.840": "ACIER + OR",
  "colores_base.850": "ACIER + OR ROSE",
  "colores_base.ACCC": "ACIER ZIRCONE",
  "colores_base.AG": "Argent",
  "colores_base.AGAB": "AMBRE ARGENT",
  "colores_base.AGCR": "CRISTAL D'ARGENT",
  "colores_base.AGLA": "LAVANDE ARGENT",
  "colores_base.AGLM": "LIME ARGENTÉE",
  "colores_base.AGMC": "ARGENT MARQUÉ",
  "colores_base.AGMO": "VIOLET ARGENT",
  "colores_base.AGPE": "PERLE D'ARGENT",
  "colores_base.AGRO": "ARGENT ROUGE",
  "colores_base.AGTP": "TOPAZE ARGENTÉE",
  "colores_base.AGU": "BLEU VERT",
  "colores_base.AM": "Jaune",
  "colores_base.AMA": "Améthyste",
  "colores_base.AMBA": "ambre",
  "colores_base.AMBR": "JAUNE BRONZE",
  "colores_base.AMCC": "ZIRCONE JAUNE",
  "colores_base.AMMA": "MARRON JAUNE",
  "colores_base.AMPE": "PERLE JAUNE",
  "colores_base.AMT": "Améthyste",
  "colores_base.AQ": "BLEU VERT",
  "colores_base.AQBO": "AQUA BOHEMICA",
  "colores_base.AUR": "aube",
  "colores_base.AURB": "AURORE BORÉALE",
  "colores_base.AVAZ": "AVENTURINE BLEUE",
  "colores_base.AZ": "BLEU",
  "colores_base.AZRS": "BLEU ROSE",
  "colores_base.BDIA": "DIAMANT NOIR",
  "colores_base.BE": "Beige",
  "colores_base.BICO": "BICOLORE",
  "colores_base.BL": "Blanc",
  "colores_base.BLCO": "BLANC CORAIL",
  "colores_base.BLPE": "PERLE BLANCHE",
  "colores_base.BLRY": "BLANC RAYÉ",
  "colores_base.BR": "Bronze",
  "colores_base.BRCC": "ZIRCONS EN BRONZE",
  "colores_base.BRIL": "brillant",
  "colores_base.BRLI": "BRONZE LILAS",
  "colores_base.BRRS": "BRONZE ROSE",
  "colores_base.BU": "Bordeaux",
  "colores_base.BUCR": "CRÈME BOURGOGNE",
  "colores_base.CA": "CHAMEAU",
  "colores_base.CABA": "Cheval",
  "colores_base.CAL": "Traîne",
  "colores_base.CALD": "Chaudière",
  "colores_base.CCPE": "PERLE DE ZIRCONE",
  "colores_base.CER": "Cerise",
  "colores_base.CH": "Champagne",
  "colores_base.CHA": "Plaque",
  "colores_base.ChGR": "PLAQUÉ GRIS",
  "colores_base.CHOC": "chocolat",
  "colores_base.CIRC": "Zircone",
  "colores_base.CITR": "Citrine",
  "colores_base.CM": "crème",
  "colores_base.CMFU": "CRÈME FUCHSIA",
  "colores_base.CO": "Cuivre",
  "colores_base.CORA": "CORAIL",
  "colores_base.CQ": "KAKI",
  "colores_base.CR": "Chrome",
  "colores_base.CRDO": "CRISTAL DORÉ",
  "colores_base.CRIS": "Cristal",
  "colores_base.CRTR": "CRISTAL TRANSPARENT",
  "colores_base.CURR": "CURRO",
  "colores_base.CZ": "QUARTZ",
  "colores_base.CZAZ": "QUARTZ BLEU",
  "colores_base.CZMA": "QUARTZ MARRON",
  "colores_base.CZRS": "QUARTZ ROSE",
  "colores_base.DELF": "dauphin",
  "colores_base.DI": "Numérique",
  "colores_base.DIAM": "Diamants",
  "colores_base.DO": "D'or",
  "colores_base.DONE": "NOIR DORÉ",
  "colores_base.ESM": "émeraude",
  "colores_base.EXPO": "EXPO",
  "colores_base.FLOR": "Fleur",
  "colores_base.FLUO": "FLUORESCENT",
  "colores_base.FOCA": "Joint",
  "colores_base.FR": "fraise",
  "colores_base.FRAM": "framboise",
  "colores_base.FU": "Fuchsia",
  "colores_base.FUCC": "ZIRCONS FUCHSIA",
  "colores_base.FUME": "Fumée",
  "colores_base.GATO": "Chat",
  "colores_base.GN": "Grenat",
  "colores_base.GR": "Gris",
  "colores_base.GRA": "Grenat",
  "colores_base.GRAG": "Gris argent",
  "colores_base.GRPL": "GRIS ARGENTÉ",
  "colores_base.HA": "HAMILTON",
  "colores_base.HAB": "LA HAVANE",
  "colores_base.HAHA": "HAMILTON HAMILTON",
  "colores_base.HAMC": "MARQUÉ HAMILTON",
  "colores_base.HANA": "HAMILTON NACAR",
  "colores_base.HANC": "HAMILTON NACRE",
  "colores_base.HEM": "HÉMATITE",
  "colores_base.INDI": "INDICOLITE",
  "colores_base.JET": "Jet",
  "colores_base.KOAL": "KOALA",
  "colores_base.LI": "LILAS",
  "colores_base.LIOS": "LILAS FONCÉ",
  "colores_base.LIPE": "PERLE LILAS",
  "colores_base.LUMI": "SUPERLUMINOVA",
  "colores_base.LUN": "Lune",
  "colores_base.MA": "marron",
  "colores_base.MAAG": "MARRON / ARGENT",
  "colores_base.MAPE": "MARRON PERLE",
  "colores_base.MBLU": "BLEU MÉTALLIQUE",
  "colores_base.MC": "Marqué",
  "colores_base.ME": "pêche",
  "colores_base.MEN": "menthe",
  "colores_base.MF": "IVOIRE",
  "colores_base.MFNE": "IVOIRE-NOIR",
  "colores_base.MGRE": "VERT MÉTALLIQUE",
  "colores_base.MO": "Violet",
  "colores_base.MOBL": "BLANC VIOLET",
  "colores_base.MOS": "Moutarde",
  "colores_base.MS": "Moutarde",
  "colores_base.MSIL": "ARGENT MÉTALLIQUE",
  "colores_base.MT": "CAMARADE",
  "colores_base.MULT": "MULTICOLORE",
  "colores_base.MULTAM": "JAUNE MULTICOLORE",
  "colores_base.MULTAZ": "BLEU MULTICOLORE",
  "colores_base.MULTRS": "ROSE MULTICOLORE",
  "colores_base.NA": "NACRE",
  "colores_base.NAAZ": "BLEU NACAR",
  "colores_base.NE": "Noir",
  "colores_base.NEAM": "NOIR JAUNE",
  "colores_base.NECC": "ZIRCONS NOIRS",
  "colores_base.NEDO": "OR NOIR",
  "colores_base.NEGD": "DIAMANT NOIR",
  "colores_base.NEO": "NE PORTE PAS",
  "colores_base.NEPE": "PERLE NOIRE",
  "colores_base.NJ": "Orange",
  "colores_base.NJNC": "PERLE ORANGE",
  "colores_base.NYB": "NYLON BLANC",
  "colores_base.NYBE": "NYLON BEIG",
  "colores_base.NYBL": "NYLON BLANC",
  "colores_base.NYC": "CRÈME NYLON",
  "colores_base.NYCM": "CRÈME NYLON",
  "colores_base.NYG": "NYLON GRIS",
  "colores_base.NYGR": "NYLON GRIS",
  "colores_base.NYM": "MARRON NYLON",
  "colores_base.NYMA": "MARRON NYLON",
  "colores_base.NYN": "NYLON NOIR",
  "colores_base.NYNE": "NYLON NOIR",
  "colores_base.NYO": "OCRE NYLON",
  "colores_base.NYR": "NYLON ROUGE",
  "colores_base.OBSI": "OBSIDIENNE",
  "colores_base.OC": "OCRE",
  "colores_base.OCR": "OCRE",
  "colores_base.OLIV": "OLIVINA",
  "colores_base.ONIX": "ONYX",
  "colores_base.OR": "Or",
  "colores_base.ORAZ": "OR BLEU",
  "colores_base.PACH": "CHAMPAGNE BLEU",
  "colores_base.PAJA": "oiseau",
  "colores_base.PAV": "PAVONADO",
  "colores_base.PD": "Palladium",
  "colores_base.PER": "perle",
  "colores_base.PERN": "PERLE NOIRE",
  "colores_base.PERR": "Chien",
  "colores_base.PLAG": "ARGENT ARGENT",
  "colores_base.PLAM": "JAUNE ARGENT",
  "colores_base.PLAZ": "ARGENT BLEU",
  "colores_base.PLLI": "LILAS ARGENT",
  "colores_base.PLNE": "ARGENT / NOIR",
  "colores_base.PLPL": "ARGENT ARGENT",
  "colores_base.PLRS": "ARGENT ROSE",
  "colores_base.PLVE": "VERT ARGENT",
  "colores_base.PV": "PAVONADO",
  "colores_base.PVCH": "CHAMPAGNE BLEU",
  "colores_base.PVD": "PVD",
  "colores_base.RH": "Rhodium",
  "colores_base.RH5N": "OR BLANC + OR 5N",
  "colores_base.RO": "ROUGE",
  "colores_base.ROCA": "CALADA ROUGE",
  "colores_base.ROMS": "MOUTARDE ROUGE",
  "colores_base.RONE": "ROUGE NOIR",
  "colores_base.RS": "rose",
  "colores_base.RSCC": "ZIRCONE ROSE",
  "colores_base.RSPE": "ROSE PERLE",
  "colores_base.RSRO": "ROUGE ROSE",
  "colores_base.RU": "RUTHÉNIUM",
  "colores_base.RUBI": "Rubis",
  "colores_base.SASA": "SAUMON SAUMON",
  "colores_base.ST": "SEMITRANSPARENT",
  "colores_base.TA": "TIGRE BLEU",
  "colores_base.TEJA": "Tuile",
  "colores_base.TIT": "TITANE",
  "colores_base.TOP": "TOPAZIO",
  "colores_base.TP": "Topaze",
  "colores_base.TPCL": "TOPAZE LÉGÈRE",
  "colores_base.TR": "TIGRE ROSE",
  "colores_base.TRAN": "transparent",
  "colores_base.TRCO": "TRICOLORE",
  "colores_base.TRI": "TRICOLORE",
  "colores_base.TRNE": "NOIR TRANSP",
  "colores_base.TW/W": "TOP WESSELTON À WESSELTON",
  "colores_base.VA": "Vanille",
  "colores_base.VE": "VERT",
  "colores_base.VEBRI": "VERT BRILLANT",
  "colores_base.VECC": "ZIRCONE VERTE",
  "colores_base.VI": "VIOLETTE",
  "colores_base.VIDI": "VIOLETTE NUMÉRIQUE",
  "colores_base.VIGR": "GRIS VIOLET",
  "colores_base.VIN": "du vin",
  "colores_base.VIOS": "Violet foncé",
  "colores_base.ZAFI": "Saphir",
  "colores_catalogo.AG": "Argent",
  "colores_catalogo.AM": "Jaune",
  "colores_catalogo.AZ": "BLEU",
  "colores_catalogo.BE": "Beige",
  "colores_catalogo.BL": "Blanc",
  "colores_catalogo.BU": "Bordeaux",
  "colores_catalogo.CH": "Champagne",
  "colores_catalogo.CM": "crème",
  "colores_catalogo.CO": "Cuivre",
  "colores_catalogo.DO": "D'or",
  "colores_catalogo.GR": "Gris",
  "colores_catalogo.MA": "marron",
  "colores_catalogo.MULT": "MULTICOLORE",
  "colores_catalogo.NA": "NACRE",
  "colores_catalogo.NE": "Noir",
  "colores_catalogo.NJ": "Orange",
  "colores_catalogo.PL": "Argent",
  "colores_catalogo.PLA": "Argent",
  "colores_catalogo.PLAZ": "ARGENT BLEU",
  "colores_catalogo.PLRS": "ARGENT ROSE",
  "colores_catalogo.RO": "ROUGE",
  "colores_catalogo.ROSE": "rose",
  "colores_catalogo.RS": "rose",
  "colores_catalogo.VE": "VERT",
  "colores_catalogo.VI": "VIOLETTE",
  "correa.agrupada.ARMYS": "ARMYS",
  "correa.agrupada.CORREA": "Ceinture",
  "estanqueidad.1A": "1 ATM",
  "estanqueidad.3A": "3 ATM",
  "estanqueidad.5A": "5 ATM",
  "estanqueidad.10A": "10 ATM",
  "estanqueidad.20A": "20 ATM",
  "estanqueidad.?": "Aucune étanchéité",
  "estanqueidad.IP68": "IP68",
  "estanqueidad.NEO": "IL N'A PAS",
  "estanqueidad.R.P.": "RÉSISTANT À LA POUSSIÈRE",
  "estanqueidad.RP": "RÉSISTANT À LA POUSSIÈRE",
  "estanqueidad.SHPR": "Douche",
  "estanqueidad.W.P.": "CONTRE L'EAU.",
  "estanqueidad.W.R.": "RÉSISTANT À L'EAU",
  "estanqueidad.WR": "RÉSISTANT À L'EAU",
  "estilos.CA": "Décontractée",
  "estilos.CH": "élégant",
  "estilos.CL": "Classique",
  "estilos.EL": "élégance",
  "estilos.LE": "ÉDITION LIMITÉE",
  "estilos.SP": "Des sports",
  "estilos.VI": "Ancien",
  "formaCaja.C": "Carré",
  "formaCaja.CR": "croix",
  "formaCaja.DI": "Numérique",
  "formaCaja.IR": "IRRÉGULIER",
  "formaCaja.O": "ovale",
  "formaCaja.RC": "Rectangulaire",
  "formaCaja.RD": "Ronde",
  "formaCaja.TO": "baril",
  "formaCaja.TV": "Télévision",
  "funcion.agrupada.101": "Fonctions numériques",
  "funcion.agrupada.102": "Multifonction",
  "funcion.agrupada.103": "HEURES ET MINUTES",
  "funcion.agrupada.104": "Chronographe",
  "funcion.agrupada.105": "Caractéristiques intelligentes",
  "gamas.?": "",
  "gamas.agrupada.BE": "Bebe",
  "gamas.agrupada.C": "homme",
  "gamas.agrupada.IN": "Enfants",
  "gamas.agrupada.NA": "Fille",
  "gamas.agrupada.NI": "Garçon",
  "gamas.agrupada.S": "femme",
  "gamas.agrupada.UN": "Unisex",
  "gamas.AN": "anneaux",
  "gamas.BE": "Bebe",
  "gamas.C": "homme",
  "gamas.CD": "JUNIOR",
  "gamas.CO": "PENDENTIF",
  "gamas.IN": "JUNIOR",
  "gamas.NA": "JUNIOR",
  "gamas.NI": "JUNIOR",
  "gamas.PE": "femme",
  "gamas.PU": "BRACELETS",
  "joyas.tipo.agrupada.ANILLO": "Anneau",
  "joyas.tipo.agrupada.BROCHE": "Broche",
  "joyas.tipo.agrupada.CIERRE": "Fermoirs",
  "joyas.tipo.agrupada.COLGANTE": "PENDENTIF",
  "joyas.tipo.agrupada.COLLAR": "collier",
  "joyas.tipo.agrupada.CONJUNTO": "Ensemble",
  "joyas.tipo.agrupada.GEMELOS": "jumeaux",
  "joyas.tipo.agrupada.PENDIENTE_CADENA": "PENDENTIF EN CHAÎNE",
  "joyas.tipo.agrupada.PENDIENTE_TREPADOR": "CLIMBING SLOPE",
  "joyas.tipo.agrupada.PENDIENTES": "EN ATTENTE",
  "joyas.tipo.agrupada.PIERCING": "piercing",
  "joyas.tipo.agrupada.PULSERA": "bracelet",
  "joyas.tipo.agrupada.TOBILLERA": "SOUTIEN DE LA CHEVILLE",
  "joyas.tipo.ANILLO": "Anneau",
  "joyas.tipo.BROCHE": "Broche",
  "joyas.tipo.COLGANTE": "PENDENTIF",
  "joyas.tipo.COLLAR": "collier",
  "joyas.tipo.CONJUNTO": "Ensemble",
  "joyas.tipo.GEMELOS": "jumeaux",
  "joyas.tipo.PENDIENTE_CADENA": "PENDENTIF EN CHAÎNE",
  "joyas.tipo.PENDIENTE_TREPADOR": "CLIMBING SLOPE",
  "joyas.tipo.PENDIENTES": "EN ATTENTE",
  "joyas.tipo.PIERCING": "piercing",
  "joyas.tipo.PULSERA": "bracelet",
  "joyas.tipo.sub.1-1": "LISA",
  "joyas.tipo.sub.1-2": "AVEC DES PIERRES",
  "joyas.tipo.sub.1-3": "AVEC DES DIAMANTS",
  "joyas.tipo.sub.1-4": "LISA ALLIANCE",
  "joyas.tipo.sub.1-5": "ALLIANCE LAPIDADA",
  "joyas.tipo.sub.1-6": "ALLIANCE AVEC LA PIERRE",
  "joyas.tipo.sub.1-7": "Timbre",
  "joyas.tipo.sub.1-8": "IMPRIMÉ ÉMAIL",
  "joyas.tipo.sub.1-9": "ALLIANCE DIAMANT",
  "joyas.tipo.sub.1-10": "Impression",
  "joyas.tipo.sub.1-11": "Email",
  "joyas.tipo.sub.1-12": "Brouillon",
  "joyas.tipo.sub.1-13": "PIDRA CRISTAL",
  "joyas.tipo.sub.1-14": "SWAROVSKI",
  "joyas.tipo.sub.2-1": "Lisse",
  "joyas.tipo.sub.2-2": "AVEC DES PIERRES",
  "joyas.tipo.sub.2-3": "SCAPULAIRE",
  "joyas.tipo.sub.2-4": "CROIX LISSE",
  "joyas.tipo.sub.2-5": "FILLE VIERGE",
  "joyas.tipo.sub.2-6": "PARCHEMIN / ASSIETTE",
  "joyas.tipo.sub.2-7": "Petits anges",
  "joyas.tipo.sub.2-8": "CROIX AVEC DES PIERRES",
  "joyas.tipo.sub.2-9": "CROIX AVEC CHRIST",
  "joyas.tipo.sub.2-10": "FÉTICHE",
  "joyas.tipo.sub.2-11": "CROIX DE DIAMANTS",
  "joyas.tipo.sub.2-12": "AVEC DES DIAMANTS",
  "joyas.tipo.sub.2-13": "FÉTICHE AVEC PIERRES",
  "joyas.tipo.sub.2-14": "ÉLASTIQUE",
  "joyas.tipo.sub.2-15": "vierge",
  "joyas.tipo.sub.2-16": "CHRIST",
  "joyas.tipo.sub.2-17": "CRUZ CARAVACA",
  "joyas.tipo.sub.2-18": "Email",
  "joyas.tipo.sub.2-19": "COMMUNION",
  "joyas.tipo.sub.2-20": "Brouillon",
  "joyas.tipo.sub.2-21": "CRUZ CALADA",
  "joyas.tipo.sub.3-1": "CHATON",
  "joyas.tipo.sub.3-2": "Zircone",
  "joyas.tipo.sub.3-3": "PIERRE DE COULEUR",
  "joyas.tipo.sub.3-4": "Email",
  "joyas.tipo.sub.3-5": "ÉLECTROFORMAGE",
  "joyas.tipo.sub.3-6": "PERLES",
  "joyas.tipo.sub.3-7": "TOI ET MOI",
  "joyas.tipo.sub.3-8": "BAGUE",
  "joyas.tipo.sub.3-9": "Balle",
  "joyas.tipo.sub.3-10": "divers",
  "joyas.tipo.sub.3-11": "CÉRAMIQUE",
  "joyas.tipo.sub.3-12": "LONG AVEC DES PIERRES",
  "joyas.tipo.sub.3-13": "BAGUE",
  "joyas.tipo.sub.3-14": "Email",
  "joyas.tipo.sub.3-15": "AVEC DES DIAMANTS",
  "joyas.tipo.sub.3-16": "ÉLASTIQUE",
  "joyas.tipo.sub.3-17": "CERCEAU DE PIERRE",
  "joyas.tipo.sub.3-18": "Impression",
  "joyas.tipo.sub.3-19": "Brouillon",
  "joyas.tipo.sub.4-1": "LISA",
  "joyas.tipo.sub.4-2": "GRAVE",
  "joyas.tipo.sub.4-3": "Frisé",
  "joyas.tipo.sub.4-4": "BOUCLÉ AVEC DES PIERRES",
  "joyas.tipo.sub.4-5": "LISSE AVEC DES PIERRES",
  "joyas.tipo.sub.4-6": "LISSE AVEC DES DIAMANTS",
  "joyas.tipo.sub.4-7": "Email",
  "joyas.tipo.sub.4-8": "AVEC PEAU",
  "joyas.tipo.sub.5-1": "1ère POSITION AVEC PLAQUE",
  "joyas.tipo.sub.5-2": "bien",
  "joyas.tipo.sub.5-3": "Brut",
  "joyas.tipo.sub.5-4": "SOLIDE ÉPAIS AVEC FEUILLE",
  "joyas.tipo.sub.5-5": "SOLIDE ÉPAISSE",
  "joyas.tipo.sub.5-6": "ÉPAISSEUR CREUX AVEC DES PERLES",
  "joyas.tipo.sub.5-7": "ÉPAISSEUR CREUX",
  "joyas.tipo.sub.5-8": "ÉPAISSEUR AVEC DES PIERRES",
  "joyas.tipo.sub.5-9": "rivière",
  "joyas.tipo.sub.5-10": "ÉPAISSEUR CREUX AVEC FEUILLE",
  "joyas.tipo.sub.5-11": "FINE CREUX AVEC PLAQUE",
  "joyas.tipo.sub.5-12": "AVEC PIERRE",
  "joyas.tipo.sub.5-13": "Des balles",
  "joyas.tipo.sub.5-14": "BOULE AVEC PIERRE",
  "joyas.tipo.sub.5-15": "1ère POSITION",
  "joyas.tipo.sub.5-16": "FINE CREUX",
  "joyas.tipo.sub.5-17": "FIN SOLIDE AVEC FEUILLE",
  "joyas.tipo.sub.5-18": "ÉLASTIQUE",
  "joyas.tipo.sub.5-19": "AVEC DES PERLES",
  "joyas.tipo.sub.5-20": "BRACELET EN CUIR",
  "joyas.tipo.sub.5-21": "AVEC DES DIAMANTS",
  "joyas.tipo.sub.5-22": "BRACELET EN CUIR DIAMANT",
  "joyas.tipo.sub.5-23": "AVEC PERLES",
  "joyas.tipo.sub.5-24": "BRACELET EN CAOUTCHOUC",
  "joyas.tipo.sub.6-1": "FINE CREUX",
  "joyas.tipo.sub.6-2": "ÉPAISSEUR CREUX",
  "joyas.tipo.sub.6-3": "FIN SOLIDE",
  "joyas.tipo.sub.6-4": "SOLIDE ÉPAISSE",
  "joyas.tipo.sub.6-5": "CAOUTCHOUC AVEC FERMETURE",
  "joyas.tipo.sub.6-6": "CUIR AVEC ARGENT",
  "joyas.tipo.sub.6-7": "CUIR AVEC FERMETURE OR",
  "joyas.tipo.sub.6-8": "LAPIDADO CREUX",
  "joyas.tipo.sub.6-9": "PLATEAU DE FERMETURE EN NYLON",
  "joyas.tipo.sub.7-1": "LAPIDADO CREUX",
  "joyas.tipo.sub.7-2": "FIN AVEC PIERRE",
  "joyas.tipo.sub.7-3": "FINA AVEC PENDENTIF",
  "joyas.tipo.sub.7-4": "anneaux",
  "joyas.tipo.sub.7-5": "PENDENTIF EN PIERRE FIN",
  "joyas.tipo.sub.7-6": "ÉPAIS AVEC PENDENTIF",
  "joyas.tipo.sub.7-7": "bien",
  "joyas.tipo.sub.7-8": "Des balles",
  "joyas.tipo.sub.7-9": "FINA AVEC PENDENTIF EN ÉMAIL",
  "joyas.tipo.sub.7-10": "ÉPAISSEUR AVEC DES PIERRES",
  "joyas.tipo.sub.7-11": "ÉPAISSEUR CREUSE",
  "joyas.tipo.sub.7-12": "FINE CREUX",
  "joyas.tipo.sub.7-13": "CAOUTCHOUC AVEC PENDENTIF",
  "joyas.tipo.sub.7-14": "PENDENTIF FINA DIAMANT",
  "joyas.tipo.sub.7-15": "ÉLASTIQUE",
  "joyas.tipo.sub.7-16": "ÉPAISSEUR DE DIAMANTS",
  "joyas.tipo.sub.7-17": "CORDON EN CUIR",
  "joyas.tipo.sub.7-18": "CORDON AVEC PENDENTIF",
  "joyas.tipo.sub.7-19": "AVEC DES PERLES",
  "joyas.tipo.sub.8-1": "1ère POSITION",
  "joyas.tipo.sub.8-2": "Attacher",
  "joyas.tipo.sub.9-1": "FIN LISSE",
  "joyas.tipo.sub.9-2": "ÉPAISSE LISSE",
  "joyas.tipo.sub.9-3": "FIN AVEC DES PIERRES",
  "joyas.tipo.sub.9-4": "ÉPAISSEUR AVEC DES PIERRES",
  "joyas.tipo.sub.9-5": "FINE AVEC DES DIAMANTS",
  "joyas.tipo.sub.9-6": "ÉPAISSEUR DE DIAMANTS",
  "joyas.tipo.sub.9-7": "Des balles",
  "joyas.tipo.sub.9-8": "rivière",
  "joyas.tipo.sub.10-1": "Lisse",
  "joyas.tipo.sub.10-2": "AVEC DES PIERRES",
  "joyas.tipo.sub.10-3": "PERLES",
  "joyas.tipo.sub.11-1": "Lisse",
  "joyas.tipo.sub.11-2": "AVEC DES PIERRES",
  "joyas.tipo.sub.12-1": "AVEC DES DIAMANTS",
  "joyas.tipo.sub.12-2": "Lisse",
  "joyas.tipo.sub.13-1": "Lisse",
  "joyas.tipo.sub.13-2": "AVEC DES PIERRES",
  "joyas.tipo.sub.13-3": "AVEC DES DIAMANTS",
  "joyas.tipo.sub.14-1": "La pression",
  "joyas.tipo.sub.14-2": "Fil",
  "joyas.tipo.sub.15-1": "AVEC DES PERLES",
  "joyas.tipo.sub.17-1": "PAIRE DE BOULONS",
  "joyas.tipo.sub.25-1": "CLIC FACILE",
  "joyas.tipo.sub.99": "SANS DEFINIR",
  "joyas.tipo.sub.900-1": "9K JAUNE",
  "joyas.tipo.sub.900-2": "9K BLANC",
  "joyas.tipo.sub.900-3": "9K BICOLORE",
  "joyas.tipo.sub.undefined": "",
  "joyas.tipo.TOBILLERA": "SOUTIEN DE LA CHEVILLE",
  "kilataje.ORO9K": "OR 9K",
  "kilataje.ORO18K": "OR 18K",
  "longpatillas.140": "140 mm",
  "longpatillas.142": "142 mm",
  "longpatillas.145": "145 mm",
  "material.9K": "375 ml d'or 9 carats",
  "material.18K": "OR 18K",
  "material.24K": "OR FIN",
  "material.?": "?",
  "material.AC": "acier",
  "material.AC-EB": "ACIER-EBANE",
  "material.ACAC": "ACIER ET ACIER",
  "material.ACBI": "ACIER-BICOLORE",
  "material.ACBR": "ACIER BRILLANT",
  "material.ACCA": "ACIER ET CAOUTCHOUC",
  "material.ACCC": "ACIER ZIRCONE",
  "material.ACCE": "ACIER CÉRAMIQUE",
  "material.ACCH": "ACIER PLAQUÉ",
  "material.ACDI": "DIAMANTS EN ACIER",
  "material.ACE": "Acier inoxydable 316l",
  "material.ACERO": "Acier inoxydable",
  "material.ACETA": "Acétate",
  "material.ACFC": "FIBRE DE CARBONE",
  "material.ACHB": "ACIER PLAQUÉ BICOLORE",
  "material.ACHO": "ACIER PLAQUÉ OR",
  "material.ACMA": "ACIER ET BOIS",
  "material.ACOR": "ACIER ET OR",
  "material.ACPI": "ACIER ET CUIR",
  "material.ACPL": "ACIER ET PLASTIQUE",
  "material.ACTI": "ACIER TITANE",
  "material.AGCI": "ARGENT-ZIRCONE",
  "material.AGES": "ARGENT ÉMAILLÉ",
  "material.AGG": "Argent",
  "material.AGPE": "ARGENT ET PERLES",
  "material.agrupado.ACERO": "acier",
  "material.agrupado.ACETA": "ACÉTATE",
  "material.agrupado.CAUCHO": "Caoutchouc",
  "material.agrupado.CERAMICA": "CÉRAMIQUE",
  "material.agrupado.CUERO": "cuir",
  "material.agrupado.GOMA": "GOMME",
  "material.agrupado.METAL": "MÉTAL",
  "material.agrupado.ORO": "Or",
  "material.agrupado.ORO9K": "OR9K",
  "material.agrupado.ORO18K": "OR18K",
  "material.agrupado.PLATA": "Argent",
  "material.agrupado.TITANIO": "TITANE",
  "material.AGS": "SWAROVSKI ARGENT",
  "material.ALG": "COTON",
  "material.ALUM": "Aluminium",
  "material.ALZ": "Alliage",
  "material.ANTE": "Avant",
  "material.BELC": "BELCRO",
  "material.BICO": "BICOLORE",
  "material.BRO": "Bronze",
  "material.C18K": "FERMETURE CAOUTCHOUC + OR",
  "material.CAAC": "ACIER CAOUTCHOUC",
  "material.CAU": "Caoutchouc",
  "material.CAUCHO": "Caoutchouc",
  "material.CEBR": "CÉRAMIQUE BRILLANT",
  "material.CER": "CÉRAMIQUE",
  "material.CERAMICA": "Céramique",
  "material.CHA": "Mixte",
  "material.CHAP": "Plaque",
  "material.CHBI": "BICOLORE PLAQUÉ",
  "material.CHOR": "PLAQUÉ OR",
  "material.COCO": "PEAU DE CROCODILE",
  "material.COCT": "COUPE COCO",
  "material.CRBI": "CHROME BICOLORE",
  "material.CRMO": "Chrome",
  "material.CRO": "Chrome",
  "material.CU": "Corde",
  "material.CUERO": "cuir",
  "material.FIB": "Fibre",
  "material.GO": "GOMME",
  "material.GOCA": "CAOUTCHOUC CAOUTCHOUC",
  "material.GOM": "GOMME",
  "material.HNAU": "FIL NAUTIQUE",
  "material.LACH": "LAITON PLAQUÉ",
  "material.LACR": "LAITON CHROMÉ",
  "material.LACT": "COUPER LE LÉZARD",
  "material.LAG": "LÉZARD",
  "material.LAT": "Alliage",
  "material.LCHB": "LAITON BICOLORE",
  "material.LIQ": "Liquide",
  "material.METAL": "MÉTAL",
  "material.MIL": "MAGNIFICATEUR MINÉRAL",
  "material.MIN": "minéral",
  "material.MINP": "MINÉRAL PLAT",
  "material.MINT": "MINÉRAL TEINTÉ",
  "material.NEO": "NÉOPRÈNE",
  "material.NOB": "NOBUCK",
  "material.NY": "NYLON",
  "material.NYBE": "NYLON BELCRO",
  "material.NYCA": "CAOUTCHOUC NYLON",
  "material.NYCT": "COUPE NYLON",
  "material.NYL": "NYLON",
  "material.NYPC": "TIGE EN NYLON-CUIR",
  "material.NYPI": "CUIR À BASE DE NYLON",
  "material.O18": "OR 18K",
  "material.OASP": "OR 18K SANS PRIX",
  "material.OB5N": "OR BLANC + OR 5N",
  "material.OBSP": "OR BLANC SANS PRIX",
  "material.OR18": "OR 18 KTES.",
  "material.OR24": "OR 24 KTES.",
  "material.ORO": "Or",
  "material.OROB": "Or Blanc 18k",
  "material.P+O": "CUIR + FERMETURE OR",
  "material.P-CC": "CUIR C / CUT (7.5X4.2)",
  "material.PABA": "PAVE BALL",
  "material.PALD": "Palladium",
  "material.PAST": "Pâtes",
  "material.PC": "PLASTIQUE + FIBRE DE VERRE",
  "material.PEL": "MAGNIFICATEUR PLEX",
  "material.PI": "Peau",
  "material.PI-C": "CUIR C / CUT (7.5X3.8)",
  "material.PICA": "CUIR CAOUTCHOUC",
  "material.PICH": "CUIR VERNI",
  "material.PICT": "CUIR COUPE",
  "material.PIEL": "cuir",
  "material.PIEL-PU": "CUIR-POLYURÉTHANE",
  "material.PIGR": "CUIR GRAVÉ",
  "material.PILI": "PEAU DOUCE",
  "material.PINA": "CUIR NAYLON",
  "material.PINY": "CUIR NYLON",
  "material.PIPL": "PEAU PLASTIFIÉE",
  "material.PIRA": "CUIR RAYÉ",
  "material.PISI": "PEAU SYNTHÉTIQUE",
  "material.PISIN": "PEAU SYNTHÉTIQUE",
  "material.PL": "PLEX",
  "material.PLA": "Plastique",
  "material.PLAA": "BAGUE EN PLASTIQUE",
  "material.PLAL": "MAGNIFICATEUR EN PLASTIQUE",
  "material.PLAS": "Plastique",
  "material.PLAT": "Argent 925 ml",
  "material.PLATA": "Argent",
  "material.PLEX": "PLASTIQUE PLEX",
  "material.PLNY": "NYLON PLASTIQUE",
  "material.PLPI": "PLASTIQUE DE BASE EN CUIR",
  "material.PLPL": "PLPL",
  "material.PLXL": "PLAST + MAGNIFIER",
  "material.POLICARBONATO": "POLYCARBONATE",
  "material.PU": "POLYURÉTHANE",
  "material.PUPI": "BASE EN CUIR PURPURINE",
  "material.PVC": "PVC",
  "material.RAYA": "CUIR RAYÉ",
  "material.RH": "Rhodium",
  "material.RU": "RUTHÉNIUM",
  "material.SERP": "serpent",
  "material.SPBP": "BASE EN CUIR SYNTHÉTIQUE",
  "material.STBP": "BASE EN CUIR SYNTHÉTIQUE",
  "material.SWA": "SWAROVSKI",
  "material.TELA": "Tissu",
  "material.TEPI": "TISSU DE BASE EN CUIR",
  "material.TIBI": "BICOLORE TITANE",
  "material.TIBU": "PEAU DE REQUIN",
  "material.TIT": "TITANE",
  "material.TITANIO": "Titane",
  "material.TORT": "PEAU DE TORTUE",
  "material.TUNG": "TUNGSTÈNE",
  "material.undefined": "",
  "material.Z+L": "SAPHIR + MAGNIFICATEUR",
  "material.Z-G": "ZAF + MAGNIFIER + GRAVURE",
  "material.ZAF": "Saphir",
  "material.ZAFP": "SAPHIR PEINT",
  "movimiento.funcion.1": "SIMPLE (CAL)",
  "movimiento.funcion.4": "CHRONO (PAS DE CAL)",
  "movimiento.funcion.12": "RÉSERVER-DÉMARRER",
  "movimiento.funcion.101": "Numérique",
  "movimiento.funcion.102": "Multifonctions",
  "movimiento.funcion.103": "QUARTZ",
  "movimiento.funcion.104": "Chronographe",
  "movimiento.funcion.105": "Numérique",
  "movimiento.funcion.106": "Numérique",
  "movimiento.funcion.107": "ALARME ANALOGIQUE",
  "movimiento.funcion.108": "Multifonctions",
  "movimiento.funcion.109": "DOUBLE TEMPS",
  "movimiento.funcion.110": "Automatique",
  "movimiento.funcion.111": "Tourbillon",
  "movimiento.funcion.112": "GMT",
  "movimiento.funcion.113": "RÉSERVER-DÉMARRER",
  "movimiento.funcion.agrupada.ANALO": "Analogique",
  "movimiento.funcion.agrupada.AUTO": "Automatiques",
  "movimiento.funcion.agrupada.CRONO": "Chronographe",
  "movimiento.funcion.agrupada.DIGI": "Numérique",
  "movimiento.funcion.agrupada.HYBRID": "Hybrides",
  "movimiento.funcion.agrupada.MULTI": "Multifonction",
  "movimiento.funcion.agrupada.SMART": "smartwatch",
  "movimiento.funcion.ANA-AL": "ALARME ANALOGIQUE",
  "movimiento.funcion.ANA-DI": "ANALOGIQUE NUMÉRIQUE",
  "movimiento.funcion.ANA-DIGI": "ANALOGIQUE NUMÉRIQUE",
  "movimiento.funcion.AUT-AL": "ALARME AUTOMATIQUE",
  "movimiento.funcion.AUTO": "Automatiques",
  "movimiento.funcion.CRO-SUI": "CHRONOGRAPHE SUISSE",
  "movimiento.funcion.CRO.SOL": "CHRONO SOLAIRE",
  "movimiento.funcion.CRONO": "Chronographe",
  "movimiento.funcion.CRONO-AL": "CHRONO-ALARME",
  "movimiento.funcion.DI-MULTI": "MULTIFUCION NUMÉRIQUE",
  "movimiento.funcion.DIGI": "Numérique",
  "movimiento.funcion.DUAL-TIM": "DOUBLE TEMPS",
  "movimiento.funcion.HYBRID": "Hybrides",
  "movimiento.funcion.MULTI": "Multifonction",
  "movimiento.funcion.QUTZ-ANA": "QUARTZ / ANALOGIQUE",
  "movimiento.funcion.SI": "Facile",
  "movimiento.funcion.SIM": "Facile",
  "movimiento.funcion.SIM-SUI": "SUISSE SIMPLE",
  "movimiento.funcion.SMART": "smart watch",
  "movimiento.tipo.01": "Numérique",
  "movimiento.tipo.1": "SIMPLE (CAL)",
  "movimiento.tipo.02": "ANALOGIQUE NUMÉRIQUE",
  "movimiento.tipo.03": "QUARTZ / ANALOGIQUE",
  "movimiento.tipo.04": "Automatique",
  "movimiento.tipo.05": "HIBRID",
  "movimiento.tipo.06": "MONTRE INTELLIGENTE",
  "movimiento.tipo.07": "solaire",
  "movimiento.tipo.7": "MULTIF (CAL)",
  "movimiento.tipo.12": "RÉSERVER-DÉMARRER",
  "movimiento.tipo.ANA-AL": "ALARME ANALOGIQUE",
  "movimiento.tipo.ANA-DI": "ANALOGIQUE NUMÉRIQUE",
  "movimiento.tipo.ANA-DIGI": "ANALOGIQUE NUMÉRIQUE",
  "movimiento.tipo.AUT-AL": "ALARME AUTOMATIQUE",
  "movimiento.tipo.AUTO": "Automatique",
  "movimiento.tipo.AUTO-CRO": "CHRONOGRAPHE AUTOMATIQUE",
  "movimiento.tipo.AUTOM": "Automatique",
  "movimiento.tipo.C": "Corde",
  "movimiento.tipo.CRO-SUI": "CHRONOGRAPHE SUISSE",
  "movimiento.tipo.CRONO": "Chronographe",
  "movimiento.tipo.CUER-MEC": "CORDE / MÉCANIQUE",
  "movimiento.tipo.CUER.": "Corde",
  "movimiento.tipo.D": "Numérique",
  "movimiento.tipo.DI": "Numérique",
  "movimiento.tipo.DIGI": "Numérique",
  "movimiento.tipo.DUAL-TIM": "DOUBLE TEMPS",
  "movimiento.tipo.F": "solaire",
  "movimiento.tipo.HIBRID": "HIBRID",
  "movimiento.tipo.ME.CRO.": "MECA.CRONO",
  "movimiento.tipo.ME.SIM.": "MECA SIMPLE",
  "movimiento.tipo.MULTI": "Multifonction",
  "movimiento.tipo.MULTI.CR": "MULTI.CHRONO",
  "movimiento.tipo.PROF.": "JAUGE DE PROFONDEUR",
  "movimiento.tipo.QUTZ-ANA": "QUARTZ / ANALOGIQUE",
  "movimiento.tipo.SIM": "Facile",
  "movimiento.tipo.SIM-SUI": "SUISSE SIMPLE",
  "movimiento.tipo.SMART": "MONTRE INTELLIGENTE",
  "movimiento.tipo.TOURBILLON": "TOURBILLON",
  "numeraciones.1A+AP": "1AR.AP + AR.PI",
  "numeraciones.1A+CC": "1AR.AP + CRIRC",
  "numeraciones.1A+IA": "1AR.AP + IN.AP",
  "numeraciones.1A+IP": "1AR.AP + IN.PI",
  "numeraciones.1A.AP": "1AR.AP",
  "numeraciones.1A.PI": "1AR.PI",
  "numeraciones.1AA": "1AR.AP",
  "numeraciones.1AP+I": "1AR.PI + IN.AP",
  "numeraciones.1AP+P": "1AR.PI + IN.PI",
  "numeraciones.1BR+I": "1BRILL + IN.AP",
  "numeraciones.1CC": "1CIRC",
  "numeraciones.1CC+I": "1CIRCO + IN.AP",
  "numeraciones.1CC+IA": "1CIRC + IN.AP",
  "numeraciones.1CIR+I": "1CIRCO + IN.AP.",
  "numeraciones.1D+IN": "1DIAM + IND APLI",
  "numeraciones.1I.AP": "1IN.AP",
  "numeraciones.1IA": "1IN.AP",
  "numeraciones.1IP": "1IN.PI",
  "numeraciones.1R+IA": "1RO.AP + IN.AP",
  "numeraciones.1R+IP": "1RO.AP + IN.PI",
  "numeraciones.1R+RP": "1RO.AP + RO.PI",
  "numeraciones.1R.AP": "1RO.AP",
  "numeraciones.1R.P": "1RO.PI",
  "numeraciones.1R.PI": "1RO.PI",
  "numeraciones.1RA": "1RO.AP.",
  "numeraciones.1RA+P": "1R.APL + RO.PIN.",
  "numeraciones.1RP+I": "1RO.PI + IN.AP",
  "numeraciones.1RP+P": "1RO.PI + IN.PI",
  "numeraciones.2A+AP": "2AR.AP + AR.PI",
  "numeraciones.2A+CC": "2AR.AP + CIRC",
  "numeraciones.2A+IA": "2AR.AP + IN.AP",
  "numeraciones.2A+IP": "2AR.AP + IN.PI",
  "numeraciones.2A.AP": "2AR.AP",
  "numeraciones.2A.PI": "2AR.PI",
  "numeraciones.2AA+2RA": "2AR.AP + 2RO.AP",
  "numeraciones.2AP+2RP": "2AR.PI + 2RO.PI",
  "numeraciones.2AP+ARP": "2AR.PI + 2RO.PI",
  "numeraciones.2AP+I": "2AR.PI + IN.AP",
  "numeraciones.2AP+P": "2AR.PI + IN.PI",
  "numeraciones.2CC": "2CIRC.",
  "numeraciones.2CC+I": "2CIRC + IN.AP",
  "numeraciones.2CC+IA": "2CIRC + IN.AP",
  "numeraciones.2I+RP": "2IN.AP + 10RO.PI",
  "numeraciones.2I.AP": "2IN.AP",
  "numeraciones.2IA": "2IN.AP",
  "numeraciones.2IA+P": "2IN.AP + IN.PI.",
  "numeraciones.2IP": "2IN.PI",
  "numeraciones.2R+CC": "2RO.AP + CIRC",
  "numeraciones.2R+IA": "2RO.AP + IN.AP",
  "numeraciones.2R+IP": "2RO.AP + IN.PI",
  "numeraciones.2R+RP": "2RO.AP + RO.PI",
  "numeraciones.2R.AP": "2RO.AP",
  "numeraciones.2R.P": "2RO.PI.",
  "numeraciones.2R.PI": "2RO.PI",
  "numeraciones.2RA": "2ROM.APL.",
  "numeraciones.2RP+C": "2RO.PI + CIRC",
  "numeraciones.2RP+I": "2RO.PI + IN.AP",
  "numeraciones.2RP+P": "2RO.PI + IN.PI",
  "numeraciones.3A+AP": "3AR.AP + AR.PI",
  "numeraciones.3A+IA": "3AR.AP + IN.AP",
  "numeraciones.3A+IP": "3AR.AP + IN.PI",
  "numeraciones.3A.AP": "3AR.AP",
  "numeraciones.3A.PI": "3AR.PI",
  "numeraciones.3AA+P": "3ARA.A + 8ARA.PIN.",
  "numeraciones.3AP+I": "3AR.PI + IN.AP.",
  "numeraciones.3AP+P": "3AR.PI + IN.PI",
  "numeraciones.3CC": "3CIRC.",
  "numeraciones.3CC+IA": "3CIRC + IN.AP",
  "numeraciones.3I+RP": "3IN.AP + RO.PI",
  "numeraciones.3I.AP": "3IN.AP",
  "numeraciones.3IA": "3IN.AP",
  "numeraciones.3IP": "3IN.PI",
  "numeraciones.3R+CC": "3ROM + ZIRCONS",
  "numeraciones.3R+IA": "3RO.AP + IN.AP",
  "numeraciones.3R+IP": "3RO.AP + IN.PI",
  "numeraciones.3R+IR": "3/4 ROMAINS + IND.APL",
  "numeraciones.3R+RP": "3RO.AP + RO.PI",
  "numeraciones.3R.AP": "3RO.AP",
  "numeraciones.3R.P": "3RO.PI.",
  "numeraciones.3R.PI": "3RO.PI",
  "numeraciones.3RA+CC": "3RA.AP + CC",
  "numeraciones.3RA+P": "3RO.AP + 8RO.PI.",
  "numeraciones.3RE+E": "3ROM.EST + IND.ESTAMP.",
  "numeraciones.3RP+I": "3RO.PI + IN.AP",
  "numeraciones.3RP+P": "3RO.PI + IN.PI",
  "numeraciones.4A+4A": "4AR.PI + 4A.PI",
  "numeraciones.4A+4AP": "4AR.AP + 4AR.P",
  "numeraciones.4A+4R": "4AR + IA / 4R + IA.",
  "numeraciones.4A+AP": "4AR.AP + AR.PI",
  "numeraciones.4A+CC": "4ARA.AP + CIRC",
  "numeraciones.4A+IA": "4AR.AP + IN.AP",
  "numeraciones.4A+IP": "4AR.AP + IN.PI",
  "numeraciones.4A+P": "4ARP + IND.PIN.",
  "numeraciones.4A.AP": "4AR.AP",
  "numeraciones.4A.PI": "4AR.PI",
  "numeraciones.4AA+6": "4AR.A + 6AR.P",
  "numeraciones.4AA+P": "4ARA.A + 8ARA.PIN.",
  "numeraciones.4AP+I": "4AR.PI + IN.AP",
  "numeraciones.4AP+P": "4AR.PI + IN.PI",
  "numeraciones.4AR.P": "4ARA.PINT + IND.PIN.",
  "numeraciones.4CC": "4CIRC.",
  "numeraciones.4DIA": "4 DIAMANTS",
  "numeraciones.4I+CC": "4IN.AP + CIRC",
  "numeraciones.4IA": "4IN.AP",
  "numeraciones.4IA+P": "4IN.AP + IN.PI",
  "numeraciones.4IN.A": "4 INDICES APPLIQUÉS",
  "numeraciones.4INDS": "4 INDICES",
  "numeraciones.4IP": "4IN.PI",
  "numeraciones.4R+CC": "4 ROM. + CIRC.",
  "numeraciones.4R+DI": "/4ROM.APLIC.+8DIAM",
  "numeraciones.4R+IA": "4RO.AP + IN.AP",
  "numeraciones.4R+IP": "4RO.AP + IN.PI",
  "numeraciones.4R+RP": "4RO.AP + RO.PI",
  "numeraciones.4R.AP": "4RO.AP",
  "numeraciones.4R.P": "4RO.PI.",
  "numeraciones.4R.PI": "4RO.PI",
  "numeraciones.4RA+I": "4 / ROMAN.APL. + IND.APL",
  "numeraciones.4RA+P": "4RO.AP + 8RO.PI",
  "numeraciones.4RE+E": "4RO.EST + IN.EST.",
  "numeraciones.4ROM": "4 ROMAINS",
  "numeraciones.4RP+I": "4RO.PI + IN.AP",
  "numeraciones.4RP+P": "4RO.PI + IN.PI",
  "numeraciones.5A+5CC": "5AR.AP + 5CIRC",
  "numeraciones.5A+AP": "5AR.AP + AR.PI",
  "numeraciones.5A+IA": "5AR.AP + IN.AP",
  "numeraciones.5A+IP": "5AR.AP + IN.PI",
  "numeraciones.5A.AP": "5AR.AP",
  "numeraciones.5A.PI": "5AR.PI",
  "numeraciones.5AP+I": "5AR.PI + IN.AP",
  "numeraciones.5AP+P": "5AR.PI + IN.PI",
  "numeraciones.5CC": "5CIRC.",
  "numeraciones.5D+IA": "5 DIAM + IND. APLI",
  "numeraciones.5DIAM": "5 DIAMANTS",
  "numeraciones.5IA": "5IN.AP",
  "numeraciones.5IP": "5IN.PI",
  "numeraciones.5R+IA": "5RO.AP + IN.AP",
  "numeraciones.5R+IP": "5RO.AP + IN.PI",
  "numeraciones.5R+RP": "5RO.AP + RO.PI",
  "numeraciones.5R.AP": "5RO.AP",
  "numeraciones.5R.PI": "5RO.PI",
  "numeraciones.5RP+I": "5RO.PI + IN.AP",
  "numeraciones.5RP+P": "5RO.PI + IN.PI",
  "numeraciones.6A+AP": "6AR.AP + AR.PI",
  "numeraciones.6A+CC": "6AR.AP + CIRC.",
  "numeraciones.6A+IA": "6AR.AP + IN.AP",
  "numeraciones.6A+IP": "6AR.AP + IN.PI",
  "numeraciones.6A.AP": "6AR.AP",
  "numeraciones.6A.PI": "6AR.PI",
  "numeraciones.6AP+I": "6AR.PI + IN.AP",
  "numeraciones.6AP+P": "6AR.PI + IN.PI.",
  "numeraciones.6CC.": "6CIRC.",
  "numeraciones.6IA": "6IN.AP",
  "numeraciones.6IP": "6IN.PI",
  "numeraciones.6R+IA": "6RO.AP + IN.AP",
  "numeraciones.6R+IP": "6RO.AP + IN.PI",
  "numeraciones.6R+RP": "6RO.AP + RO.PI",
  "numeraciones.6R.AP": "6RO.AP",
  "numeraciones.6R.P": "6RO.PIN.",
  "numeraciones.6RP+I": "6RO.PI + IN.AP",
  "numeraciones.6RP+P": "6RO.PI + IN.PI",
  "numeraciones.7A+IA": "7AR.AP + IN.AP",
  "numeraciones.7AR.P": "7AR.P",
  "numeraciones.7DIA": "7 DIAMANTS",
  "numeraciones.8A+IA": "8AR.AP + IN.AP",
  "numeraciones.8AP": "8ARA.PIN.",
  "numeraciones.8AP+P": "8ARA.PIN + IND.PIN.",
  "numeraciones.8R+IA": "8RO.AP + IN.AP",
  "numeraciones.8RP+P": "8RO.PI + IN.PI.",
  "numeraciones.9ARAB": "9 ARABES",
  "numeraciones.10ARA": "10 ARABES",
  "numeraciones.A+R.P": "ARA + ROM.PIN.",
  "numeraciones.AA+AP": "ARA PINT + ARA APLIC",
  "numeraciones.AP+AA": "ARA PINT + ARA APLIC",
  "numeraciones.AP+IA": "ARA.PIN + IND.A",
  "numeraciones.AP+IP": "AR.PI + IN.PI",
  "numeraciones.AR+DI": "RA. + 10 DIAMANTS",
  "numeraciones.AR.AP": "ARABES APPLIQUÉS",
  "numeraciones.AR.PI": "ARABES PEINTES",
  "numeraciones.AR4/4": "ARABES 4/4 + INDICES",
  "numeraciones.ARA.A": "ARABES APPLIQUÉS",
  "numeraciones.ARA.E": "ARABES IMPRIMÉS",
  "numeraciones.ARA.P": "ARABES PEINTES",
  "numeraciones.ARAB": "ARABES",
  "numeraciones.ARABE": "ARABES 12/12",
  "numeraciones.BAND": "Drapeaux",
  "numeraciones.BOLAS": "BOULES 2N18",
  "numeraciones.CIRC": "Zircone",
  "numeraciones.CR": "CRISTAL SWAROVSKI",
  "numeraciones.DIAM": "12 DIAMANTS",
  "numeraciones.DIAMG": "GRANDS DIAMANTS",
  "numeraciones.DIAN": "ANALOGIQUE NUMÉRIQUE",
  "numeraciones.DIAR": "ARABES NUMÉRIQUES",
  "numeraciones.DIG": "Numérique",
  "numeraciones.DIIN": "INDICES NUMÉRIQUES",
  "numeraciones.FANT": "Fantaisie",
  "numeraciones.FL": "Fleurs",
  "numeraciones.IN+CC": "IND + ZIRCONS",
  "numeraciones.IN.AP": "INDICES APPLIQUÉS",
  "numeraciones.IN.PI": "INDICES PEINTS",
  "numeraciones.INAR": "12INDIC + 12ARAB",
  "numeraciones.IND.A": "INDICES APPLIQUÉS",
  "numeraciones.IND.E": "IND.EST.",
  "numeraciones.IND.P": "INDUSTRIES PEINTES",
  "numeraciones.INDIC": "INDICES",
  "numeraciones.INFAN": "INDICES FANTASTIQUES",
  "numeraciones.INPIN": "IND.PIN.",
  "numeraciones.INRO": "12 INDICES + 12 ROMAINS",
  "numeraciones.IP+AP": "12IN.PI + 12AR.PI",
  "numeraciones.NEO": "NE PORTE PAS",
  "numeraciones.RA+RP": "4ROM APLI + 4ROM PIN",
  "numeraciones.RO+IN": "ROMAINS 12 + 10 INDEX",
  "numeraciones.RO.AP": "ROMAINS APPLIQUÉS",
  "numeraciones.RO.PI": "ROMAINS PEINTS",
  "numeraciones.RO2/2": "ROMAINS 2/2 + INDEX",
  "numeraciones.RO4/4": "ROMAINS 4/4 + INDICES",
  "numeraciones.ROES": "ROM.STAMPINGS",
  "numeraciones.ROM": "ROMAINS",
  "numeraciones.ROM.A": "APPLIQUES ROMAINES",
  "numeraciones.ROM.E": "ROMAINS ESTAMPÉS",
  "numeraciones.ROM.P": "ROMAINS PEINTS",
  "numeraciones.ROMAN": "12/12 ROMAINS",
  "numeraciones.RP+IA": "12ROM.PIN + 12IND.A.",
  "numeraciones.RP+IP": "12RO.PI + 12IN.PI",
  "piedras.tipo.agrupada.ABA": "PERLES DE FORMES DIFFÉRENTES",
  "piedras.tipo.agrupada.ACBA": "BILLE D'ACIER",
  "piedras.tipo.agrupada.AGA": "AGATE",
  "piedras.tipo.agrupada.AGBA": "BOULE D'ARGENT",
  "piedras.tipo.agrupada.AMA": "Améthyste",
  "piedras.tipo.agrupada.AMAN": "AMANZONITE",
  "piedras.tipo.agrupada.ARCI": "BAGUE EN ARGENT AVEC ZIRCONS",
  "piedras.tipo.agrupada.ARGO": "BAGUE",
  "piedras.tipo.agrupada.AVEN": "AVENTURINE",
  "piedras.tipo.agrupada.BAEN": "SET DE BAR ZIRCONIA",
  "piedras.tipo.agrupada.BAPA": "BAR PAVÉ",
  "piedras.tipo.agrupada.BGSP": "BAGUES SANS PRIX",
  "piedras.tipo.agrupada.BOL": "BOULE D'ARGENT À VISAGE",
  "piedras.tipo.agrupada.BRI": "brillant",
  "piedras.tipo.agrupada.BS": "BS",
  "piedras.tipo.agrupada.BUTT": "BOUTON PERLE CULTIVÉE",
  "piedras.tipo.agrupada.CALC": "CALCÉDOINE",
  "piedras.tipo.agrupada.CER": "CÉRAMIQUE",
  "piedras.tipo.agrupada.CIR": "ZIRCONE JAUNE (CZ-YE1)",
  "piedras.tipo.agrupada.CIRC": "SET ZIRCONIA",
  "piedras.tipo.agrupada.COR": "CORNALINE",
  "piedras.tipo.agrupada.CRBA": "BOULE DE CRISTAL",
  "piedras.tipo.agrupada.CRI": "Cristal",
  "piedras.tipo.agrupada.CRPA": "CRUZ PAVE",
  "piedras.tipo.agrupada.CUAR": "QUARTZ",
  "piedras.tipo.agrupada.DIAM": "diamant",
  "piedras.tipo.agrupada.DISP": "0",
  "piedras.tipo.agrupada.ESMA": "Email",
  "piedras.tipo.agrupada.ESP": "Spinelle",
  "piedras.tipo.agrupada.FERI": "Vacances",
  "piedras.tipo.agrupada.G030": "FO1030",
  "piedras.tipo.agrupada.G031": "FO1031",
  "piedras.tipo.agrupada.G034": "brillant",
  "piedras.tipo.agrupada.G036": "FO1036",
  "piedras.tipo.agrupada.G050": "FO1050",
  "piedras.tipo.agrupada.GI10": "PIERRE 0.90",
  "piedras.tipo.agrupada.GI11": "38640",
  "piedras.tipo.agrupada.GI12": "MOD FO282",
  "piedras.tipo.agrupada.GI13": "LUNETTE CRIMPÉE DE SHOCKWAVE",
  "piedras.tipo.agrupada.GI14": "PIERRES 1,6 DIAM",
  "piedras.tipo.agrupada.GI15": "PIERRE 0.80",
  "piedras.tipo.agrupada.GIL": "DIAM ENGAS MIGUEL GIL 0.015K",
  "piedras.tipo.agrupada.GIL1": "PIERRES 1.20 + 1.50",
  "piedras.tipo.agrupada.GIL2": "FO436",
  "piedras.tipo.agrupada.GIL3": "DIAMANT 1.10",
  "piedras.tipo.agrupada.GIL4": "MODÈLE DE PIERRE LO314",
  "piedras.tipo.agrupada.GIL5": "MODÈLE PIERRES FO323",
  "piedras.tipo.agrupada.GIL6": "PIERRES DE 1,20",
  "piedras.tipo.agrupada.GIL7": "PIERRES 1.40",
  "piedras.tipo.agrupada.GIL8": "PIERRES 1.30",
  "piedras.tipo.agrupada.GIL9": "PIERRE 1.00",
  "piedras.tipo.agrupada.GLAM": "GLAM",
  "piedras.tipo.agrupada.HEMA": "HÉMATITE",
  "piedras.tipo.agrupada.JADE": "JADE",
  "piedras.tipo.agrupada.LABR": "LABRADORITE",
  "piedras.tipo.agrupada.LAPI": "LAPIS LAZULI",
  "piedras.tipo.agrupada.LAVA": "BOULE DE LAVE",
  "piedras.tipo.agrupada.MP": "MÈRE PERLE",
  "piedras.tipo.agrupada.NAC": "NACRE",
  "piedras.tipo.agrupada.OJGA": "OEIL DE CHAT",
  "piedras.tipo.agrupada.ONI": "ONYX",
  "piedras.tipo.agrupada.OPPE": "OPALE PÉRUVIENNE",
  "piedras.tipo.agrupada.OTIG": "OEIL DE TIGRE (GRENAT)",
  "piedras.tipo.agrupada.OTUR": "OEIL TURQUE",
  "piedras.tipo.agrupada.PABA": "PAVE BALL",
  "piedras.tipo.agrupada.PEBA": "PERLE BAROQUE CULTIVÉE",
  "piedras.tipo.agrupada.PECU": "PERLE DE CULTURE",
  "piedras.tipo.agrupada.PERL": "PERLE NATURELLE",
  "piedras.tipo.agrupada.PESY": "PERLE SYNTHÉTIQUE",
  "piedras.tipo.agrupada.PILU": "PIERRE DE LUNE",
  "piedras.tipo.agrupada.QURS": "QUARTZ ROSE",
  "piedras.tipo.agrupada.RES": "BOULE DE RÉSINE",
  "piedras.tipo.agrupada.RESI": "RÉSINE",
  "piedras.tipo.agrupada.RORU": "RHODIUM RUTENIUM",
  "piedras.tipo.agrupada.RUB": "Rubis",
  "piedras.tipo.agrupada.SWA": "SWAROVSKI",
  "piedras.tipo.agrupada.SZA1": "PIERRE 1.50",
  "piedras.tipo.agrupada.SZA2": "PIERRE 1.20",
  "piedras.tipo.agrupada.TUCI": "TUBE ZIRCONE",
  "piedras.tipo.agrupada.TUR": "TURQUOISE",
  "producto.coleccion": "collection",
  "producto.tipo.accesorio": "accessoire",
  "producto.tipo.joya": "Bijou",
  "producto.tipo.reloj": "montre",
  "tipo.movimiento.agrupado.01": "Numérique",
  "tipo.movimiento.agrupado.02": "QUARTZ DIGITAL",
  "tipo.movimiento.agrupado.03": "Manuel",
  "tipo.movimiento.agrupado.04": "RÉSERVE-MARS",
  "tipo.movimiento.agrupado.05": "hybrides",
  "tipo.movimiento.agrupado.06": "SMARTWATCH",
  "tipo.movimiento.agrupado.07": "QUARTZ",
  "tipolente.POLARIZED": "Polarisé"
}
