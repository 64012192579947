import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UtilStaticImageUrlModule } from '@fgp/shared/pipes/util-static-image-url';
import { IPaymentMethodIcons } from '../../models/footer-bar-perrelet.model';

@Component({
    selector: 'fgp-footer-bar-perrelet-payment-icons',
    imports: [CommonModule, UtilStaticImageUrlModule],
    templateUrl: './footer-bar-perrelet-payment-icons.component.html',
    styleUrl: './footer-bar-perrelet-payment-icons.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterBarPerreletPaymentIconsComponent {
  @Input({ required: true }) icons: IPaymentMethodIcons[];
}
