<article class="card card-plain producto-catalogo" (mouseover)="showCarousel = true" (mouseleave)="showCarousel = false">
  <fgp-favorite-icon [product]="producto"></fgp-favorite-icon>
  @if (producto?.novedad) {
    <p class="novedad py-1 paragraph-4 font-italic my-0 text-uppercase font-weight-bold">
      {{ 'detalle.producto.festina.novedad' | translate }}
    </p>
  }

  <a
    (click)="gtmProductClick()"
    routerLink="{{ languageUrl }}{{ producto?.productoPrincipal?.urlPathProducto }}"
    [queryParams]="
      collectionType[collectionType.private] === producto?.productoPrincipal.coleccionType
        ? {
            val1: producto?.productoPrincipal?.idColeccion
          }
        : ''
    "
    [title]="producto?.productoPrincipal?.nombre"
    class="text-center d-block position-relative card-background-color"
  >
    <picture>
      <source [srcset]="producto?.productoPrincipal.urlImg | webp" type="image/webp" />
      <img
        loading="lazy"
        alt="{{ producto?.productoPrincipal?.nombre }}"
        class="img-fluid mx-auto"
        [ngClass]="{ 'w-50': !size, 'w-75': size === 75 }"
        [src]="producto?.productoPrincipal.urlImg"
        height="212"
        width="127"
      />
    </picture>
    <fgp-product-icons [product]="producto" />
    @if (producto?.productoPrincipal?.mostrarPvpAnterior) {
      <p class="descuento my-0 p-1 white-color paragraph-3 semibold-text">
        - {{ producto?.productoPrincipal?.pvp | discount: producto?.productoPrincipal?.pvpAnterior | number: '1.0-0' }}%
      </p>
    }
  </a>

  <div class="card-body p-2-5 position-relative">
    <p class="paragraph-1 my-0 font-weight-bold text-uppercase">{{ producto?.productoPrincipal?.coleccion }}</p>
    <div class="card-body-description d-flex flex-column position-relative">
      <a
        (click)="gtmProductClick()"
        routerLink="{{ languageUrl }}{{ producto?.productoPrincipal?.urlPathProducto }}"
        [queryParams]="{ val1: producto?.productoPrincipal?.idColeccion }"
        [title]="producto?.productoPrincipal?.nombre"
      >
        <h3 class="paragraph-3 text-name py-1">
          {{ producto?.productoPrincipal?.nombre }}
        </h3>
      </a>
      <div class="d-flex flex-column">
        @if (producto?.productoPrincipal?.mostrarPvpAnterior) {
          <p class="strike paragraph-3 gris-4-color pr-2">{{ producto?.productoPrincipal?.pvpAnterior | utilCurrency }}</p>
        }
        <p class="paragraph-3 font-weight-bold pb-2 my-0" [ngClass]="{ 'text-red': producto?.productoPrincipal?.mostrarPvpAnterior }">
          {{ producto?.productoPrincipal?.pvp | utilCurrency }}
        </p>
        @if (producto?.productoPrincipal?.preSale) {
          <fgp-ui-pre-sale-chip />
        }
      </div>
      @if (producto.productos.length > 1) {
        <p class="paragraph-3 text-left my-0 gris-4-color models-products">
          {{ producto.productos.length }}
          <span>{{ (producto.productos.length === 1 ? 'generic.strings.modelo' : 'generic.strings.modelos') | translate }}</span>
        </p>
      }
      @if (isDesktop$()) {
        <div class="card-body-hover w-100 d-none">
          <a
            (click)="gtmProductClick()"
            routerLink="{{ languageUrl }}{{ producto?.productoPrincipal?.urlPathProducto }}"
            [queryParams]="
              collectionType[collectionType.private] === producto?.productoPrincipal.coleccionType
                ? {
                    val1: producto?.productoPrincipal?.idColeccion
                  }
                : ''
            "
            class="btn d-flex mx-auto justify-content-center"
            [ngClass]="{
              'btn-info': !producto?.productoPrincipal?.preSale,
              'bg-pre-sale-primary border-0': producto?.productoPrincipal?.preSale
            }"
          >
            @if (producto?.productoPrincipal?.preSale) {
              <div class="d-flex align-items-center">
                <i class="icon icon-pre-sale icon-xm mr-2 bg-white"></i>
                <p class="paragraph-2 font-weight-bold my-0 pt-1 pt-lg-0 text-white">
                  {{ 'pre-sale.title' | translate }}
                </p>
              </div>
            } @else {
              {{ 'generic.strings.see.product' | translate }}
            }
          </a>
        </div>
      }
    </div>
    @if (isDesktop$()) {
      <div class="models-container">
        <fgp-product-swiper
          [productos]="producto?.productos"
          [showCarousel]="showCarousel && producto.productos.length > 1"
          [codigo]="producto?.productoPrincipal?.codigo"
          [swiperModelConfig]="swiperModelConfig"
          (selectedProduct)="obtenerInformacionProducto($event)"
        ></fgp-product-swiper>
      </div>
    }
  </div>
</article>
