{
	"acabado.AC-OR": "STAHLGOLD",
	"acabado.B": "Helligkeit",
	"acabado.BP01": "BP01",
	"acabado.BP01C": "FARBKREIS",
	"acabado.BSL": "BSL",
	"acabado.CAUCH": "Silikon",
	"acabado.CORB": "Krawatte",
	"acabado.CRISA": "CHRYSALIS",
	"acabado.ESTAM": "Drucken",
	"acabado.F+R": "WOHNUNG + RUND",
	"acabado.F+S": "WOHNUNG + GERADE",
	"acabado.F+S C": "FLAT + COLOR REC",
	"acabado.F+T": "WOHNUNG + KRAWATTE",
	"acabado.FAC06": "FAC06",
	"acabado.FR11": "FRAP 11",
	"acabado.FRA15": "FRAP15",
	"acabado.GRAB": "Verzeichnet",
	"acabado.LAPI": "LAPIDADO",
	"acabado.LASER": "sein",
	"acabado.M": "KAMERAD",
	"acabado.M+S": "BERG + GERADE",
	"acabado.M+S C": "MONT.RECT.COLOR",
	"acabado.M+T": "BERG + KRAWATTE",
	"acabado.MAL": "GITTERGEWEBE",
	"acabado.MB": "MATTE-GLOSS",
	"acabado.MECA": "Bearbeitung",
	"acabado.MF5P": "MIKROFUSION 5PC",
	"acabado.MMILA": "MAILANDISCHES MESCH",
	"acabado.MOUNT": "Montana",
	"acabado.MTCA": "Kumpel CALADA",
	"acabado.OYS": "Auster",
	"acabado.PIR3": "PYRAMIDAL 3M",
	"acabado.PMP": "PULI-MATE-PULI",
	"acabado.PPM": "POLISHED-MATT PANTER",
	"acabado.PPU": "POLIERTER PANTER",
	"acabado.PRES": "Präsident",
	"acabado.PULID": "poliert",
	"acabado.RAS": "Kratzte",
	"acabado.ROUND": "Rund",
	"acabado.S7M": "ESTAM 7 3MAT",
	"acabado.S7R": "STEMPEL 7 STÜCK STREIFEN",
	"acabado.S73M": "ESTAMP 7-3MATE",
	"acabado.ST3": "3PCS STAMPING",
	"acabado.ST4": "STEMPELN 4 STÜCKE",
	"acabado.ST5": "STEMPELTE 5 STÜCKE",
	"acabado.ST6": "Gestempelt 6PCS",
	"acabado.ST7": "7 STÜCKSTempel",
	"acabado.TPMP": "ROHR P-M-P",
	"acabado.TUB": "TUBE",
	"acabado.V620": "V620",
	"acabado.Y": "POLIERTER BERG",
	"acabado.YHLO": "BERG 1 / 2B + M.",
	"accesorios.subtipo.BALLPOINT_PEN": "tintenroller",
	"accesorios.subtipo.BELT": "Gürtel",
	"accesorios.subtipo.CARD_HOLDER": "Kartenetuis",
	"accesorios.subtipo.DOCUMENT_BAG": "Dokumententasche",
	"accesorios.subtipo.DRESSING_CASE": "Verbandskasten",
	"accesorios.subtipo.FOLDER": "Bindemittel",
	"accesorios.subtipo.FOUNTAIN_PEN": "Füllfederhalter",
	"accesorios.subtipo.LAPTOP_BAG": "Laptop-Tasche",
	"accesorios.subtipo.LEATHER_PEN_SET": "Leder-Kugelschreiber-Set",
	"accesorios.subtipo.OPTIC": "Optische Brillen",
	"accesorios.subtipo.OPTIC_SOLAR_CLIP": "",
	"accesorios.subtipo.PEN": "stift",
	"accesorios.subtipo.REPLACEMENT": "Ersatzteil",
	"accesorios.subtipo.REPORTER_BAG": "Umhängetasche",
	"accesorios.subtipo.ROLLERBALL_PEN": "Kugelschreiber",
	"accesorios.subtipo.SOLAR": "Sonnenbrille",
	"accesorios.subtipo.WALLET": "Geldbörsen",
	"accesorios.subtipo.WATCH_CASE": "Uhrengehäuse",
	"accesorios.subtipo.WRITING_CASE": "Fall schreiben",
	"accesorios.subtipo.WRITING_SET": "Schreibset",
	"accesorios.tipo.CLEANER": "Reiniger",
	"accesorios.tipo.EYEWEAR": "Brillen",
	"accesorios.tipo.FRAGANCE": "parfums",
	"accesorios.tipo.JEWELLERY_BOX": "Juweliere",
	"accesorios.tipo.KEYCHAIN": "Schlüsselanhänger",
	"accesorios.tipo.LEATHER_ITEM": "Lederwaren",
	"accesorios.tipo.SET": "setzt",
	"accesorios.tipo.SPARE_PARTS": "ersatzteile",
	"accesorios.tipo.TOOL": "werkzeuge",
	"accesorios.tipo.WRITING": "schreibgeräte",
	"accesorios.tipo_boligrafo.BALLPOINT": "Ballpoint",
	"accesorios.tipo_boligrafo.ROLLERBALL": "Rollerball",
	"agrupacion.coleccion": "kollektionen",
	"agrupacion.seleccion": "auswahl",
	"anchopuente.14": "14 mm",
	"anchopuente.15": "15 mm",
	"anchopuente.16": "16 mm",
	"anchopuente.17": "17 mm",
	"anchopuente.18": "18 mm",
	"anchopuente.21": "21 mm",
	"caracteristicas.alarma": "Alarm",
	"caracteristicas.balancier_visible": "Sichtbarer Ausgleichsmechanismus",
	"caracteristicas.complicacion_automatico": "Automatische Komplikation",
	"caracteristicas.corona_rosca": "Kranzgebäck",
	"caracteristicas.dual_time": "Zweite Zeitzone",
	"caracteristicas.gmt": "GMT",
	"caracteristicas.luminova": "Luminova",
	"caracteristicas.luz": "Licht",
	"caracteristicas.reserva_marcha": "Gangreserve",
	"caracteristicas.skeleton": "Skeleton",
	"caracteristicas.solar": "Solar",
	"caracteristicas.superluminova": "Superluminova",
	"caracteristicas.tourbillon": "Tourbillon",
	"cierre.agrupado.CAJ": "Faltschließe",
	"cierre.agrupado.CAP": "TASTENKASTEN",
	"cierre.agrupado.CCAT": "Englischer Verschluss",
	"cierre.agrupado.CCJ": "SCHUBLADENVERSCHLUSS",
	"cierre.agrupado.CGAN": "Hakenverschluss",
	"cierre.agrupado.CI": "BUTTERFLY",
	"cierre.agrupado.CIP": "Druckverschluss",
	"cierre.agrupado.CLIP": "Clipverschluss",
	"cierre.agrupado.CM": "MAGNETISCH",
	"cierre.agrupado.COMEGA": "Juwelierclip",
	"cierre.agrupado.CPC": "BOX PULS GÜRTEL",
	"cierre.agrupado.CPL": "Seite",
	"cierre.agrupado.CPR": "Druck",
	"cierre.agrupado.CSE": "Abschließbarer Verschluss",
	"cierre.agrupado.CSP": "SICHERE SCHLIESSUNGSTASTE",
	"cierre.agrupado.HEB": "Dornschließe",
	"cierre.agrupado.HKSALO": "TRESORSCHLOSS",
	"cierre.agrupado.LI": "Buchdruck",
	"cierre.agrupado.MOS": "KARABINER",
	"cierre.agrupado.OCL": "Hidden",
	"cierre.agrupado.OCP": "VERSTECKTE DRUCKTASTE",
	"cierre.agrupado.OCU": "Afrika",
	"cierre.agrupado.ROS": "Schraubverschluss",
	"cierre.agrupado.SO": "Kippfaltschließe mit Druckknopf",
	"cierre.agrupado.SOP": "Sicherheitsschließe",
	"cierre.CAJ": "Faltschließe",
	"cierre.CAP": "TASTENKASTEN",
	"cierre.CCJ": "SCHUBLADENVERSCHLUSS (STIL)",
	"cierre.CI": "SCHLIESSUNG 1",
	"cierre.CIP": "DRUCKVERSCHLUSS",
	"cierre.CM": "MAGNETISCH",
	"cierre.CMS": "MAGNETISCH MIT VERSICHERUNG",
	"cierre.CPC": "GÜRTEL PULS BOX",
	"cierre.CPL": "PULS SPÄTER BOX.",
	"cierre.CPR": "Druck",
	"cierre.CSE": "Abschließbarer Verschluss",
	"cierre.CSP": "SICHERE SCHLIESSUNGSTASTE",
	"cierre.HEB": "Dornschließe",
	"cierre.HKBB": "SCHMETTERLINGSSCHNALLE",
	"cierre.HKCB": "CLIP BUCKLE",
	"cierre.HKCLB": "CLIP LOCK BUCKLE",
	"cierre.HKJEWBUK": "SCHMUCKSCHNALLE",
	"cierre.HKLB": "LEDERSCHNALLE",
	"cierre.HKLOBUK": "Butterfly",
	"cierre.HKMB": "MAGNETISCHE SCHNALLE",
	"cierre.HKSALO": "TRESORSCHLOSS",
	"cierre.HKSCB": "SPRING C-CLIP BUCKLE",
	"cierre.HKT-BABUK": "T-BAR SCHNALLE",
	"cierre.HOR": "GOLD SCHNALLE",
	"cierre.LI": "Buchdruck",
	"cierre.MOS": "KARABINER",
	"cierre.NEO": "TRÄGT NICHT",
	"cierre.NOSE": "Weiß nicht",
	"cierre.OCH": "Versteckte Schließschnalle",
	"cierre.OCL": "Klappschließe",
	"cierre.OCP": "VERSTECKTE DRUCKTASTE",
	"cierre.OCS": "Versteckte Klappe",
	"cierre.OCU": "Afrika",
	"cierre.OPC": "HIDDEN STRAP PUSH",
	"cierre.OSP": "VERSTECKTE FLAP PULS",
	"cierre.ROS": "GEWINDESCHLIESSUNG",
	"cierre.SO": "Kippfaltschließe mit Druckknopf",
	"cierre.SOC": "FLAP STRAP",
	"cierre.SOH": "FLAP BUCKLE",
	"cierre.SOP": "Sicherheitsschließe",
	"cierre.SPC": "FLAP PULS GÜRTEL",
	"cierre.undefined": "",
	"colores_base.1": "CRO",
	"colores_base.2N18": "2N18 GOLDEN",
	"colores_base.2NBN": "MITTE 2N18 SCHWARZER KÖRPER",
	"colores_base.2NMA": "2N18 MIT MARKEN",
	"colores_base.2NTN": "2N18 MIT SCHWARZEM Filet",
	"colores_base.3N": "3N GOLD",
	"colores_base.3N18": "3N18",
	"colores_base.4N18": "LICHT ROSE GOLD",
	"colores_base.5N": "5N ROSENGOLD",
	"colores_base.5N18": "ROSA GOLD",
	"colores_base.026": "Elfenbein",
	"colores_base.032": "DUNKELBRAUN",
	"colores_base.037": "HAVANA BROWN",
	"colores_base.041": "MITTLERE GOLD",
	"colores_base.061": "MITTELBLAU",
	"colores_base.065": "hellblau",
	"colores_base.066": "hellblau",
	"colores_base.068": "AZURBLAU",
	"colores_base.084": "KORALLE",
	"colores_base.100": "GLÄNZENDER STAHL",
	"colores_base.128": "Nackt",
	"colores_base.591": "PVD SCHWARZ",
	"colores_base.600": "Rose Gold",
	"colores_base.840": "STAHL + GOLD",
	"colores_base.850": "STAHL + ROSENGOLD",
	"colores_base.ACCC": "Edelstahl & Zirkonia",
	"colores_base.AG": "SILBER",
	"colores_base.AGAB": "SILBERNER BERNSTEIN",
	"colores_base.AGCR": "SILBERKRISTALL",
	"colores_base.AGLA": "LAVENDER SILBER",
	"colores_base.AGLM": "SILBERKALK",
	"colores_base.AGMC": "MARKIERTES SILBER",
	"colores_base.AGMO": "SILBER LILA",
	"colores_base.AGPE": "SILBERNE PERLE",
	"colores_base.AGRO": "SILBER ROT",
	"colores_base.AGTP": "SILBERNE TOPAZ",
	"colores_base.AGU": "Aquamarin",
	"colores_base.AM": "Gelb",
	"colores_base.AMA": "Amethyst",
	"colores_base.AMBA": "Bernstein",
	"colores_base.AMBR": "BRONZE GELB",
	"colores_base.AMCC": "GELBE ZIRKONIE",
	"colores_base.AMMA": "BRAUNES GELB",
	"colores_base.AMPE": "GELBE PERLE",
	"colores_base.AMT": "Amethyst",
	"colores_base.AQ": "Aquamarin",
	"colores_base.AQBO": "AQUA BOHEMICA",
	"colores_base.AUR": "Dämmerung",
	"colores_base.AURB": "NORDLICHTER",
	"colores_base.AVAZ": "BLAUES ABENTEUER",
	"colores_base.AZ": "Blau",
	"colores_base.AZRS": "BLAU ROSA",
	"colores_base.BDIA": "SCHWARZER DIAMANT",
	"colores_base.BE": "Beige",
	"colores_base.BICO": "BICOLOR",
	"colores_base.BL": "Weiß",
	"colores_base.BLCO": "CORAL WHITE",
	"colores_base.BLPE": "WEISSE PERLE",
	"colores_base.BLRY": "Gestreiftes Weiß",
	"colores_base.BR": "Bronze",
	"colores_base.BRCC": "BRONZE ZIRCONS",
	"colores_base.BRIL": "SPARKLY",
	"colores_base.BRLI": "BRONZE LILAC",
	"colores_base.BRRS": "BRONZE ROSA",
	"colores_base.BU": "BORDEAUX",
	"colores_base.BUCR": "BURGUNDECREME",
	"colores_base.CA": "Kamel",
	"colores_base.CABA": "Pferd",
	"colores_base.CAL": "Ziehen",
	"colores_base.CALD": "Kessel",
	"colores_base.CCPE": "ZIRCONIA PERLE",
	"colores_base.CER": "Kirsche",
	"colores_base.CH": "Champagner",
	"colores_base.CHA": "Überzogen",
	"colores_base.ChGR": "GRAU BESCHICHTET",
	"colores_base.CHOC": "Schokolade",
	"colores_base.CIRC": "Zirkonia",
	"colores_base.CITR": "CITRINE",
	"colores_base.CM": "Creme",
	"colores_base.CMFU": "FUCHSIA-CREME",
	"colores_base.CO": "Kupfer",
	"colores_base.CORA": "KORALLE",
	"colores_base.CQ": "KHAKI",
	"colores_base.CR": "VERCHROMT",
	"colores_base.CRDO": "GOLDENER KRISTALL",
	"colores_base.CRIS": "Kristall",
	"colores_base.CRTR": "TRANSPARENTER KRISTALL",
	"colores_base.CURR": "CURRO",
	"colores_base.CZ": "QUARZ",
	"colores_base.CZAZ": "BLAUES QUARTZ",
	"colores_base.CZMA": "BROWN QUARTZ",
	"colores_base.CZRS": "ROSA QUARTZ",
	"colores_base.DELF": "Delfin",
	"colores_base.DI": "Digital",
	"colores_base.DIAM": "Diamanten",
	"colores_base.DO": "Gold",
	"colores_base.DONE": "GOLDEN SCHWARZ",
	"colores_base.ESM": "Smaragd",
	"colores_base.EXPO": "Messe",
	"colores_base.FLOR": "Blume",
	"colores_base.FLUO": "FLUORESZIEREND",
	"colores_base.FOCA": "Dichtung",
	"colores_base.FR": "Erdbeere",
	"colores_base.FRAM": "Himbeere",
	"colores_base.FU": "Fuchsie",
	"colores_base.FUCC": "FUCHSIA ZIRCONS",
	"colores_base.FUME": "Rauch",
	"colores_base.GATO": "Katze",
	"colores_base.GN": "Granat",
	"colores_base.GR": "GRAU",
	"colores_base.GRA": "Granat",
	"colores_base.GRAG": "Grausilber",
	"colores_base.GRPL": "SILBER-GRAU",
	"colores_base.HA": "HAMILTON",
	"colores_base.HAB": "HAVANA",
	"colores_base.HAHA": "HAMILTON HAMILTON",
	"colores_base.HAMC": "HAMILTON MARKIERT",
	"colores_base.HANA": "HAMILTON NACAR",
	"colores_base.HANC": "HAMILTON MUTTER VON PERLE",
	"colores_base.HEM": "HEMATIT",
	"colores_base.INDI": "INDICOLITE",
	"colores_base.JET": "JET",
	"colores_base.KOAL": "Koala",
	"colores_base.LI": "Lila",
	"colores_base.LIOS": "DUNKLE LILA",
	"colores_base.LIPE": "Fliederperle",
	"colores_base.LUMI": "SUPERLUMINOVA",
	"colores_base.LUN": "Mond",
	"colores_base.MA": "Braun",
	"colores_base.MAAG": "BRAUN / SILBER",
	"colores_base.MAPE": "PERLE BRAUN",
	"colores_base.MBLU": "METALLISCH BLAU",
	"colores_base.MC": "Markiert",
	"colores_base.ME": "Pfirsich",
	"colores_base.MEN": "Minze",
	"colores_base.MF": "Elfenbein",
	"colores_base.MFNE": "Elfenbeinschwarz",
	"colores_base.MGRE": "METALLISCHES GRÜN",
	"colores_base.MO": "Violett",
	"colores_base.MOBL": "LILA WEISS",
	"colores_base.MOS": "Senf",
	"colores_base.MS": "Senf",
	"colores_base.MSIL": "SILBERMETALLIC",
	"colores_base.MT": "KAMERAD",
	"colores_base.MULT": "Mehrfarbig",
	"colores_base.MULTAM": "MULTICOLOR GELB",
	"colores_base.MULTAZ": "MULTICOLOR BLAU",
	"colores_base.MULTRS": "MULTICOLOR PINK",
	"colores_base.NA": "Perlmutt",
	"colores_base.NAAZ": "NACAR BLAU",
	"colores_base.NE": "Schwarz",
	"colores_base.NEAM": "SCHWARZ GELB",
	"colores_base.NECC": "SCHWARZE ZIRKONE",
	"colores_base.NEDO": "SCHWARZES GOLD",
	"colores_base.NEGD": "SCHWARZER DIAMANT",
	"colores_base.NEO": "TRÄGT NICHT",
	"colores_base.NEPE": "SCHWARZE PERLE",
	"colores_base.NJ": "Orange",
	"colores_base.NJNC": "ORANGE PERLE",
	"colores_base.NYB": "WHITE NYLON",
	"colores_base.NYBE": "NYLON BEIG",
	"colores_base.NYBL": "WHITE NYLON",
	"colores_base.NYC": "NYLONCREME",
	"colores_base.NYCM": "NYLONCREME",
	"colores_base.NYG": "GRAU NYLON",
	"colores_base.NYGR": "GRAU NYLON",
	"colores_base.NYM": "NYLON BROWN",
	"colores_base.NYMA": "NYLON BROWN",
	"colores_base.NYN": "SCHWARZES NYLON",
	"colores_base.NYNE": "SCHWARZES NYLON",
	"colores_base.NYO": "NYLON OCHER",
	"colores_base.NYR": "ROTES NYLON",
	"colores_base.OBSI": "OBSIDIAN",
	"colores_base.OC": "OCKER",
	"colores_base.OCR": "OCKER",
	"colores_base.OLIV": "Olivia",
	"colores_base.ONIX": "ONYX",
	"colores_base.OR": "GOLD",
	"colores_base.ORAZ": "BLAUES GOLD",
	"colores_base.PACH": "BLAUE CHAMPAGNE",
	"colores_base.PAJA": "Vögel",
	"colores_base.PAV": "PAVONADO",
	"colores_base.PD": "Palladium",
	"colores_base.PER": "Perle",
	"colores_base.PERN": "SCHWARZE PERLE",
	"colores_base.PERR": "Hund",
	"colores_base.PLAG": "SILBER SILBER",
	"colores_base.PLAM": "SILBERGELB",
	"colores_base.PLAZ": "BLAUES SILBER",
	"colores_base.PLLI": "Flieder Silber",
	"colores_base.PLNE": "SILBER SCHWARZ",
	"colores_base.PLPL": "SILBER SILBER",
	"colores_base.PLRS": "ROSA SILBER",
	"colores_base.PLVE": "SILBERGRÜN",
	"colores_base.PV": "PAVONADO",
	"colores_base.PVCH": "BLAUE CHAMPAGNE",
	"colores_base.PVD": "PVD",
	"colores_base.RH": "Rhodium",
	"colores_base.RH5N": "WEISSGOLD + 5N GOLD",
	"colores_base.RO": "rot",
	"colores_base.ROCA": "ROTE KALADA",
	"colores_base.ROMS": "ROTER SENF",
	"colores_base.RONE": "ROT SCHWARZ",
	"colores_base.RS": "Roségold",
	"colores_base.RSCC": "ROSA ZIRCONIA",
	"colores_base.RSPE": "PERLROSA",
	"colores_base.RSRO": "PINK ROT",
	"colores_base.RU": "RUTHENIUM",
	"colores_base.RUBI": "Rubin",
	"colores_base.SASA": "LACHS LACHS",
	"colores_base.ST": "SEMITRANSPARENT",
	"colores_base.TA": "BLAUER TIGER",
	"colores_base.TEJA": "DACHZIEGEL",
	"colores_base.TIT": "Titan",
	"colores_base.TOP": "TOPAZIO",
	"colores_base.TP": "Topas",
	"colores_base.TPCL": "LICHT TOPAZ",
	"colores_base.TR": "ROSA TIGER",
	"colores_base.TRAN": "Transparent",
	"colores_base.TRCO": "TRICOLOR",
	"colores_base.TRI": "TRICOLOR",
	"colores_base.TRNE": "TRANSP SCHWARZ",
	"colores_base.TW/W": "TOP WESSELTON ZU WESSELTON",
	"colores_base.VA": "Vanille",
	"colores_base.VE": "Grün",
	"colores_base.VEBRI": "GRÜN GLÄNZEND",
	"colores_base.VECC": "GRÜNE ZIRKONIEN",
	"colores_base.VI": "violett",
	"colores_base.VIDI": "DIGITAL VIOLET",
	"colores_base.VIGR": "VIOLET GREY",
	"colores_base.VIN": "Wein",
	"colores_base.VIOS": "Dunkelviolett",
	"colores_base.ZAFI": "Saphir",
	"colores_catalogo.AG": "SILBER",
	"colores_catalogo.AM": "Gelb",
	"colores_catalogo.AZ": "Blau",
	"colores_catalogo.BE": "Beige",
	"colores_catalogo.BL": "Weiß",
	"colores_catalogo.BU": "BORDEAUX",
	"colores_catalogo.CH": "Champagner",
	"colores_catalogo.CM": "Creme",
	"colores_catalogo.CO": "Kupfer",
	"colores_catalogo.DO": "Gold",
	"colores_catalogo.GR": "GRAU",
	"colores_catalogo.MA": "Braun",
	"colores_catalogo.MULT": "Mehrfarbig",
	"colores_catalogo.NA": "Perlmutt",
	"colores_catalogo.NE": "Schwarz",
	"colores_catalogo.NJ": "Orange",
	"colores_catalogo.PL": "SILBER",
	"colores_catalogo.PLA": "SILBER",
	"colores_catalogo.PLAZ": "BLAUES SILBER",
	"colores_catalogo.PLRS": "ROSA SILBER",
	"colores_catalogo.RO": "rot",
	"colores_catalogo.ROSE": "Roségold",
	"colores_catalogo.RS": "Roségold",
	"colores_catalogo.VE": "Grün",
	"colores_catalogo.VI": "violett",
	"correa.agrupada.ARMYS": "Edelstahl",
	"correa.agrupada.CORREA": "Leder/Silikon",
	"estanqueidad.1A": "1 BAR",
	"estanqueidad.3A": "3 BAR",
	"estanqueidad.5A": "5 BAR",
	"estanqueidad.10A": "10 BAR",
	"estanqueidad.20A": "20 BAR",
	"estanqueidad.?": "Keine Enge",
	"estanqueidad.IP68": "IP68",
	"estanqueidad.NEO": "Keine",
	"estanqueidad.R.P.": "STAUBBESTÄNDIG",
	"estanqueidad.RP": "STAUBBESTÄNDIG",
	"estanqueidad.SHPR": "DUSCHDICHT",
	"estanqueidad.W.P.": "WASSER GESCHÜTZT.",
	"estanqueidad.W.R.": "WASSERFEST",
	"estanqueidad.WR": "WASSERFEST",
	"estilos.CA": "Beiläufig",
	"estilos.CH": "Schick",
	"estilos.CL": "Klassisch",
	"estilos.EL": "ELEGANZ",
	"estilos.LE": "LIMITIERTE AUFLAGE, BESCHRÄNKTE AUFLAGE",
	"estilos.SP": "Sport",
	"estilos.VI": "Jahrgang",
	"formaCaja.C": "Quadrat",
	"formaCaja.CR": "Kreuz",
	"formaCaja.DI": "Digital",
	"formaCaja.IR": "IRREGULÄR",
	"formaCaja.O": "Oval",
	"formaCaja.RC": "Rechteckig",
	"formaCaja.RD": "Rund",
	"formaCaja.TO": "Fass",
	"formaCaja.TV": "Fernseher",
	"funcion.agrupada.101": "Digitale Funktionen",
	"funcion.agrupada.102": "Mehrfachfunktion",
	"funcion.agrupada.103": "STUNDEN UND MINUTEN",
	"funcion.agrupada.104": "Chronograph",
	"funcion.agrupada.105": "Intelligente Funktionen",
	"gamas.?": "",
	"gamas.agrupada.BE": "Baby",
	"gamas.agrupada.C": "Herren",
	"gamas.agrupada.IN": "Kinder",
	"gamas.agrupada.NA": "Madchen",
	"gamas.agrupada.NI": "Junge",
	"gamas.agrupada.S": "Damen",
	"gamas.agrupada.UN": "Unisex",
	"gamas.AN": "Ringe",
	"gamas.BE": "Baby",
	"gamas.C": "Herren",
	"gamas.CD": "Kinder",
	"gamas.CO": "Anhänger",
	"gamas.IN": "Kinder",
	"gamas.NA": "Kinder",
	"gamas.NI": "Kinder",
	"gamas.PE": "Damen",
	"gamas.PU": "ARMBÄNDER",
	"joyas.tipo.agrupada.ANILLO": "RING",
	"joyas.tipo.agrupada.BROCHE": "Brosche",
	"joyas.tipo.agrupada.CIERRE": "sluitingen",
	"joyas.tipo.agrupada.COLGANTE": "Anhänger",
	"joyas.tipo.agrupada.COLLAR": "HALSKETTE",
	"joyas.tipo.agrupada.CONJUNTO": "einstellen",
	"joyas.tipo.agrupada.GEMELOS": "Zwillinge",
	"joyas.tipo.agrupada.PENDIENTE_CADENA": "KETTENSCHLEIFE",
	"joyas.tipo.agrupada.PENDIENTE_TREPADOR": "KLETTERNDER ABHANG",
	"joyas.tipo.agrupada.PENDIENTES": "Ohrringe",
	"joyas.tipo.agrupada.PIERCING": "Piercing",
	"joyas.tipo.agrupada.PULSERA": "Armband",
	"joyas.tipo.agrupada.TOBILLERA": "Fußkettchen",
	"joyas.tipo.ANILLO": "RING",
	"joyas.tipo.BROCHE": "Brosche",
	"joyas.tipo.COLGANTE": "Anhänger",
	"joyas.tipo.COLLAR": "HALSKETTE",
	"joyas.tipo.CONJUNTO": "einstellen",
	"joyas.tipo.GEMELOS": "Zwillinge",
	"joyas.tipo.PENDIENTE_CADENA": "KETTENSCHLEIFE",
	"joyas.tipo.PENDIENTE_TREPADOR": "KLETTERNDER ABHANG",
	"joyas.tipo.PENDIENTES": "Ohrringe",
	"joyas.tipo.PIERCING": "Piercing",
	"joyas.tipo.PULSERA": "Armband",
	"joyas.tipo.sub.1-1": "LISA",
	"joyas.tipo.sub.1-2": "MIT STEINEN",
	"joyas.tipo.sub.1-3": "MIT DIAMANTEN",
	"joyas.tipo.sub.1-4": "LISA ALLIANCE",
	"joyas.tipo.sub.1-5": "LAPIDADA ALLIANCE",
	"joyas.tipo.sub.1-6": "Allianz mit Stein",
	"joyas.tipo.sub.1-7": "STEMPEL",
	"joyas.tipo.sub.1-8": "EMAILDRUCK",
	"joyas.tipo.sub.1-9": "DIAMOND ALLIANCE",
	"joyas.tipo.sub.1-10": "Drucken",
	"joyas.tipo.sub.1-11": "EMAILLE",
	"joyas.tipo.sub.1-12": "Entwurf",
	"joyas.tipo.sub.1-13": "PIDRA CRISTAL",
	"joyas.tipo.sub.1-14": "SWAROVSKI",
	"joyas.tipo.sub.2-1": "glatt",
	"joyas.tipo.sub.2-2": "MIT STEINEN",
	"joyas.tipo.sub.2-3": "SKAPULIER",
	"joyas.tipo.sub.2-4": "GLATTES KREUZ",
	"joyas.tipo.sub.2-5": "JUNGFRAU MÄDCHEN",
	"joyas.tipo.sub.2-6": "Pergament / Platte",
	"joyas.tipo.sub.2-7": "Kleine Engel",
	"joyas.tipo.sub.2-8": "KREUZ MIT STEINEN",
	"joyas.tipo.sub.2-9": "KREUZ MIT CHRISTUS",
	"joyas.tipo.sub.2-10": "FETISCH",
	"joyas.tipo.sub.2-11": "KREUZ MIT DIAMANTEN",
	"joyas.tipo.sub.2-12": "MIT DIAMANTEN",
	"joyas.tipo.sub.2-13": "FETISCH MIT STEINEN",
	"joyas.tipo.sub.2-14": "ELASTISCH",
	"joyas.tipo.sub.2-15": "Jungfrau",
	"joyas.tipo.sub.2-16": "CHRISTUS",
	"joyas.tipo.sub.2-17": "CRUZ CARAVACA",
	"joyas.tipo.sub.2-18": "EMAILLE",
	"joyas.tipo.sub.2-19": "Gemeinschaft",
	"joyas.tipo.sub.2-20": "Entwurf",
	"joyas.tipo.sub.2-21": "CRUZ CALADA",
	"joyas.tipo.sub.3-1": "CHATON",
	"joyas.tipo.sub.3-2": "Zirkonia",
	"joyas.tipo.sub.3-3": "FARBSTEIN",
	"joyas.tipo.sub.3-4": "EMAILLE",
	"joyas.tipo.sub.3-5": "ELEKTROFORMIERUNG",
	"joyas.tipo.sub.3-6": "PERLEN",
	"joyas.tipo.sub.3-7": "DU UND ICH",
	"joyas.tipo.sub.3-8": "RING",
	"joyas.tipo.sub.3-9": "Ball",
	"joyas.tipo.sub.3-10": "Verschiedene",
	"joyas.tipo.sub.3-11": "Keramik",
	"joyas.tipo.sub.3-12": "LANG MIT STEINEN",
	"joyas.tipo.sub.3-13": "RING",
	"joyas.tipo.sub.3-14": "EMAILLE",
	"joyas.tipo.sub.3-15": "MIT DIAMANTEN",
	"joyas.tipo.sub.3-16": "ELASTISCH",
	"joyas.tipo.sub.3-17": "STEINKOPF",
	"joyas.tipo.sub.3-18": "Drucken",
	"joyas.tipo.sub.3-19": "Entwurf",
	"joyas.tipo.sub.4-1": "LISA",
	"joyas.tipo.sub.4-2": "GRAVIERT",
	"joyas.tipo.sub.4-3": "Lockig",
	"joyas.tipo.sub.4-4": "CURLY MIT STEINEN",
	"joyas.tipo.sub.4-5": "GLATT MIT STEINEN",
	"joyas.tipo.sub.4-6": "GLATT MIT DIAMANTEN",
	"joyas.tipo.sub.4-7": "EMAILLE",
	"joyas.tipo.sub.4-8": "MIT HAUT",
	"joyas.tipo.sub.5-1": "1. POSITION MIT PLATTE",
	"joyas.tipo.sub.5-2": "Fein",
	"joyas.tipo.sub.5-3": "Brutto",
	"joyas.tipo.sub.5-4": "Dick fest mit Blatt",
	"joyas.tipo.sub.5-5": "Dick fest",
	"joyas.tipo.sub.5-6": "DICKER HOLLOW MIT PERLEN",
	"joyas.tipo.sub.5-7": "DICKES HOLLOW",
	"joyas.tipo.sub.5-8": "Dick mit Steinen",
	"joyas.tipo.sub.5-9": "RIVIERE",
	"joyas.tipo.sub.5-10": "DICKES HOLLOW MIT BLATT",
	"joyas.tipo.sub.5-11": "FEINES HOLLOW MIT PLATTE",
	"joyas.tipo.sub.5-12": "MIT STEIN",
	"joyas.tipo.sub.5-13": "BÄLLE",
	"joyas.tipo.sub.5-14": "BALL MIT STEIN",
	"joyas.tipo.sub.5-15": "1. POSITION",
	"joyas.tipo.sub.5-16": "FEINES HOLLOW",
	"joyas.tipo.sub.5-17": "FEINFEST MIT BLATT",
	"joyas.tipo.sub.5-18": "ELASTISCH",
	"joyas.tipo.sub.5-19": "MIT PERLEN",
	"joyas.tipo.sub.5-20": "LEDERRIEMEN",
	"joyas.tipo.sub.5-21": "MIT DIAMANTEN",
	"joyas.tipo.sub.5-22": "DIAMANT LEDER GURT",
	"joyas.tipo.sub.5-23": "MIT PERLEN",
	"joyas.tipo.sub.5-24": "GUMMI-GURT",
	"joyas.tipo.sub.6-1": "FEINES HOLLOW",
	"joyas.tipo.sub.6-2": "DICKES HOLLOW",
	"joyas.tipo.sub.6-3": "FEINFEST",
	"joyas.tipo.sub.6-4": "Dick fest",
	"joyas.tipo.sub.6-5": "GUMMI MIT VERSCHLUSS",
	"joyas.tipo.sub.6-6": "LEDER MIT SILBER",
	"joyas.tipo.sub.6-7": "LEDER MIT GOLDVERSCHLUSS",
	"joyas.tipo.sub.6-8": "HOLLOW LAPIDADO",
	"joyas.tipo.sub.6-9": "NYLON-SCHLIESSPLATTE",
	"joyas.tipo.sub.7-1": "HOLLOW LAPIDADO",
	"joyas.tipo.sub.7-2": "Fein mit Stein",
	"joyas.tipo.sub.7-3": "FINA MIT ANHÄNGER",
	"joyas.tipo.sub.7-4": "Ringe",
	"joyas.tipo.sub.7-5": "FEINER STEINANHÄNGER",
	"joyas.tipo.sub.7-6": "DICK MIT ANHÄNGER",
	"joyas.tipo.sub.7-7": "Fein",
	"joyas.tipo.sub.7-8": "BÄLLE",
	"joyas.tipo.sub.7-9": "FINA MIT EMAILLE ANHÄNGER",
	"joyas.tipo.sub.7-10": "Dick mit Steinen",
	"joyas.tipo.sub.7-11": "HOLLOW THICK",
	"joyas.tipo.sub.7-12": "FEINES HOLLOW",
	"joyas.tipo.sub.7-13": "GUMMI MIT ANHÄNGER",
	"joyas.tipo.sub.7-14": "FINA DIAMOND ANHÄNGER",
	"joyas.tipo.sub.7-15": "ELASTISCH",
	"joyas.tipo.sub.7-16": "Dick mit Diamanten",
	"joyas.tipo.sub.7-17": "LEDERSCHNUR",
	"joyas.tipo.sub.7-18": "KABEL MIT ANHÄNGER",
	"joyas.tipo.sub.7-19": "MIT PERLEN",
	"joyas.tipo.sub.8-1": "1. POSITION",
	"joyas.tipo.sub.8-2": "Krawatte",
	"joyas.tipo.sub.9-1": "FEINE GLATTE",
	"joyas.tipo.sub.9-2": "Dickes Glätten",
	"joyas.tipo.sub.9-3": "Fein mit Steinen",
	"joyas.tipo.sub.9-4": "Dick mit Steinen",
	"joyas.tipo.sub.9-5": "FEIN MIT DIAMANTEN",
	"joyas.tipo.sub.9-6": "Dick mit Diamanten",
	"joyas.tipo.sub.9-7": "BÄLLE",
	"joyas.tipo.sub.9-8": "RIVIERE",
	"joyas.tipo.sub.10-1": "glatt",
	"joyas.tipo.sub.10-2": "MIT STEINEN",
	"joyas.tipo.sub.10-3": "PERLEN",
	"joyas.tipo.sub.11-1": "glatt",
	"joyas.tipo.sub.11-2": "MIT STEINEN",
	"joyas.tipo.sub.12-1": "MIT DIAMANTEN",
	"joyas.tipo.sub.12-2": "glatt",
	"joyas.tipo.sub.13-1": "glatt",
	"joyas.tipo.sub.13-2": "MIT STEINEN",
	"joyas.tipo.sub.13-3": "MIT DIAMANTEN",
	"joyas.tipo.sub.14-1": "Druck",
	"joyas.tipo.sub.14-2": "Faden",
	"joyas.tipo.sub.15-1": "MIT PERLEN",
	"joyas.tipo.sub.17-1": "SCHRAUBENPAAR",
	"joyas.tipo.sub.25-1": "EINFACHER KLICK",
	"joyas.tipo.sub.99": "Nicht definiert",
	"joyas.tipo.sub.900-1": "9K GELB",
	"joyas.tipo.sub.900-2": "9K WEISS",
	"joyas.tipo.sub.900-3": "9K BICOLOR",
	"joyas.tipo.sub.undefined": "",
	"joyas.tipo.TOBILLERA": "Fußkettchen",
	"kilataje.ORO9K": "9K GOLD",
	"kilataje.ORO18K": "18K GOLD",
	"longpatillas.140": "140 mm",
	"longpatillas.142": "142 mm",
	"longpatillas.145": "145 mm",
	"material.9K": "375 ml 9 Karat Gold",
	"material.18K": "18K GOLD",
	"material.24K": "GUT GOLD",
	"material.?": "?",
	"material.AC": "Stahl",
	"material.AC-EB": "STAHL-EBAN",
	"material.ACAC": "STAHL UND STAHL",
	"material.ACBI": "STAHL-BICOLOR",
	"material.ACBR": "HELLER STAHL",
	"material.ACCA": "STAHL UND GUMMI",
	"material.ACCC": "Edelstahl & Zirkonia",
	"material.ACCE": "KERAMIKSTAHL",
	"material.ACCH": "Plattierter Stahl",
	"material.ACDI": "STAHLDIAMANTEN",
	"material.ACE": "Edelstahl 316l",
	"material.ACERO": "Edelstahl",
	"material.ACETA": "Acetate",
	"material.ACFC": "KOHLEFASER",
	"material.ACHB": "BICOLOUR PLATED STEEL",
	"material.ACHO": "Vergoldeter Stahl",
	"material.ACMA": "STAHL UND HOLZ",
	"material.ACOR": "STAHL UND GOLD",
	"material.ACPI": "STAHL UND LEDER",
	"material.ACPL": "STAHL UND KUNSTSTOFF",
	"material.ACTI": "STAHL TITAN",
	"material.AGCI": "SILBERZIRKONIEN",
	"material.AGES": "EMAILLIERTES SILBER",
	"material.AGG": "SILBER",
	"material.AGPE": "SILBER UND PERLEN",
	"material.agrupado.ACERO": "Stahl",
	"material.agrupado.ACETA": "ACETAT",
	"material.agrupado.CAUCHO": "Silikon",
	"material.agrupado.CERAMICA": "Keramiken",
	"material.agrupado.CUERO": "Leder",
	"material.agrupado.GOMA": "Silikon",
	"material.agrupado.METAL": "METAL",
	"material.agrupado.ORO": "GOLD",
	"material.agrupado.ORO9K": "GOLD9K",
	"material.agrupado.ORO18K": "GOLD18K",
	"material.agrupado.PLATA": "SILBER",
	"material.agrupado.TITANIO": "Titan",
	"material.AGS": "SWAROVSKI SILBER",
	"material.ALG": "BAUMWOLLE",
	"material.ALUM": "Aluminium",
	"material.ALZ": "Metall",
	"material.ANTE": "Vor",
	"material.BELC": "BELCRO",
	"material.BICO": "BICOLOR",
	"material.BRO": "Bronze",
	"material.C18K": "GUMMI + GOLDVERSCHLUSS",
	"material.CAAC": "GUMMISTAHL",
	"material.CAU": "Silikon",
	"material.CAUCHO": "Silikon",
	"material.CEBR": "KERAMIK-BRILLIANT",
	"material.CER": "Keramik",
	"material.CERAMICA": "Keramiken",
	"material.CHA": "Gemischt",
	"material.CHAP": "Überzogen",
	"material.CHBI": "BICOLOUR PLATED",
	"material.CHOR": "VERGOLDET",
	"material.COCO": "KROKODILE HAUT",
	"material.COCT": "COCO CUT",
	"material.CRBI": "BICOLOR CHROME",
	"material.CRMO": "Chrom",
	"material.CRO": "VERCHROMT",
	"material.CU": "Seil",
	"material.CUERO": "Leder",
	"material.FIB": "Ballaststoff",
	"material.GO": "Silikon",
	"material.GOCA": "GUMMI GUMMI",
	"material.GOM": "Silikon",
	"material.HNAU": "NAUTISCHER DRAHT",
	"material.LACH": "PLATED MESSING",
	"material.LACR": "CHROM MESSING",
	"material.LACT": "CUT LIZARD",
	"material.LAG": "Eidechse",
	"material.LAT": "Alloy",
	"material.LCHB": "BICOLOUR PLATED BRASS",
	"material.LIQ": "Flüssigkeit",
	"material.METAL": "METAL",
	"material.MIL": "MINERALVERGRÖSSERUNG",
	"material.MIN": "MINERAL",
	"material.MINP": "FLACHES MINERAL",
	"material.MINT": "Getöntes Mineral",
	"material.NEO": "NEOPREN",
	"material.NOB": "NOBUCK",
	"material.NY": "Nylon",
	"material.NYBE": "NYLON BELCRO",
	"material.NYCA": "NYLON GUMMI",
	"material.NYCT": "NYLON CUT",
	"material.NYL": "Nylon",
	"material.NYPC": "NYLON-LEDER OBER",
	"material.NYPI": "NYLON BASE LEDER",
	"material.O18": "18K GOLD",
	"material.OASP": "18K GOLD OHNE PREIS",
	"material.OB5N": "WEISSGOLD + 5N GOLD",
	"material.OBSP": "WEISSES GOLD OHNE PREIS",
	"material.OR18": "GOLD 18 KTES.",
	"material.OR24": "GOLD 24 KTES.",
	"material.ORO": "Gold",
	"material.OROB": "18K WEISSGOLD",
	"material.P+O": "LEDER + GOLDVERSCHLUSS",
	"material.P-CC": "LEDER C / CUT (7,5 x 4,2)",
	"material.PABA": "PAVE BALL",
	"material.PALD": "Palladium",
	"material.PAST": "Pasta",
	"material.PC": "KUNSTSTOFF + GLASFASER",
	"material.PEL": "PLEX MAGNIFIER",
	"material.PI": "Haut",
	"material.PI-C": "LEDER C / CUT (7,5 x 3,8)",
	"material.PICA": "Gummi Leder",
	"material.PICH": "PATENTLEDER",
	"material.PICT": "LEDER SCHNITT",
	"material.PIEL": "Leder",
	"material.PIEL-PU": "LEDER-POLYURETHAN",
	"material.PIGR": "GRAVIERTES LEDER",
	"material.PILI": "GLATTE HAUT",
	"material.PINA": "NAYLON LEDER",
	"material.PINY": "NYLON LEDER",
	"material.PIPL": "VERSTÄRKTE HAUT",
	"material.PIRA": "Gestreiftes Leder",
	"material.PISI": "SYNTHETISCHE HAUT",
	"material.PISIN": "SYNTHETISCHE HAUT",
	"material.PL": "PLEX",
	"material.PLA": "Plastik",
	"material.PLAA": "KUNSTSTOFFRING",
	"material.PLAL": "KUNSTSTOFF-VERGRÖSSERER",
	"material.PLAS": "Plastik",
	"material.PLAT": "Silber 925 ml",
	"material.PLATA": "Silber",
	"material.PLEX": "KUNSTSTOFFPLEX",
	"material.PLNY": "KUNSTSTOFF NYLON",
	"material.PLPI": "LEDERBASIS KUNSTSTOFF",
	"material.PLPL": "PLPL",
	"material.PLXL": "PLAST + MAGNIFIER",
	"material.POLICARBONATO": "POLYCARBONAT",
	"material.PU": "POLYURETHAN",
	"material.PUPI": "LEDERBASIS PURPURIN",
	"material.PVC": "PVC",
	"material.RAYA": "Gestreiftes Leder",
	"material.RH": "Rhodium",
	"material.RU": "RUTHENIUM",
	"material.SERP": "Schlange",
	"material.SPBP": "SYNTHETISCHE LEDERBASIS",
	"material.STBP": "SYNTHETISCHE LEDERBASIS",
	"material.SWA": "SWAROVSKI",
	"material.TELA": "Stoff",
	"material.TEPI": "LEDERBASISSTOFF",
	"material.TIBI": "BICOLOUR TITANIUM",
	"material.TIBU": "Haifischhaut",
	"material.TIT": "Titan",
	"material.TITANIO": "Titan",
	"material.TORT": "Schildkrötenhaut",
	"material.TUNG": "WOLFRAM",
	"material.undefined": "",
	"material.Z+L": "SAPPHIRE + MAGNIFIER",
	"material.Z-G": "ZAF + MAGNIFIER + GRAVUR",
	"material.ZAF": "Saphir",
	"material.ZAFP": "GEMALTES SAPHIR",
	"movimiento.funcion.1": "EINFACH (CAL)",
	"movimiento.funcion.4": "CHRONO (NO CAL)",
	"movimiento.funcion.12": "RESERVE-START",
	"movimiento.funcion.101": "Digital",
	"movimiento.funcion.102": "Multifunktion",
	"movimiento.funcion.103": "QUARZ",
	"movimiento.funcion.104": "Chronograph",
	"movimiento.funcion.105": "Digital",
	"movimiento.funcion.106": "Digital",
	"movimiento.funcion.107": "ANALOGER ALARM",
	"movimiento.funcion.108": "Multifunktion",
	"movimiento.funcion.109": "DOPPELZEIT",
	"movimiento.funcion.110": "Automatisch",
	"movimiento.funcion.111": "Tourbillon",
	"movimiento.funcion.112": "GMT",
	"movimiento.funcion.113": "RESERVE-START",
	"movimiento.funcion.agrupada.ANALO": "Analog",
	"movimiento.funcion.agrupada.AUTO": "Automatischs",
	"movimiento.funcion.agrupada.CRONO": "Chronograph",
	"movimiento.funcion.agrupada.DIGI": "Digital",
	"movimiento.funcion.agrupada.HYBRID": "Hybriden",
	"movimiento.funcion.agrupada.MULTI": "Multifunktion",
	"movimiento.funcion.agrupada.SMART": "Smartwatch",
	"movimiento.funcion.ANA-AL": "ANALOGER ALARM",
	"movimiento.funcion.ANA-DI": "DIGITAL ANALOG",
	"movimiento.funcion.ANA-DIGI": "DIGITAL ANALOG",
	"movimiento.funcion.AUT-AL": "AUTOMATISCHER ALARM",
	"movimiento.funcion.AUTO": "Automatischs",
	"movimiento.funcion.CRO-SUI": "SCHWEIZER CHRONOGRAFIE",
	"movimiento.funcion.CRO.SOL": "SOLAR CHRONO",
	"movimiento.funcion.CRONO": "Chronograph",
	"movimiento.funcion.CRONO-AL": "CHRONO-ALARM",
	"movimiento.funcion.DI-MULTI": "DIGITALE MULTIFUCION",
	"movimiento.funcion.DIGI": "Digital",
	"movimiento.funcion.DUAL-TIM": "DOPPELZEIT",
	"movimiento.funcion.HYBRID": "Hybriden",
	"movimiento.funcion.MULTI": "Multifunktion",
	"movimiento.funcion.QUTZ-ANA": "QUARTZ / ANALOG",
	"movimiento.funcion.SI": "einfach",
	"movimiento.funcion.SIM": "einfach",
	"movimiento.funcion.SIM-SUI": "EINFACH SCHWEIZER",
	"movimiento.funcion.SMART": "Smartwatch",
	"movimiento.tipo.01": "Digital",
	"movimiento.tipo.1": "EINFACH (CAL)",
	"movimiento.tipo.02": "DIGITAL ANALOG",
	"movimiento.tipo.03": "QUARTZ / ANALOG",
	"movimiento.tipo.04": "Automatisch",
	"movimiento.tipo.05": "HIBRID",
	"movimiento.tipo.06": "Smartwatch",
	"movimiento.tipo.07": "Solar",
	"movimiento.tipo.7": "MULTIF (CAL)",
	"movimiento.tipo.12": "RESERVE-START",
	"movimiento.tipo.ANA-AL": "ANALOGER ALARM",
	"movimiento.tipo.ANA-DI": "DIGITAL ANALOG",
	"movimiento.tipo.ANA-DIGI": "DIGITAL ANALOG",
	"movimiento.tipo.AUT-AL": "AUTOMATISCHER ALARM",
	"movimiento.tipo.AUTO": "Automatisch",
	"movimiento.tipo.AUTO-CRO": "AUTOMATISCHE CHRONOGRAFIE",
	"movimiento.tipo.AUTOM": "Automatisch",
	"movimiento.tipo.C": "Seil",
	"movimiento.tipo.CRO-SUI": "SCHWEIZER CHRONOGRAFIE",
	"movimiento.tipo.CRONO": "Chronograph",
	"movimiento.tipo.CUER-MEC": "SEIL / MECHANISCH",
	"movimiento.tipo.CUER.": "Seil",
	"movimiento.tipo.D": "Digital",
	"movimiento.tipo.DI": "Digital",
	"movimiento.tipo.DIGI": "Digital",
	"movimiento.tipo.DUAL-TIM": "DOPPELZEIT",
	"movimiento.tipo.F": "Solar",
	"movimiento.tipo.HIBRID": "HIBRID",
	"movimiento.tipo.ME.CRO.": "MECA.CRONO",
	"movimiento.tipo.ME.SIM.": "EINFACHE MECA",
	"movimiento.tipo.MULTI": "Multifunktion",
	"movimiento.tipo.MULTI.CR": "MULTI.CHRONO",
	"movimiento.tipo.PROF.": "TIEFENMESSER",
	"movimiento.tipo.QUTZ-ANA": "Quarz / Analog",
	"movimiento.tipo.SIM": "einfach",
	"movimiento.tipo.SIM-SUI": "EINFACH SCHWEIZER",
	"movimiento.tipo.SMART": "Smartwatch",
	"movimiento.tipo.TOURBILLON": "TOURBILLON",
	"numeraciones.1A+AP": "1AR.AP + AR.PI",
	"numeraciones.1A+CC": "1AR.AP + CRIRC",
	"numeraciones.1A+IA": "1AR.AP + IN.AP",
	"numeraciones.1A+IP": "1AR.AP + IN.PI",
	"numeraciones.1A.AP": "1AR.AP",
	"numeraciones.1A.PI": "1AR.PI",
	"numeraciones.1AA": "1AR.AP",
	"numeraciones.1AP+I": "1AR.PI + IN.AP.",
	"numeraciones.1AP+P": "1AR.PI + IN.PI",
	"numeraciones.1BR+I": "1BRILL + IN.AP",
	"numeraciones.1CC": "1CIRC",
	"numeraciones.1CC+I": "1CIRCO + IN.AP.",
	"numeraciones.1CC+IA": "1CIRC + IN.AP.",
	"numeraciones.1CIR+I": "1CIRCO + IN.AP.",
	"numeraciones.1D+IN": "1DIAM + IND APLI",
	"numeraciones.1I.AP": "1IN.AP",
	"numeraciones.1IA": "1IN.AP",
	"numeraciones.1IP": "1IN.PI",
	"numeraciones.1R+IA": "1RO.AP + IN.AP.",
	"numeraciones.1R+IP": "1RO.AP + IN.PI",
	"numeraciones.1R+RP": "1RO.AP + RO.PI",
	"numeraciones.1R.AP": "1RO.AP",
	"numeraciones.1R.P": "1RO.PI",
	"numeraciones.1R.PI": "1RO.PI",
	"numeraciones.1RA": "1RO.AP.",
	"numeraciones.1RA+P": "1R.APL + RO.PIN.",
	"numeraciones.1RP+I": "1RO.PI + IN.AP.",
	"numeraciones.1RP+P": "1RO.PI + IN.PI",
	"numeraciones.2A+AP": "2AR.AP + AR.PI",
	"numeraciones.2A+CC": "2AR.AP + CIRC",
	"numeraciones.2A+IA": "2AR.AP + IN.AP.",
	"numeraciones.2A+IP": "2AR.AP + IN.PI",
	"numeraciones.2A.AP": "2AR.AP",
	"numeraciones.2A.PI": "2AR.PI",
	"numeraciones.2AA+2RA": "2AR.AP + 2RO.AP.",
	"numeraciones.2AP+2RP": "2AR.PI + 2RO.PI",
	"numeraciones.2AP+ARP": "2AR.PI + 2RO.PI",
	"numeraciones.2AP+I": "2AR.PI + IN.AP.",
	"numeraciones.2AP+P": "2AR.PI + IN.PI",
	"numeraciones.2CC": "2CIRC.",
	"numeraciones.2CC+I": "2CIRC + IN.AP.",
	"numeraciones.2CC+IA": "2CIRC + IN.AP.",
	"numeraciones.2I+RP": "2IN.AP + 10RO.PI",
	"numeraciones.2I.AP": "2IN.AP",
	"numeraciones.2IA": "2IN.AP",
	"numeraciones.2IA+P": "2IN.AP + IN.PI.",
	"numeraciones.2IP": "2IN.PI",
	"numeraciones.2R+CC": "2RO.AP + CIRC",
	"numeraciones.2R+IA": "2RO.AP + IN.AP",
	"numeraciones.2R+IP": "2RO.AP + IN.PI",
	"numeraciones.2R+RP": "2RO.AP + RO.PI",
	"numeraciones.2R.AP": "2RO.AP",
	"numeraciones.2R.P": "2RO.PI.",
	"numeraciones.2R.PI": "2RO.PI",
	"numeraciones.2RA": "2ROM.APL.",
	"numeraciones.2RP+C": "2RO.PI + CIRC",
	"numeraciones.2RP+I": "2RO.PI + IN.AP.",
	"numeraciones.2RP+P": "2RO.PI + IN.PI",
	"numeraciones.3A+AP": "3AR.AP + AR.PI",
	"numeraciones.3A+IA": "3AR.AP + IN.AP",
	"numeraciones.3A+IP": "3AR.AP + IN.PI",
	"numeraciones.3A.AP": "3AR.AP",
	"numeraciones.3A.PI": "3AR.PI",
	"numeraciones.3AA+P": "3ARA.A + 8ARA.PIN.",
	"numeraciones.3AP+I": "3AR.PI + IN.AP.",
	"numeraciones.3AP+P": "3AR.PI + IN.PI",
	"numeraciones.3CC": "3CIRC.",
	"numeraciones.3CC+IA": "3CIRC + IN.AP.",
	"numeraciones.3I+RP": "3IN.AP + RO.PI",
	"numeraciones.3I.AP": "3IN.AP",
	"numeraciones.3IA": "3IN.AP",
	"numeraciones.3IP": "3IN.PI",
	"numeraciones.3R+CC": "3ROM + ZIRCONS",
	"numeraciones.3R+IA": "3RO.AP + IN.AP.",
	"numeraciones.3R+IP": "3RO.AP + IN.PI",
	"numeraciones.3R+IR": "3/4 RÖMER + IND.APL",
	"numeraciones.3R+RP": "3RO.AP + RO.PI",
	"numeraciones.3R.AP": "3RO.AP",
	"numeraciones.3R.P": "3RO.PI.",
	"numeraciones.3R.PI": "3RO.PI",
	"numeraciones.3RA+CC": "3RA.AP + CC",
	"numeraciones.3RA+P": "3RO.AP + 8RO.PI.",
	"numeraciones.3RE+E": "3ROM.EST + IND.ESTAMP.",
	"numeraciones.3RP+I": "3RO.PI + IN.AP.",
	"numeraciones.3RP+P": "3RO.PI + IN.PI",
	"numeraciones.4A+4A": "4AR.PI + 4A.PI",
	"numeraciones.4A+4AP": "4AR.AP + 4AR.P",
	"numeraciones.4A+4R": "4AR + IA / 4R + IA.",
	"numeraciones.4A+AP": "4AR.AP + AR.PI",
	"numeraciones.4A+CC": "4ARA.AP + CIRC",
	"numeraciones.4A+IA": "4AR.AP + IN.AP",
	"numeraciones.4A+IP": "4AR.AP + IN.PI",
	"numeraciones.4A+P": "4ARP + IND.PIN.",
	"numeraciones.4A.AP": "4AR.AP",
	"numeraciones.4A.PI": "4AR.PI",
	"numeraciones.4AA+6": "4AR.A + 6AR.P",
	"numeraciones.4AA+P": "4ARA.A + 8ARA.PIN.",
	"numeraciones.4AP+I": "4AR.PI + IN.AP.",
	"numeraciones.4AP+P": "4AR.PI + IN.PI",
	"numeraciones.4AR.P": "4ARA.PINT + IND.PIN.",
	"numeraciones.4CC": "4CIRC.",
	"numeraciones.4DIA": "4 DIAMANTEN",
	"numeraciones.4I+CC": "4IN.AP + CIRC",
	"numeraciones.4IA": "4IN.AP",
	"numeraciones.4IA+P": "4IN.AP + IN.PI",
	"numeraciones.4IN.A": "4 ANGEWANDTE INDIZES",
	"numeraciones.4INDS": "4 INDIZES",
	"numeraciones.4IP": "4IN.PI",
	"numeraciones.4R+CC": "4 ROM. + CIRC.",
	"numeraciones.4R+DI": "/4ROM.APLIC.+8DIAM",
	"numeraciones.4R+IA": "4RO.AP + IN.AP.",
	"numeraciones.4R+IP": "4RO.AP + IN.PI",
	"numeraciones.4R+RP": "4RO.AP + RO.PI",
	"numeraciones.4R.AP": "4RO.AP",
	"numeraciones.4R.P": "4RO.PI.",
	"numeraciones.4R.PI": "4RO.PI",
	"numeraciones.4RA+I": "4 / ROMAN.APL. + IND.APL",
	"numeraciones.4RA+P": "4RO.AP + 8RO.PI",
	"numeraciones.4RE+E": "4RO.EST + IN.EST.",
	"numeraciones.4ROM": "4 RÖMER",
	"numeraciones.4RP+I": "4RO.PI + IN.AP.",
	"numeraciones.4RP+P": "4RO.PI + IN.PI",
	"numeraciones.5A+5CC": "5AR.AP + 5CIRC",
	"numeraciones.5A+AP": "5AR.AP + AR.PI",
	"numeraciones.5A+IA": "5AR.AP + IN.AP.",
	"numeraciones.5A+IP": "5AR.AP + IN.PI",
	"numeraciones.5A.AP": "5AR.AP",
	"numeraciones.5A.PI": "5AR.PI",
	"numeraciones.5AP+I": "5AR.PI + IN.AP.",
	"numeraciones.5AP+P": "5AR.PI + IN.PI",
	"numeraciones.5CC": "5CIRC.",
	"numeraciones.5D+IA": "5 DIAM + IND. APLI",
	"numeraciones.5DIAM": "5 DIAMANTEN",
	"numeraciones.5IA": "5IN.AP",
	"numeraciones.5IP": "5IN.PI",
	"numeraciones.5R+IA": "5RO.AP + IN.AP.",
	"numeraciones.5R+IP": "5RO.AP + IN.PI",
	"numeraciones.5R+RP": "5RO.AP + RO.PI",
	"numeraciones.5R.AP": "5RO.AP",
	"numeraciones.5R.PI": "5RO.PI",
	"numeraciones.5RP+I": "5RO.PI + IN.AP.",
	"numeraciones.5RP+P": "5RO.PI + IN.PI",
	"numeraciones.6A+AP": "6AR.AP + AR.PI",
	"numeraciones.6A+CC": "6AR.AP + CIRC.",
	"numeraciones.6A+IA": "6AR.AP + IN.AP",
	"numeraciones.6A+IP": "6AR.AP + IN.PI",
	"numeraciones.6A.AP": "6AR.AP",
	"numeraciones.6A.PI": "6AR.PI",
	"numeraciones.6AP+I": "6AR.PI + IN.AP.",
	"numeraciones.6AP+P": "6AR.PI + IN.PI.",
	"numeraciones.6CC.": "6CIRC.",
	"numeraciones.6IA": "6IN.AP",
	"numeraciones.6IP": "6IN.PI",
	"numeraciones.6R+IA": "6RO.AP + IN.AP.",
	"numeraciones.6R+IP": "6RO.AP + IN.PI",
	"numeraciones.6R+RP": "6RO.AP + RO.PI",
	"numeraciones.6R.AP": "6RO.AP",
	"numeraciones.6R.P": "6RO.PIN.",
	"numeraciones.6RP+I": "6RO.PI + IN.AP.",
	"numeraciones.6RP+P": "6RO.PI + IN.PI",
	"numeraciones.7A+IA": "7AR.AP + IN.AP",
	"numeraciones.7AR.P": "7AR.P",
	"numeraciones.7DIA": "7 DIAMANTEN",
	"numeraciones.8A+IA": "8AR.AP + IN.AP",
	"numeraciones.8AP": "8ARA.PIN.",
	"numeraciones.8AP+P": "8ARA.PIN + IND.PIN.",
	"numeraciones.8R+IA": "8RO.AP + IN.AP.",
	"numeraciones.8RP+P": "8RO.PI + IN.PI.",
	"numeraciones.9ARAB": "9 ARABEN",
	"numeraciones.10ARA": "10 ARABES",
	"numeraciones.A+R.P": "ARA + ROM.PIN.",
	"numeraciones.AA+AP": "ARA PINT + ARA APLIC",
	"numeraciones.AP+AA": "ARA PINT + ARA APLIC",
	"numeraciones.AP+IA": "ARA.PIN + IND.A.",
	"numeraciones.AP+IP": "AR.PI + IN.PI",
	"numeraciones.AR+DI": "RA. + 10 DIAMANTEN",
	"numeraciones.AR.AP": "ANGEWANDTE ARABEN",
	"numeraciones.AR.PI": "GEMALTE ARABEN",
	"numeraciones.AR4/4": "ARABES 4/4 + INDIZES",
	"numeraciones.ARA.A": "ANGEWANDTE ARABEN",
	"numeraciones.ARA.E": "DRUCK ARABES",
	"numeraciones.ARA.P": "GEMALTE ARABEN",
	"numeraciones.ARAB": "ARABES",
	"numeraciones.ARABE": "ARABES 12/12",
	"numeraciones.BAND": "Flaggen",
	"numeraciones.BOLAS": "2N18 BÄLLE",
	"numeraciones.CIRC": "Zirkonia",
	"numeraciones.CR": "SWAROVSKI KRISTALL",
	"numeraciones.DIAM": "12 DIAMANTEN",
	"numeraciones.DIAMG": "GROSSE DIAMANTEN",
	"numeraciones.DIAN": "DIGITAL ANALOG",
	"numeraciones.DIAR": "DIGITALE ARABEN",
	"numeraciones.DIG": "Digital",
	"numeraciones.DIIN": "DIGITALE INDIZES",
	"numeraciones.FANT": "Fantasie",
	"numeraciones.FL": "Blumen",
	"numeraciones.IN+CC": "IND + ZIRCONS",
	"numeraciones.IN.AP": "ANGEWANDTE INDIZES",
	"numeraciones.IN.PI": "GEMALTE INDEXE",
	"numeraciones.INAR": "12INDIC + 12ARAB",
	"numeraciones.IND.A": "ANGEWANDTE INDIZES",
	"numeraciones.IND.E": "IND.EST.",
	"numeraciones.IND.P": "GEMALTE INDUSTRIEN",
	"numeraciones.INDIC": "Indizes",
	"numeraciones.INFAN": "FANTASIE-INDIZES",
	"numeraciones.INPIN": "IND.PIN.",
	"numeraciones.INRO": "12 INDIZES + 12 ROMAN",
	"numeraciones.IP+AP": "12IN.PI + 12AR.PI",
	"numeraciones.NEO": "TRÄGT NICHT",
	"numeraciones.RA+RP": "4ROM APLI + 4ROM PIN",
	"numeraciones.RO+IN": "RÖMER 12 + 10 INDEX",
	"numeraciones.RO.AP": "ANGEWANDTE RÖMER",
	"numeraciones.RO.PI": "GEMALTE RÖMER",
	"numeraciones.RO2/2": "RÖMER 2/2 + INDEX",
	"numeraciones.RO4/4": "RÖMER 4/4 + INDIZES",
	"numeraciones.ROES": "ROM.STAMPINGS",
	"numeraciones.ROM": "RÖMER",
	"numeraciones.ROM.A": "RÖMER ANWENDUNGEN",
	"numeraciones.ROM.E": "Römer gestempelt",
	"numeraciones.ROM.P": "GEMALTE RÖMER",
	"numeraciones.ROMAN": "12/12 RÖMER",
	"numeraciones.RP+IA": "12ROM.PIN + 12IND.A.",
	"numeraciones.RP+IP": "12RO.PI + 12IN.PI",
	"piedras.tipo.agrupada.ABA": "BEADS VERSCHIEDENE FORMEN",
	"piedras.tipo.agrupada.ACBA": "STAHL BALL",
	"piedras.tipo.agrupada.AGA": "ACHAT",
	"piedras.tipo.agrupada.AGBA": "SILBERKUGEL",
	"piedras.tipo.agrupada.AMA": "Amethyst",
	"piedras.tipo.agrupada.AMAN": "AMANZONIT",
	"piedras.tipo.agrupada.ARCI": "Silberring mit Zirkonen",
	"piedras.tipo.agrupada.ARGO": "RING",
	"piedras.tipo.agrupada.AVEN": "Abenteuer",
	"piedras.tipo.agrupada.BAEN": "BAR SET ZIRCONIA",
	"piedras.tipo.agrupada.BAPA": "PAVE BAR",
	"piedras.tipo.agrupada.BGSP": "BAGUES OHNE PREIS",
	"piedras.tipo.agrupada.BOL": "Silberball mit Facetten",
	"piedras.tipo.agrupada.BRI": "SPARKLY",
	"piedras.tipo.agrupada.BS": "BS",
	"piedras.tipo.agrupada.BUTT": "KULTIVIERTE PERLENKNOPF",
	"piedras.tipo.agrupada.CALC": "CHALZEDON",
	"piedras.tipo.agrupada.CER": "Keramik",
	"piedras.tipo.agrupada.CIR": "GELBE ZIRKONIE (CZ-YE1)",
	"piedras.tipo.agrupada.CIRC": "SET ZIRCONIA",
	"piedras.tipo.agrupada.COR": "CORNELIAN",
	"piedras.tipo.agrupada.CRBA": "Kristallkugel",
	"piedras.tipo.agrupada.CRI": "Kristall",
	"piedras.tipo.agrupada.CRPA": "CRUZ PAVE",
	"piedras.tipo.agrupada.CUAR": "QUARZ",
	"piedras.tipo.agrupada.DIAM": "Diamant",
	"piedras.tipo.agrupada.DISP": "0",
	"piedras.tipo.agrupada.ESMA": "EMAILLE",
	"piedras.tipo.agrupada.ESP": "SPINEL",
	"piedras.tipo.agrupada.FERI": "Urlaub",
	"piedras.tipo.agrupada.G030": "FO1030",
	"piedras.tipo.agrupada.G031": "FO1031",
	"piedras.tipo.agrupada.G034": "BRILLANT",
	"piedras.tipo.agrupada.G036": "FO1036",
	"piedras.tipo.agrupada.G050": "FO1050",
	"piedras.tipo.agrupada.GI10": "STEIN 0,90",
	"piedras.tipo.agrupada.GI11": "38640",
	"piedras.tipo.agrupada.GI12": "MOD FO282",
	"piedras.tipo.agrupada.GI13": "SHOCKWAVE CRIMPED BEZEL",
	"piedras.tipo.agrupada.GI14": "STEINE 1,6DIAM",
	"piedras.tipo.agrupada.GI15": "STEIN 0,80",
	"piedras.tipo.agrupada.GIL": "DIAM ENGAS MIGUEL GIL 0.015K",
	"piedras.tipo.agrupada.GIL1": "STEINE 1,20 + 1,50",
	"piedras.tipo.agrupada.GIL2": "FO436",
	"piedras.tipo.agrupada.GIL3": "DIAMANT 1.10",
	"piedras.tipo.agrupada.GIL4": "STEINMODELL LO314",
	"piedras.tipo.agrupada.GIL5": "STEINE MODELL FO323",
	"piedras.tipo.agrupada.GIL6": "STEINE VON 1,20",
	"piedras.tipo.agrupada.GIL7": "STEINE 1.40",
	"piedras.tipo.agrupada.GIL8": "STEINE 1.30",
	"piedras.tipo.agrupada.GIL9": "STEIN 1.00",
	"piedras.tipo.agrupada.GLAM": "GLAM",
	"piedras.tipo.agrupada.HEMA": "HEMATIT",
	"piedras.tipo.agrupada.JADE": "JADE",
	"piedras.tipo.agrupada.LABR": "LABRADORIT",
	"piedras.tipo.agrupada.LAPI": "LAPISLAZULI",
	"piedras.tipo.agrupada.LAVA": "LAVA BALL",
	"piedras.tipo.agrupada.MP": "MUTTER PERLE",
	"piedras.tipo.agrupada.NAC": "Perlmutt",
	"piedras.tipo.agrupada.OJGA": "KATZENAUGE",
	"piedras.tipo.agrupada.ONI": "ONYX",
	"piedras.tipo.agrupada.OPPE": "Peruanischer Opal",
	"piedras.tipo.agrupada.OTIG": "TIGERS AUGE (GARNET)",
	"piedras.tipo.agrupada.OTUR": "TÜRKISCHES AUGE",
	"piedras.tipo.agrupada.PABA": "PAVE BALL",
	"piedras.tipo.agrupada.PEBA": "Kultivierte Barockperle",
	"piedras.tipo.agrupada.PECU": "ZUCHTPERLE",
	"piedras.tipo.agrupada.PERL": "NATÜRLICHE PERLE",
	"piedras.tipo.agrupada.PESY": "SYNTHETISCHE PERLE",
	"piedras.tipo.agrupada.PILU": "MONDSTEIN",
	"piedras.tipo.agrupada.QURS": "ROSA QUARTZ",
	"piedras.tipo.agrupada.RES": "Harzball",
	"piedras.tipo.agrupada.RESI": "Harz",
	"piedras.tipo.agrupada.RORU": "RHODIUM RUTENIUM",
	"piedras.tipo.agrupada.RUB": "Rubin",
	"piedras.tipo.agrupada.SWA": "SWAROVSKI",
	"piedras.tipo.agrupada.SZA1": "STEIN 1.50",
	"piedras.tipo.agrupada.SZA2": "STEIN 1.20",
	"piedras.tipo.agrupada.TUCI": "ZIRCONIA TUBE",
	"piedras.tipo.agrupada.TUR": "Türkis",
	"producto.coleccion": "Kollektionen",
	"producto.tipo.accesorio": "schreibwaren",
	"producto.tipo.joya": "Juwel",
	"producto.tipo.reloj": "Uhr",
	"tipo.movimiento.agrupado.01": "Digital",
	"tipo.movimiento.agrupado.02": "QUARZ-DIGITAL",
	"tipo.movimiento.agrupado.03": "Handbuch",
	"tipo.movimiento.agrupado.04": "RESERVE-MÄRZ",
	"tipo.movimiento.agrupado.05": "Hybrid",
	"tipo.movimiento.agrupado.06": "SMARTWATCH",
	"tipo.movimiento.agrupado.07": "QUARZ",
	"tipolente.POLARIZED": "Polarisiert"
}
