import { APPEnv, CMSEnv, IAppConfig, Language, ShopCode } from '@fgp/shared/interfaces';

/* eslint-disable @typescript-eslint/no-var-requires */
export const environment: IAppConfig = {
  version: require('../../../../package.json').version,
  appVersion: require('../../../../package.json').version + '-prod',
  appEnv: APPEnv.PROD,
  production: true,
  apiPublicUrl: 'https://fcloud.festina.com/public/dmz/services/',
  JWTDomainPublic: 'fcloud.festina.com',
  tiendaCode: ShopCode.Perrelet,
  tiendaCodigoAES: 'f40506230b39a1e92f40579ae576d171',
  tiendaNombre: 'perrelet.com',
  appName: 'perrelet',
  domain: 'https://perrelet.com',
  cms: {
    local: false,
    endpoint: 'https://cms-swiss.festinagroup.com',
    bucket: 'bkt-festina-swiss-cms-strapi-env-prj-p-platform-ecommerce-1ef3',
    preview: false,
    env: CMSEnv.prod
  },
  imageConversor: {
    url: 'https://imageconv.festinagroup.com/convert?code=',
    basePath: 'festina-swiss-cms'
  },
  translateDomain: 'https://perrelet.com',
  imgServerUrl: 'https://static6.festinagroup.com/product/',
  urlServidorEstatico: 'https://media6.festinagroup.com/static/tiendas_online/',
  imgServerUrlLogoTienda: 'https://media6.festinagroup.com/static/tiendas_online/logos/',
  imgServerUrlHomeImages: 'https://media6.festinagroup.com/static/tiendas_online/perrelet/images/home',
  imgServerUrlMenuImages: 'https://media6.festinagroup.com/static/tiendas_online/perrelet/images/menu',
  imgServerUrlImages: 'https://media6.festinagroup.com/static/tiendas_online/perrelet/images',
  imgServerUrlLogoImages: 'https://media6.festinagroup.com/static/tiendas_online/perrelet/images/logos',
  imgServerUrlVideos: 'https://media6.festinagroup.com/static/tiendas_online/perrelet/videos',
  imgServerUrlSmartWatch: 'https://media6.festinagroup.com/static/tiendas_online/perrelet/smartwatch',
  apiDescargaManualesUrl: 'https://static6.festinagroup.com/product',
  imgServerUrlMapaImages: 'https://media6.festinagroup.com/static/tiendas_online/perrelet/images/mapa',
  imgServerUrlImagesPromociones: 'https://media6.festinagroup.com/static/tiendas_online/perrelet/images/promotions',
  documentsServerUrl: 'https://media6.festinagroup.com/static/tiendas_online/perrelet/documents',
  imgServerUrlSharedImages: 'https://media6.festinagroup.com/static/tiendas_online/shared/images',
  imgServerUrlSharedVideos: 'https://media6.festinagroup.com/static/tiendas_online/shared/videos',
  imgServerUrlPhotoReview: 'https://media6.festinagroup.com/static/logistica/photo-review',
  numeroProductosPorPagina: 12,
  modalSubscribe: false,
  modalSubscribeTime: 8000,
  // El primero de cada grupo de idiomas indica el que se escogerá por defecto
  idiomasAplicacion: [Language.English, Language.Spanish, Language.French, Language.German],
  idiomaPorDefecto: Language.English,
  countrydefault: 'ES',
  idiomaCulturalSeparador: '-',
  reCaptcha: {
    enabled: true,
    siteKey: '6LfbRNIpAAAAAO4vSLr7H6XpCYeu77oXtY1-HTOm'
  },
  payPal: {
    messages: true,
    clientId: 'AcZmpdN7_YUz8eXs6bhmloQs4TLU10KO2JBpCn7527YbXQVt0O8NQLNws_T7pCsX2wBzEk9J_jJlRzeE'
  },
  stripe: {
    key: 'pk_live_51NnNKEDSaP4VoijmbnbbTl0HsrIpofCe5vHYfMjiFzwXtbhQq5tnqj2vazddzCEZLhbmQTIWtfc0weH5EYwd3Nwn00wpx9EtdP'
  },
  google: {
    tagManagerId: 'GTM-T2PXHMD',
    googleTagManagerAuth: 'i-UntmiUlbyBzk7m0L0wOQ',
    googleTagManagerPreview: 'env-1'
  },
  googleMaps: {
    key: 'AIzaSyCd3Kz19yz8NGrCBMnrGwUluWkOlYJGUhQ'
  },
  facebook: {
    appId: '125579485409570'
  },
  fileUpload: {
    extensionesImagenes: ['jpg', 'png'],
    extensionesFicheros: ['pdf'],
    tamanyoMaximo: 9000000
  },
  imgDefaultLazyLoading: 'https://media1.festina.com/static/tiendas_online/logos/festina.svg',
  urlInterceptorErrorEscaped: ['sfileserver.festina.com/Manual'],
  patronTelefono: '[0-9]+',
  seo: {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Festina',
    logo: 'https://perrelet.com/favicon.ico',
    address: 'VELAZQUEZ, 150 3. 1. 28002 MADRID (SPAIN)',
    priceCurrency: 'EUR',
    facebook: 'https://www.facebook.com/festinaes/',
    instagram: 'https://www.instagram.com/festina.watches/',
    email: 'atencioncliente@festina.com',
    wiki: 'https://es.wikipedia.org/wiki/Festina'
  },
  fiestasNacionales: ['01-01', '01-06', '05-01', '06-01', '06-24', '08-06', '08-15', '09-11', '10-12', '12-08', '12-25', '12-26'],
  limiteDiasRespuestaIncidencia: 15,
  googleMerchantId: '285696531'
};
