{
  "accesorio": {
    "h1": "Accesorios",
    "meta": "Encuentra el regalo perfecto para cualquier ocasión o date un capricho y luce los mejores accesorios. Visita ahora nuestro catálogo. {{numPagina}}",
    "title": "Accesorios | Perrelet"
  },
  "accesorio.default": {
    "h1": "Accesorios {{tipoAccesorio}} {{subtipoAccesorio}} {{tipoBoligrafo}}",
    "meta": "Encuentra el regalo perfecto para cualquier ocasión o date un capricho y luce los mejores accesorios. Visita ahora nuestro catálogo. {{numPagina}}",
    "robots": "false",
    "title": "Accesorios {{tipoAccesorio}} {{subtipoAccesorio}} {{tipoBoligrafo}} | Perrelet"
  },
  "accesorio.LEATHER_ITEM.CARD_HOLDER": {
    "h1": "Tarjeteros de piel",
    "meta": "La mejor opción para llevar tus tarjetas organizadas es contar con nuestros Tarjeteros de piel fabricados con el material más resistente y elegante.",
    "title": "Tarjeteros de piel | Perrelet"
  },
  "accesorio.LEATHER_ITEM.WALLET": {
    "h1": "Carteras de piel",
    "meta": "Nuestras Carteras de piel son perfectas para regalar. Elaboradas con un material resistente y de varios colores son ideales para llevar donde quieras.",
    "title": "Carteras de piel | Perrelet"
  },
  "accesorio.subtipoAccesorio.default": {
    "h1": "{{subtipoAccesorio}}",
    "meta": "Descubre nuestra selección de {{subtipoAccesorio}} en nuestra tienda online oficial.",
    "title": "{{subtipoAccesorio}} | Perrelet"
  },
  "accesorio.subtipoAccesorio.SOLAR": {
    "h1": "Gafas de sol",
    "meta": "Descubre nuestra selección de Gafas de sol en nuestra tienda online oficial.",
    "title": "Gafas de sol | Perrelet"
  },
  "accesorio.tipoAccesorio.default": {
    "h1": "Accesorios {{tipoAccesorio}}",
    "meta": "De entre nuestra selección de Acessorios Perrelet, encuentra el regalo ideal para obsequiar en ocasiones puntuales o para darte un capricho a ti mismo.",
    "title": "Accesorios {{tipoAccesorio}} | Perrelet"
  },
  "accesorio.tipoAccesorio.KEYCHAIN": {
    "h1": "Llaveros",
    "meta": "Los llaveros Perrelet son un accesorio indispensable para complementar tu estilo. Son elegantes y modernos y dan un toque distintivo a quien los lleve.",
    "title": "Llaveros | Perrelet"
  },
  "accesorio.WRITING.BALLPOINT_PEN": {
    "h1": "Bolígrafos ballpoint",
    "meta": "Nuestros bolígrafos ballpoint se han convertido en los bolígrafos más cómodos y fáciles para escribir. Además, presentan un diseño sutil y exclusivo.",
    "title": "Bolígrafos ballpoint | Perrelet"
  },
  "accesorio.WRITING.FOUNTAIN_PEN": {
    "h1": "Plumas Estilográficas",
    "meta": "Dale un toque personal a tu caligrafía con nuestras Plumas Estilográficas. En nuestro catálogo podrás encontrarlas en diferentes colores y diseños.",
    "title": "Plumas Estilográficas | Perrelet"
  },
  "accesorio.WRITING.REPLACEMENT": {
    "h1": "Recambios para bolígrafos",
    "meta": "En nuestra tienda online también podrás adquirir Recambios de bolígrafos, y comprarlos por en packs de dos unidades para tenerlos siempre a mano.",
    "title": "Recambios para bolígrafos | Perrelet"
  },
  "accesorio.WRITING.ROLLERBALL_PEN": {
    "h1": "Bolígrafos rollerball",
    "meta": "Conoce nuestros bolígrafos rollerball, bolígrafos de lujo con un diseño elegante y discreto, perfectos para obsequiar en momentos especiales.",
    "title": "Bolígrafos rollerball | Perrelet"
  },
  "reloj": {
    "h1": "Relojes Perrelet",
    "meta": "Descubre nuestra selección de Relojes Perrelet únicos y Accesorios de Escritura originales en nuestra tienda online oficial.",
    "title": "PERRELET | Relojes para Hombre y Mujer | Tienda Online Oficial"
  },
  "reloj.C": {
    "h1": "Relojes para Hombre",
    "meta": "Nuestros Relojes para Hombre son perfectos para cualquier ocasión. De diseño singular, son ideales para complementar un estilo elegante y único. {{numPagina}}",
    "title": "Relojes para Hombre {{numPagina}} | Perrelet"
  },
  "reloj.colorEsfera.default": {
    "h1": "Relojes con Esfera {{ colorEsfera }}",
    "meta": "¿Buscas un reloj único y elegante? Descubre nuestros relojes con esfera {{ colorEsfera }} de las mejores marcas y encuentra el modelo perfecto para ti. ",
    "title": "Relojes con Esfera {{ colorEsfera }} | Perrelet"
  },
  "reloj.default": {
    "h1": "Relojes {{marca}} {{funcion}} {{estilos}} {{colorCorrea}} {{colorEsfera}} {{materialCaja}} {{materialCorrea}}",
    "meta": "¿Buscas un reloj único y elegante? Descubre nuestros relojes de las mejores marcas y encuentra el modelo perfecto para ti. {{numPagina}}",
    "robots": "false",
    "title": "Relojes {{gama}} {{marca}} {{funcion}} {{estilos}} {{colorCorrea}} {{colorEsfera}} {{materialCaja}} {{materialCorrea}} {{numPagina}} | Perrelet"
  },
  "reloj.funcion.default": {
    "h1": "Relojes {{funcion}}",
    "meta": "¿Buscas un Reloj {{funcion}}? Encuentra los mejores modelos de las mejores marcas y escoge el reloj perfecto para ti. Visita nuestro catálogo ahora {{numPagina}}",
    "title": "Relojes {{funcion}} {{numPagina}} | Perrelet"
  },
  "reloj.gama.colorCorrea.default": {
    "h1": "Relojes {{colorCorrea}} para {{gama}}",
    "meta": "Disponemos de una extensa selección de colores y materiales para que escojas los Relojes {{colorCorrea}} para {{gama}} que más se adaptan a tu estilo. {{numPagina}}",
    "title": "Relojes {{colorCorrea}} para {{gama}} {{numPagina}} | Perrelet"
  },
  "reloj.gama.colorEsfera.default": {
    "h1": "Relojes con esfera {{colorEsfera}} para {{gama}}",
    "meta": "Distinguidos, únicos y sofisticados. Así son nuestros Relojes con esfera {{colorEsfera}} para {{gama}} que aportarán un toque de elegancia a tu muñeca.",
    "title": "Relojes con esfera {{colorEsfera}} para {{gama}} {{numPagina}} | Perrelet"
  },
  "reloj.gama.funcion.default": {
    "h1": "Relojes {{funcion}} para {{gama}}",
    "meta": "Encuentra los relojes {{funcion}} para {{gama}} de Perrelet más increíbles y sofisticados del mercado y lúcelos en cualquier ocasión. {{numPagina}}",
    "title": "Relojes {{funcion}} para {{gama}} {{numPagina}} | Perrelet"
  },
  "reloj.gama.materialCaja.default": {
    "h1": "Relojes con caja de {{materialCaja}} para {{gama}}",
    "meta": "Los relojes con caja de {{materialCaja}} para {{gama}} destacan por su cuidado diseño. Entra en nuestra tienda para escoger el que más se adecúe a ti.",
    "title": "Relojes con caja de {{materialCaja}} para {{gama}} {{numPagina}} | Perrelet"
  },
  "reloj.gama.materialCorrea.default": {
    "h1": "Relojes de {{materialCorrea}} para {{gama}}",
    "meta": "Los relojes de {{materialCorrea}} para {{gama}} de Perrelet se convierten en la opción perfecta para llevar un reloj exclusivo y manejable en tu muñeca.",
    "title": "Relojes de {{materialCorrea}} para {{gama}} | Perrelet"
  },
  "reloj.gama.tipoCierre.default": {
    "h1": "Relojes de {{tipoCierre}} para {{gama}}",
    "meta": "En nuestra web podrás ver la amplia selección de Relojes de {{tipoCierre}} para {{gama}} que ofrecemos. Disponemos de piezas exclusivas solo para ti.",
    "title": "Relojes de {{tipoCierre}} para {{gama}} | Perrelet"
  },
  "reloj.gama.tipoCorrea.ARMYS": {
    "h1": "Relojes {{tipoCorrea}} para {{gama}}",
    "meta": "¿Sabías que en Perrelet ofrecemos Relojes {{tipoCorrea}} para {{gama}} para todo tipo de ocasiones? ¡Encuentra el que más se adapte a tu personalidad!",
    "title": "Relojes Armys para {{gama}} | Perrelet"
  },
  "reloj.gama.tipoCorrea.CORREA": {
    "h1": "Relojes con Correa para {{gama}} | Perrelet",
    "meta": "¿Sabías que en Perrelet ofrecemos Relojes con Correa para {{gama}} para todo tipo de ocasiones? ¡Encuentra el que más se adapte a tu personalidad!",
    "title": "Relojes con Correa para {{gama}} | Perrelet"
  },
  "reloj.gama.tipoEstanqueidad.default": {
    "h1": "Relojes de {{tipoEstanqueidad}} para {{gama}}",
    "meta": "Consulta nuestro amplio catálogo de Relojes de {{tipoEstanqueidad}} para {{gama}} fabricados con los mejores materiales y técnicas del mercado.",
    "title": "Relojes de {{tipoEstanqueidad}} para {{gama}} | Perrelet"
  },
  "reloj.gama.tiposMovimiento.01": {
    "h1": "Relojes Digitales para {{gama}}",
    "meta": "¿Estás buscando un reloj digital para {{gama}}? En Perrelet tenemos una gran selección de relojes disponibles. Entra y elige el que mejor se adapta a ti.",
    "title": "Relojes Digitales para {{gama}} | Perrelet"
  },
  "reloj.gama.tiposMovimiento.02": {
    "h1": "Relojes para {{gama}} con movimiento Cuarzo Digital",
    "meta": "¿Buscas Relojes de {{gama}} con movimiento Cuarzo Digital? Echa un ojo a nuestra amplia gama de relojes para {{gama}} y escoge el modelo perfecto para ti.",
    "robots": "false",
    "title": "Relojes para {{gama}} con movimiento Cuarzo Digital | Perrelet"
  },
  "reloj.gama.tiposMovimiento.04": {
    "h1": "Relojes Automáticos para {{gama}}",
    "meta": "¿Estás buscando un reloj automático para {{gama}}? En Perrelet tenemos una gran selección de relojes disponibles. Entra y elige el que mejor se adapta a ti.",
    "title": "Relojes Automáticos para {{gama}} | Perrelet"
  },
  "reloj.gama.tiposMovimiento.05": {
    "h1": "Relojes Híbridos para {{gama}}",
    "meta": "¿Estás buscando un reloj híbrido para {{gama}}? En Perrelet tenemos una gran selección de relojes disponibles. Entra y elige el que mejor se adapta a ti.",
    "title": "Relojes Híbridos para {{gama}} | Perrelet"
  },
  "reloj.gama.tiposMovimiento.06": {
    "h1": "Smartwatches para {{gama}}",
    "meta": "¿Estás buscando un reloj híbrido para {{gama}}? En Perrelet tenemos una gran selección de relojes disponibles. Entra y elige el que mejor se adapta a ti.",
    "title": "Smartwatches para {{gama}} - Relojes Inteligentes | Perrelet"
  },
  "reloj.gama.tiposMovimiento.07": {
    "h1": "Relojes de Cuarzo para {{gama}}",
    "meta": "¿Estás buscando un reloj de cuarzo para {{gama}}? En Perrelet tenemos una gran selección de relojes disponibles. Entra y elige el que mejor se adapta a ti.",
    "title": "Relojes de Cuarzo para {{gama}} | Perrelet"
  },
  "reloj.IN": {
    "h1": "Relojes para Niños",
    "meta": "¿En busca de los mejores relojes para niños? En nuestra tienda online encontrarás divertidos y variados relojes para los más pequeños de la casa. {{numPagina}}",
    "title": "Relojes para Niños {{numPagina}} | Perrelet"
  },
  "reloj.IN.funcion": {
    "h1": "Relojes {{funcion}} para Niños",
    "meta": "¿Quieres un reloj {{funcion}} para niño? Encuentra toda una variedad de relojes infantiles {{funcion}} de las mejores marcas. ¡Visita nuestro catálogo! {{numPagina}}",
    "title": "Relojes {{funcion}} para Niños {{numPagina}} | Perrelet"
  },
  "reloj.materialCaja.default": {
    "h1": "Relojes con carcasa de {{materialCaja}}",
    "meta": "¿Te gustan los relojes de {{materialCaja}}? Descubre nuestro amplio catálogo y encuentra el reloj que haga brillar tu muñeca. {{numPagina}}",
    "title": "Relojes con carcasa de {{materialCaja}} {{numPagina}} | Perrelet"
  },
  "reloj.materialCorrea.default": {
    "h1": "Relojes {{materialCorrea}}",
    "meta": "¿Te gustan los relojes con correa de {{materialCorrea}}? Descubre nuestro amplio catálogo y encuentra el reloj que haga brillar tu muñeca. {{numPagina}}",
    "title": "Relojes {{materialCorrea}} {{numPagina}} | Perrelet"
  },
  "reloj.S": {
    "h1": "Relojes para Mujer",
    "meta": "En nuestro catálogo de Relojes para Mujer, encuentra el que mejor se adapte a tu estilo y personalidad y llévalo cómodamente en tu día a día. {{numPagina}}",
    "title": "Relojes para Mujer {{numPagina}} | Perrelet"
  },
  "reloj.tipoCierre.default": {
    "h1": "Relojes {{tipoCierre}}",
    "meta": "¿Buscas un Reloj {{tipoCierre}}? Encuentra los mejores modelos de las mejores marcas y escoge el reloj perfecto para ti. Visita nuestro catálogo ahora {{numPagina}}",
    "title": "Relojes {{tipoCierre}} {{numPagina}} | Perrelet"
  },
  "reloj.tipoCorrea.default": {
    "h1": "Relojes {{tipoCorrea}}",
    "meta": "¿Buscas un Reloj {{tipoCorrea}}? Encuentra los mejores modelos de las mejores marcas y escoge el reloj perfecto para ti. Visita nuestro catálogo ahora {{numPagina}}",
    "title": "Relojes {{tipoCorrea}} {{numPagina}} | Perrelet"
  },
  "reloj.tiposMovimiento.01": {
    "h1": "Relojes Digitales",
    "meta": "¿Estás buscando un reloj digital? En Perrelet tenemos una gran selección de relojes digitales disponibles. Entra y elige el que mejor se adapta a ti.",
    "robots": "false",
    "title": "Relojes Digitales | Perrelet"
  },
  "reloj.tiposMovimiento.02": {
    "h1": "Relojes con movimiento Cuarzo Digital",
    "meta": "¿Buscas Relojes con movimiento Cuarzo Digital? Echa un ojo a nuestra amplia gama de relojes  y escoge el modelo perfecto para ti.",
    "robots": "false",
    "title": "Relojes con movimiento Cuarzo Digital | Perrelet"
  },
  "reloj.tiposMovimiento.03": {
    "h1": "Relojes con movimiento Manual",
    "meta": "¿Buscas Relojes con movimiento Manual? Echa un ojo a nuestra amplia gama de relojes para Perrelet y escoge el modelo perfecto para ti.",
    "robots": "false",
    "title": "Relojes con movimiento Manual | Perrelet"
  },
  "reloj.tiposMovimiento.04": {
    "h1": "Relojes Automáticos",
    "meta": "¿Estás buscando un reloj automático? En Perrelet tenemos una gran selección de relojes automáticos disponibles. Entra y elige el que mejor se adapta a ti.",
    "title": "Relojes Automáticos | Perrelet"
  },
  "reloj.tiposMovimiento.05": {
    "h1": "Relojes Híbridos",
    "meta": "¿Estás buscando un reloj automático? En Perrelet tenemos una gran selección de relojes automáticosdisponibles. Entra y elige el que mejor se adapta a ti.",
    "robots": "false",
    "title": "Relojes Híbridos | Perrelet"
  },
  "reloj.tiposMovimiento.06": {
    "h1": "Smartwatches",
    "meta": "¿Estás buscando un smartwatch? En Perrelet tenemos una gran selección de relojes inteligentes disponibles. Entra y elige el que mejor se adapta a tu estilo.",
    "title": "Smartwatches - Relojes Inteligentes | Perrelet"
  },
  "reloj.tiposMovimiento.07": {
    "h1": "Relojes de Cuarzo",
    "meta": "¿Estás buscando un reloj de cuarzo? En Perrelet tenemos una gran selección de relojes de cuarzo disponibles. Entra y elige el que mejor se adapta a ti.",
    "robots": "false",
    "title": "Relojes de Cuarzo | Perrelet"
  }
}
